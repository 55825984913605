@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 27 Jan 2016
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Panel for security
 */
.security-panel {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  margin-left: 2.5%;
  overflow: auto;
  padding: 0 2.5%;
  position: relative;
  /* transition: width .25s; */
  width: 97.5%;
}

.security-panel-display {
  background-color: #fff;
  border-bottom: 1px solid #2a7090;
  cursor: pointer;
  font-size: 0.7vw;
  position: absolute;
  top: 0;
  right: 0;
  color: #2a7090;
  padding: 5px;
  transition: all 0.5s;
}

.security-panel-display:hover {
  color: white;
  background-color: #2a7090;
}

.security-panel__title {
  margin-top: 0.5em;
  min-height: 5.25em;
}

.security-panel__ticker-name {
  display: inline-block;
}

.security-panel__name,
.security-panel__ticker {
  display: block;
}

.security-panel__ticker {
  font-size: 60%;
  font-weight: 600;
}

.security-panel__name {
  line-height: 1em;
  padding: 0.15em 0;
}

.security-panel__logo-container {
  display: inline-block;
  float: left;
}

.security-panel__logo {
  background-image: none;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 58px;
  margin-right: 0.5em;
  margin-top: 0.25em;
  padding: 0.2em;
  width: 58px;
}

.security-panel__info {
  color: #666;
}

.security-panel__chart {
  clear: both;
  position: relative;
  display: flex;
  flex: 1;
}

.security-panel__rate {
  font-size: 350%;
  height: 1.8em;
  margin-bottom: 0.15em;
}

.security-panel__performance {
  margin-top: 4em;
}

.security-panel__performance-row {
  border-bottom: 1px solid #d3d3d3;
  margin: 0 0.5em;
  padding: 0.5em 0;
  position: relative;
}

.security-panel__performance-row:last-child {
  border: 0;
}

.security-panel__performance-container-bar-value {
  position: absolute;
  right: 0;
  bottom: 0.5em;
}

.security-panel__performance-value {
  display: inline-block;
  margin-right: 0.5em;
}

.security-panel__performance-value--big {
  font-size: 200%;
  line-height: 0.5em;
}
