@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 04 Dec 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Application: layout, color
 */
.app,
.public {
  color: #000;
  font-size: 0.9vw;
}

@media screen and (min-width: 1280px) {
  .app,
  .public {
    font-size: 0.7vw;
  }
}

.public {
  font-size: 1.8vmin;
}

.a11y {
  border: 0;
  height: 0;
  left: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  width: 0;
}

/* .hide {
   display: none  !important ;
} */

.impersonate {
  border: 4px solid red;
  height: 99%;
  width: 99%;
}
/* -------------------------------------------------------------- Application */
.app__header {
  background-color: #000;
  border-bottom: 3px solid #ffc001;
  color: #fff;
  font-size: 110%;
}

.app__header__advisor {
  background-color: #19376d;
  border-bottom: 3px solid #ffc001;
  color: #fff;
  font-size: 110%;
}

.app__logo,
.app__menu,
.app__preference,
.app__switch {
  display: inline-block;
  margin: 0.15em 0.25em;
  vertical-align: top;
}

.app__logo {
  height: 2.25em;
  width: auto;
}

.app__menu {
  margin-bottom: 0;
}

.app__preference {
  cursor: pointer;
  float: right;
  margin-right: 0.75em;
  margin-top: 0.75em;
}

.app__switch {
  cursor: pointer;
  float: right;
  /* margin-right: 0.45em;
  margin-top: 0.45em; */
}

.app__switch__wrap {
  border: 1px solid #666;
  border-radius: 50%;
  display: flex;
  flex-wrap: nowrap;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
}

/* @media screen and (min-width: 1200px) {
  .app__switch {
    margin-right: 0.65em;
    margin-top: 0.65em;
  }
} */

/* @media screen and (min-width: 1600px) {
  .app__switch {
    margin-right: 0.75em;
    margin-top: 0.75em;
  }
} */

.app__switch__logo {
  height: 20px;
  width: auto;
}

/* tooltip / popup */
.app__switch__popup {
  background-color: #2a7092;
  color: #fff;
  font-size: 1.1em;
  padding: 3px;
}

.app__main-wrapper {
  overflow: auto;
  position: relative;
}

.app__main {
  height: auto;
  margin: 0;
  padding: 4px 4px 0;
  position: relative;
}

.app__footer {
  height: 3.2em;
  background-color: #000;
  border-bottom: 3px solid #ffc001;
  bottom: 0;
  color: #fff;
  left: 0;
  position: fixed;
  width: 100%;
}

.app__footer__advisor {
  height: 3.2em;
  background-color: #19376d;
  border-bottom: 3px solid #ffc001;
  bottom: 0;
  color: #fff;
  left: 0;
  position: fixed;
  width: 100%;
}

.impersonate .app__footer {
  border-bottom-color: #f00;
  left: 4px;
  right: 4px;
  width: 99%;
}

.app__customer-care {
  display: inline-block;
}

.app__workflow {
  bottom: 0;
  left: 8%;
  position: absolute;
  right: 8%;
  width: 84%;
  z-index: 1;
}

.app__version--debug {
  background-color: #f00;
  color: #fff;
  font-weight: 600;
}

.link,
.link:visited {
  color: #2a7092;
  cursor: pointer;
  font-weight: normal;
}

.link:hover,
.link:active {
  background-color: #2a7092;
  color: #fff;
}
/* -------------------------------------------------------------- Public area */
.public--centered {
  text-align: center;
}

.public__header {
  font-size: 200%;
  font-weight: normal;
  margin-bottom: 1.25em;
  margin-top: 1.25em;
}

.public__claim {
  font-size: 300%;
  margin-bottom: 1em;
}

.public__title {
  font-size: 200%;
}

.public__footer {
  bottom: 0;
  font-size: 80%;
  left: 0;
  padding: 0 0.25%;
  position: fixed;
  text-align: center;
  width: 99.5%;
}

.public__version {
  float: left;
}

.public__unsupported {
  margin-bottom: 1em;
  padding: 1em;
}

.public__user-agent,
.public__user-agent-alternative,
.public__user-agent-alternative-logo,
.public__user-agent-alternative-name {
  display: block;
}

.public__user-agent {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  padding: 0.5em;
}

.public__user-agent-alternative {
  margin-top: 1em;
  text-align: center;
}

.public__user-agent-alternative-logo {
  margin: 1em auto;
}

.public__user-agent-alternative-name {
  font-size: 150%;
}
