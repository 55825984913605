.listItems {
  padding: 2px 5px;
  border: 1px solid #ddd;
  cursor: pointer;
  background-color: white;
  transition: 0.5s;
  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:hover {
    background-color: #2a7090;
    color: white;
  }
}

.listItemsWithRange {
  &:last-child {
    border-radius: 0;
  }
}

.openSliderButton {
  background: white;
  padding: 2px 5px;
  border: 1px solid #ddd;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin: 0;
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.active {
  color: white;
  background-color: #2a7090;
}
.inputSlider {
  padding: 2px 5px !important;
  font-size: 0.8vw !important;
  text-align: right;
}
