.navigatorWidgetBox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.modal {
  display: flex;
  overflow: hidden;
}
