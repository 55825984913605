.react-tabs {
  -webkit-tap-highlight-color: transparent;

  .react-tabs__tab-list {
    margin: 0 0 10px;
    padding: 0;
    border-bottom: 1px solid $primary;
  }

  .react-tabs__tab {
    display: inline-block;
    border-bottom: 3px solid transparent;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 125%;

    &--selected {
      border-bottom-color:$primary;
      color: black;
      border-radius: 5px 5px 0 0;
    }

    &--disabled {
      color: #999;
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  .react-tabs__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}
