.tippy-box[data-theme~="material"] {
    background-color: #505355;
    font-weight: 600;
}
.tippy-box[data-theme~="material"][data-placement^="top"]
    > .tippy-arrow:before {
    border-top-color: #505355;
}
.tippy-box[data-theme~="material"][data-placement^="bottom"]
    > .tippy-arrow:before {
    border-bottom-color: #505355;
}
.tippy-box[data-theme~="material"][data-placement^="left"]
    > .tippy-arrow:before {
    border-left-color: #505355;
}
.tippy-box[data-theme~="material"][data-placement^="right"]
    > .tippy-arrow:before {
    border-right-color: #505355;
}
.tippy-box[data-theme~="material"] > .tippy-backdrop {
    background-color: #505355;
}
.tippy-box[data-theme~="material"] > .tippy-svg-arrow {
    fill: #505355;
}
