@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 04 Dec 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Application fonts
 */

@font-face {
    font-display: swap;
    font-family: "Open Sans";
    /* font-style: normal; */
    font-weight: normal;
    src: url("../fonts/OpenSans400.woff") format("woff");
    text-rendering: optimizeLegibility;
}

@font-face {
    font-display: swap;
    font-family: "Open Sans";
    /* font-style: bold; */
    font-weight: 600;
    src: url("../fonts/OpenSans600.woff") format("woff");
    text-rendering: optimizeLegibility;
}

@font-face {
    font-display: swap;
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    src: local(""), url("../fonts/inter400.woff2") format("woff2"),
        url("../fonts/inter400.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    src: local(""), url("../fonts/inter600.woff2") format("woff2"),
        url("../fonts/inter600.woff") format("woff");
}
