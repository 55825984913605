.rootNode {
  display: flex;
  flex: 1;
  min-height: 100%;
  min-width: 100%;
  gap: 8px;
  max-height: 100%;
}
.tablePanel {
  flex: 1;
  background-color: white;
  border-radius: 6px;
  min-height: 0;
  gap: 8px;
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: space-between;
}
.contentPanel {
  flex: 1;
  background-color: white;
  border-radius: 6px;
  padding: 8px;
  min-height: 0;
}
.table {
  width: 100%;
  border-collapse: collapse;
}
.headerCol_sm {
  width: 10%;
}
.headerCol_lg {
  width: 40%;
}
.heading {
  text-transform: uppercase;
  color: #2a7090;
  font-weight: bold;
}
.centeredText {
  text-align: center;
}
.verticalAlignedTop {
  vertical-align: top;
  padding: 16px 8px;
}
.stickyHeader {
  position: sticky;
  top: 0;
  height: 40px;
  background: white;
  z-index: 2;
}
.tableRow {
  cursor: pointer;
  &:hover {
    background-color: rgba(221, 221, 221, 0.5);
  }
}
.selectedRow {
  background-color: rgba(221, 221, 221, 0.5);
}
.borderedCell {
  position: relative;
  border-left: 2px solid gold;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: -2px;
    background-color: white;
    height: 8px;
    width: 2px;
  }
}
.embedTitleBox {
  height: 40px;
  display: flex;
  align-items: center;
}
.embedContainer {
  height: calc(100% - 40px);
}
