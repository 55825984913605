.pageWrapper {
  display: flex;
  flex: 1;
  min-width: 0;
  width: 100%;
  padding: 8px;
  gap: 16px;
  height: 100%;

  .editorCol {
    min-width: 20%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .navigatorWidgetBox {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .engage-image-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }

  .resultCol {
    flex: 3;
    display: flex;
    min-height: 0;
  }
}

.tr_small {
  padding: 2px 5px !important;
  font-size: 0.8vw !important;
  max-width: 50px;
}

.strategySelectorListbox {
  border: 2px solid #2a7090;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  .strategySelectorHeader {
    background-color: #2a7090;
    color: white;
    padding: 8px 16px;

    h3 {
      font-size: 0.9vw;
      font-weight: 600;
    }
  }

  .strategySelector {
    display: flex;
    flex-direction: column;
    padding: 16px;
    padding-bottom: 24px;

    .strategySelectorListItem {
      border-radius: 4px;
      background-color: #f2f2f2;
      margin-bottom: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 16px;

      &:last-child {
        margin-bottom: 0;
      }

      .strategyNameCell {
        font-weight: bold;
        font-size: 0.8vw;
        color: black;
      }

      .uncolored {
        background-color: transparent;
      }

      .weightSelector {
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;

        .weightSelectorLabel {
          display: flex;
          gap: 2px;
          color: rgba(#000000, 0.4);
          margin-right: 5px;
          font-size: 0.8vw;

          .selected {
            color: black;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.strategyBoxHoverable {
  color: black;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  background-color: transparent;
  transition: 0.5s;
  font-size: 0.8vw;
  padding: 5px;

  &:hover {
    color: #2a7090;
    background-color: rgba(#000000, 0.1);
  }
}

.previewTable {
  width: 100%;
}

.editorBacktestFirstRow {
  display: flex;
  gap: 8px;
  flex: 1;
}

.deleteButton {
  padding: 5px;
  border-radius: 4px;
  transition: 0.5s;
  background-color: transparent;
  color: black;
  border: none;
  width: 50px;

  &:hover {
    background-color: #2a7090;
    color: white;
  }
}
