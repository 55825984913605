@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 09 Nov 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Add to button: add securities to an existing collection or create a new one and add
 */
.add-to {
    display: inline-block;
}

.add-to .button {
    margin-bottom: 2px;
    margin-right: 0.25em;
    padding: 0.3em 0.75em;
}
