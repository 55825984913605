.deletable_card {
    position: relative;
    box-sizing: border-box;

    .remove__card__icon {
        position: absolute;
        top: 2px;
        right: 5px;
        color: darkGrey;
        cursor: pointer;
    }

    .activate__panel__title {
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            color: #2a7090;
        }

        span {
            margin-right: 5px;
        }
    }
}

.breadcrumb__step {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
        color: #2a7090;
    }
}

.title__label {
    font-size: 1.6vw !important;
    font-weight: normal;
    color: black;
    cursor: pointer;

    &:hover {
        color: #2a7090;
        visibility: visible !important;
    }
}

.title__label__size {
    font-size: 1.6vw !important;
    font-weight: normal;
    color: black;
    cursor: pointer;
    visibility: hidden;

    &:hover {
        color: white;
        visibility: visible;
    }
}

.outlined__box {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 2px 5px;

    &__label {
        color: #2a7090;
        margin-left: 5px;
    }

    &__content {
        display: flex;
        flex: 1;
        padding: 2px 10px;
        border-radius: 0px;
        background-color: white;
        transition: all 0.3s;

        &:hover {
            background-color: #2a7090;
            border-radius: 4px;

            p {
                visibility: visible;
                color: white;
            }
        }
    }
}
