.mainWrapper {
  display: flex;
  height: 100%;
  min-height: 0;
  flex-direction: column;
  padding-bottom: 8px;

  .tabContent {
    margin-top: 8px;
    display: flex;
    flex: 1;
    min-height: 0;
    flex-direction: column;

    .contentWrapper {
      display: flex;
      gap: 8px;
      margin-top: 8px;
      flex: 1;
      min-height: 0;

      .tablePanel {
        display: flex;
        flex: 3;
        min-height: 0;
      }

      .sidePanel {
        display: flex;
        flex: 1;
        flex-direction: column;
        min-height: 0;
        gap: 8px;
      }
    }
  }
}
