@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 25 Feb 2016
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Builder module
 */
/* #region ------------------------------------------------------------- List */
.builder-list {
}

.builder-list__type {
    background-color: #fff;
    border: 1px solid #d3d3d3;
    color: #000;
    padding: 0.5em;
}

.builder-list__title {
    border-bottom: 3px solid #2a7092;
    margin-bottom: 0.25em;
    padding-bottom: 0.25em;
}

.builder-list__engage {
    border-bottom: 1px solid #2a7092;
    margin: 1em 0;
    padding: 0 0 1em 0;
    text-align: center;
}

.builder-list__engage-icon {
    color: #2a7092;
    font-size: 400%;
    line-height: 1em;
}

.builder-list__engage-description {
    font-size: 125%;
    font-weight: normal;
    padding: 1em;
}

.builder-list__engage-wrap {
    margin-top: 10px;
    text-align: center;
    margin-bottom: 10px;
}

.builder-list__menu {
    position: relative;
    border: 1px solid #d3d3d3;
    content: "☰";
    cursor: pointer;
    float: right;
    color: #2a7092;
    font-size: 0.9em;
    height: 1.4em;
    line-height: 1.5em;
    text-align: center;
    width: 1.5em;
}

.builder-list__menu:after {
    position: absolute;
    content: "☰";
    left: 4px;
    top: 0;
}

.builder-list__menu:hover {
    color: #ffffff;
    background-color: #2a7092;
    border-color: #2a7092;
}
/* #endregion --------------------------------------------------------------- */
/* #region ----------------------------------------------------------- Editor */
.builder {
}

.builder-title {
    margin-bottom: 0.5em;
}

.builder-title__title {
    display: inline-block;
    margin-right: 0.5em;
}

.builder__form {
    position: relative;
    overflow: auto;
}

.builder__form-wrapper {
    
}

.builder__step {
    background-color: #2a7092;
    border: 1px solid #fff;
    color: #fff;
    display: inline-block;
    font-weight: normal;
    text-align: center;
    width: 1.3em;
}

.builder-smart-beta__rate {
    display: inline-block;
    font-size: 200%;
    vertical-align: middle;
}

.builder-smart-beta__rate-slider {
    display: inline-block;
    vertical-align: middle;
    width: 80%;
}

.builder-smart-beta__rate-value {
    display: inline-block;
    vertical-align: middle;
    width: 2.5em;
}

.builder-sector-rotation__rate-slider {
    display: inline-block;
    vertical-align: middle;
    width: 75%;
}

.builder__button-bar {
    background-color: #f2f2f2;
    bottom: 0;
    right: 0;
    padding: 0.5em 0 0 0;
    position: absolute;
    width: 100%;
    z-index: 400;
}
/* #endregion --------------------------------------------------------------- */
/* #region -------------------------------------------------------- Key facts */
.key-facts {
}

.key-facts__title {
    border-bottom: 1px solid #d3d3d3;
    margin-bottom: 0.3em;
}

.key-facts__table {
    border-collapse: collapse;
    width: 98%;
}

.key-facts__td,
.key-facts__th {
    padding: 0.5em;
}

.key-facts__th.key-facts__th--align-right {
    text-align: right;
}

.key-facts__td {
    text-align: right;
}

.key-facts__td--label {
    text-align: left;
    width: 15em;
}

.key-facts__table tr:hover {
    background-color: #eee;
    cursor: pointer;
}

.key-facts__th {
    border-bottom: 1px solid #d3d3d3;
    font-weight: normal;
    padding-top: 0;
    text-align: left;
}

.key-facts__chart {
    float: right;
}
/* #endregion --------------------------------------------------------------- */
/* #region ------------------------------------------------------------ Chart */
.builder-chart {
    height: 100%;
    overflow: hidden;
}

.builder-chart__title {
    position: relative;
}

.builder-chart__tools {
    font-size: 70%;
    position: absolute;
    right: 0;
    top: 0.25em;
}

.builder-chart__tools-scale {
    display: inline-block;
    margin-left: 1em;
}

.builder-chart__chart {
    height: 93%;
}
/* #endregion --------------------------------------------------------------- */
/* #region -------------------------------------------------------- Analytics */
.builder-analytics {
    height: 100%;
}

.builder-analytics__tools {
    margin-bottom: 0.5em;
}

.builder-analytics__grid {
}

.builder-analytics__formatterSwitch {
    background-color: #fff;
    border: 0;
    cursor: pointer;
    float: right;
    line-height: 1em;
    margin: 0;
    outline: 1px solid #d3d3d3;
    padding: 2px 2px 0 2px;
}

.builder-analytics__formatterSwitch:hover {
    outline-color: #2a7092;
}

.builder-analytics__formatterSwitch--off {
    color: #d3d3d3;
}

.builder-analytics__formatterSwitch--on {
    color: #2a7092;
    outline-color: #2a7092;
}

.builder-analytics__grid .dgrid-content .field-DiffPerf {
    text-align: right;
}
/* #endregion --------------------------------------------------------------- */
/* #region --------------------------------------------------------- Holdings */
.builder-holdings {
}

.builder-holdings__timeline {
    overflow: hidden;
    width: 100%;
}

.builder-holdings__facts {
    display: flex;
    padding: 1% 0;
}

.builder-holdings__fact {
    border: 1px solid #d3d3d3;
    padding: 0.15% 0.5%;
    width: 17%;
}

.builder-rationale {
    float: right;
}

.builder-holdings__extra-fact {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    width: 6.15em;
}

.builder-holdings__fact--momentum {
    width: 20%;
}

.builder-holdings__fact--performance {
    width: 20%;
}

.builder-holdings__fact--timeframe {
    width: 26%;
}

.builder-holdings-performance__table {
    border-collapse: collapse;
}

.builder-holdings-performance__th {
    font-weight: normal;
}

.builder-holdings-performance__td {
    text-align: right;
}

.builder-holdings-performance__td--left {
    text-align: left;
}

.builder-holdings__fact-value {
    display: inline-block;
    margin-right: 1em;
    width: 3.75em;
}

.builder-holdings__fact-value--rate {
    font-weight: 600;
    text-align: right;
}

.builder-holdings__performances {
}

.builder-holdings__performance {
    margin-left: 0.5em;
}

.builder-holdings__performance--unique {
    margin-left: 0;
}

/* .builder-holdings__pie {
   overflow: hidden;
} */
.builder-holdings .tAnalysisOverview {
    margin-right: 0.5em;
}

.builder-holdings .tAnalysisOverview-layout--allocation {
    outline: 0;
}

.timeframe {
    text-align: center;
}

.timeframe__button,
.timeframe__current {
    vertical-align: middle;
}

.timeframe__current {
    display: inline-block;
    margin: 0 0.5em;
}

.timeframe__button {
    padding: 0 0.15em;
}
/* #endregion --------------------------------------------------------------- */
/* #region ------------------------------------------------------------- Help */
.builder-help {
    font-size: 200%;
    padding-top: 32vh;
    text-align: center;
}

.builder-help__description {
    font-size: 60%;
    margin-top: 0.5em;
}

.builder-help__get {
    font-size: 90%;
    margin-top: 1em;
}

.builder-help__text {
    background-color: #fff;
    border: 1px solid #d3d3d3;
    box-shadow: 3px 3px 3px 3px rgba(232, 232, 232, 0.75);
    font-size: 120%;
    margin: 0;
    margin-left: 1%;
    padding: 0;
    position: relative;
    width: 94%;
}

.builder-help__text-wrapper {
    height: 76.5vh; /* was 78.5vh */
    overflow: auto;
    padding: 1%;
}

.builder-help__textSection {
    margin-left: 2.5em;
    margin-top: 0.5em;
}

.builder-help__text .title1 {
    font-size: 165%;
    margin-bottom: 0.25em;
}

.builder-help__text .titleDescription {
    display: inline-block;
    font-size: 60%;
    padding-left: 1em;
}

.builder-help__text .title2 {
    border-bottom: 1px solid #d3d3d3;
    padding-top: 0.5em;
}

.builder-help__text .fieldHelp {
    list-style: none;
    margin: 0;
    padding: 0;
}

.builder-help__text .fieldHelp .item {
    line-height: 1.75em;
    padding: 0.75% 0;
}

.builder-help__text .fieldHelp .mandatory {
    color: #333;
    display: inline-block;
    font-size: 150%;
    left: -0.25em;
    position: relative;
    vertical-align: middle;
    width: 0.4em;
}

.builder-help__text .close {
    background-color: #fff;
    border-radius: 100%;
    color: #d3d3d3;
    font-size: 2em;
    height: 0.9em;
    position: absolute;
    right: -0.45em;
    top: -0.5em;
}

.builder-help__text .close:hover {
    color: #333;
    cursor: pointer;
}
/* #endregion --------------------------------------------------------------- */
/* #region --------------------------------------------------------- Tracking */
.tracking {
    margin: 0.5em 0.75em;
}

.tracking__message {
    margin-bottom: 0.5em;
}

.tracking__strategy-list {
    margin-bottom: 0.5em;
}

.tracking__strategy-list-item {
    margin-bottom: 0.5em;
    text-align: right;
}

.tracking__strategy-name {
    display: inline-block;
    margin-right: 1em;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 10.6em;
}

.tracking--untrack {
    width: 20em;
}
/* #endregion --------------------------------------------------------------- */
