@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 16 Dec 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Support form
 */
.support {
   padding: .25em .75em;
   position: relative;
   width: 20em;
}

.support__feedback {}

.support__form {}

.support__button-bar {
   bottom: .75em;
   right: .75em;;
   position: absolute;
}