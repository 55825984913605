@import "./styles/backdrop.scss";
@import "./styles/border.scss";
@import "./styles/svg-arrow.scss";
@import "./styles/tippy.scss";
@import "./styles/themes/light-border.scss";
@import "./styles/themes/security-tooltip.scss";
@import "./styles/themes/light.scss";
@import "./styles/themes/material.scss";
@import "./styles/themes/translucent.scss";

$trendrating-blue: #2a7092;
$tooltip-bg-color-light: #fff;

.tippy-box[data-theme~="light-border"],
.tippy-box[data-theme~="security-tooltip"] {
    border-color: $trendrating-blue;
    box-shadow: 0px 3px 15px rgba($color: #000000, $alpha: .7);
    max-height: calc(100vh - 10px);
}
.tippy-box[data-theme~="security-tooltip"] {
    display: flex;
    min-width: 0;
}
[data-tippy-root] {
    max-width: calc(100vw - 50px);
    min-width: 0;
}

.tippy-box[data-theme~="light-border"][data-placement^="top"]
    > .tippy-arrow:after,
.tippy-box[data-theme~="security-tooltip"][data-placement^="top"]
    > .tippy-arrow:after {
    border-top-color: $trendrating-blue;
}

.tippy-box[data-theme~="light-border"][data-placement^="bottom"]
    > .tippy-arrow:after,
.tippy-box[data-theme~="security-tooltip"][data-placement^="bottom"]
    > .tippy-arrow:after {
    border-bottom-color: $trendrating-blue;
}

.tippy-box[data-theme~="light-border"][data-placement^="left"]
    > .tippy-arrow:after,
.tippy-box[data-theme~="security-tooltip"][data-placement^="left"]
    > .tippy-arrow:after {
    border-left-color: $trendrating-blue;
}

.tippy-box[data-theme~="light-border"][data-placement^="right"]
    > .tippy-arrow:after,
.tippy-box[data-theme~="security-tooltip"][data-placement^="right"]
    > .tippy-arrow:after {
    border-right-color: $trendrating-blue;
}

//Style for the tooltip with 2 panels
.tooltip-content-wrapper {
    display: flex;
    flex: 1 1 auto;

    .tooltip-leftPanel {
        display: flex;
        flex: 2;
        border-right: 1px solid $trendrating-blue;
        align-items: center;
        justify-content: center;

        .alerts-tooltip-icon {
            width: 60%;
            height: auto;
        }
    }

    .tooltip-rightPanel {
        flex: 8;
        margin-left: 15px;

        .call-to-action {
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 10px;
        }

        .title {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 5px;
        }

        .paragraph {
            font-size: 14px;
            margin-bottom: 10px;
        }

        .subtext {
            font-size: 14px;
            font-weight: 600;
        }
    }
}

.tooltip-icon {
    color: black;
    display: flex;
    position: absolute;
    left: -5px;
    cursor: help;

    &:hover {
        color: $trendrating-blue;
    }

    &.tooltip-inline {
        left: 0;
        position: relative;
    }
}

.tooltip-icon-wrapper {
    position: relative;

    &.tooltip-trigger-click {
        .tooltip-icon {
            cursor: pointer;
        }
    }

    &.tooltip-icon-wrapper-table {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
    }
}
