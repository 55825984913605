@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 10 Nov 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Collapser
 */
.collapser {
   color: #2a7092;
   cursor: pointer;
   display: inline-block;
   float: right;
}

.collapser__icon,
.collapser__label {
   display: inline-block;
}

.collapser__label {
   margin-right: .25em;
}

.collapser--alerts {
   display: inline-block;
   font-size: 60%;
   margin-right: .35em;
   margin-top: .35em;
}

.collapser--analysis-collection {
   position: absolute;
   right: 1%;
   top: 1%;
}

.collapser--analysis-collection-collepsed {
   top: 28%;
}