.tippy-box[data-placement^="top"] > .tippy-svg-arrow {
    bottom: 0;
}
.tippy-box[data-placement^="top"] > .tippy-svg-arrow:after,
.tippy-box[data-placement^="top"] > .tippy-svg-arrow > svg {
    top: 16px;
    transform: rotate(180deg);
}
.tippy-box[data-placement^="bottom"] > .tippy-svg-arrow {
    top: 0;
}
.tippy-box[data-placement^="bottom"] > .tippy-svg-arrow > svg {
    bottom: 16px;
}
.tippy-box[data-placement^="left"] > .tippy-svg-arrow {
    right: 0;
}
.tippy-box[data-placement^="left"] > .tippy-svg-arrow:after,
.tippy-box[data-placement^="left"] > .tippy-svg-arrow > svg {
    transform: rotate(90deg);
    top: calc(50% - 3px);
    left: 11px;
}
.tippy-box[data-placement^="right"] > .tippy-svg-arrow {
    left: 0;
}
.tippy-box[data-placement^="right"] > .tippy-svg-arrow:after,
.tippy-box[data-placement^="right"] > .tippy-svg-arrow > svg {
    transform: rotate(-90deg);
    top: calc(50% - 3px);
    right: 11px;
}
.tippy-svg-arrow {
    width: 16px;
    height: 16px;
    fill: #333;
    text-align: initial;
}
.tippy-svg-arrow,
.tippy-svg-arrow > svg {
    position: absolute;
}
