@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 04 March 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Select Tree Expanded (expanded taxonomy)
 */
.classification {
   border-spacing: 1px;
   font-size: 95%;
   table-layout: fixed;
   width: 100%;
}

.classification__cell {
   padding: .25em .5em;
   vertical-align: top;
}

.classification__cell--world,
.classification__cell--area {}

.classification__cell--world,
.classification__cell--area,
.classification__cell--region {
   padding-bottom: .5em;
   padding-top: .5em;
}

.classification__cell--region {}

.classification__cell--country {}

.classification__label-container {}

.classification__label {
   display: inline-block;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   width: 4em;
   vertical-align: middle;
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
   .classification__label {
      width: 4.5em;
   }
}

@media screen and (min-width: 1280px) {
   .classification__label {
      width: 6.5em;
   }
}

@media screen and (min-width:1800px) {
   .classification__label {
      width: 7.5em;
   }
}

.classification__cell--world .classification__label,
.classification__cell--area .classification__label {
   width: auto;
}

.classification .button-bar {
   background-color: #eee;
   border-top: 1px solid #2a7092;
   padding: .25em .5em;
   text-align: left;
}

.classification .button {
   vertical-align: middle;
}

.classification__domestic-foreign {
   display: inline-block;
   vertical-align: middle;
}

.classification__domestic-foreign-label {
   display: inline-block;
   margin-right: 1em;
}

.classification__security-type-label {
   display: inline-block;
   margin-right: .5em;
}

.classification__security-type {
   display: inline-block;
   margin-left: 4em;
   margin-right: 8em;
   vertical-align: middle;
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
   .classification__security-type {
      margin-right: 14em;
   }
}

@media screen and (min-width: 1280px) {
   .classification__security-type {
      margin-right: 32em;
   }
}

@media screen and (min-width:1800px) {
   .classification__security-type {
      margin-right: 32em;
   }
}
/* ------------------------------------------------------------------- colors */
.classification__cell--null {
   background-color: #2a7092;
   color: #fff;
}

.classification__cell--M_D,
.classification__cell--R_D_A,
.classification__cell--R_D_E,
.classification__cell--R_D_P {
   background-color: #D1DAE1;
   color: #333;
}

.classification__cell--M_E,
.classification__cell--R_E_A,
.classification__cell--R_E_E,
.classification__cell--R_E_P {
   background-color: #E8E6E5;
   color: #333;
}

.classification__cell--M_F,
.classification__cell--R_F_A,
.classification__cell--R_F_E,
.classification__cell--R_F_F,
.classification__cell--R_F_M,
.classification__cell--R_F_S {
   background-color: #E6F4F3;
   color: #333;
}