@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 22 May 2017
 * Project: Trendrating Web Application
 *
 * Timeframe chart for security analysis
 */
.chartTimeframe { }

.chartTimeframe-chart {
   border-bottom: 2px solid rgba(211, 211, 211, 1);
   border-top: 2px solid rgba(211, 211, 211, 1);
   height: 25vh;
   overflow: hidden;
}

.chartTimeframe-info {
   margin: .5em 0;
}

.chartTimeframe-ticker {
   font-weight: 600;
}

.chartTimeframe-potential {
   display: inline-block;
}

.chartTimeframe-performed {
   display: inline-block;
}