.content__box {
  display: flex;
  flex: 1 !important;
  min-width: 0;
  flex-grow: 1;
  flex-shrink: 1;
}

.clickcable__peer__card {
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    border-color: #2a7090;
  }
}
