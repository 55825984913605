@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 16 Now 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * User's preferences
 */
.preference {
   background-color: #fff;
   color: #333;
}

.product-config {
   background-color: transparent;
   margin: 0;
   padding: 0;
}

.product-config .product-config-feature {
   background-color: #fff;
   color: #000;
   border: 0;
   margin-top: .5em;
}

.product-config .product-config-feature .product-config-feature-name {
   background-color: #fff;
   font-weight: 600;
}

.product-config .product-config-feature .product-config-feature-option {
   margin-right: 1em;
}

.preference__feedback {
   text-align: center;
}

.preference__fieldset {
   border: 0;
   margin: 0;
   padding: 0;
}

.preference__legend {
   background-color: #2a7092;
   color: #fff;
   margin: 0;
   padding: .15em;
   padding-bottom: .25em;
   text-align: left;
   width: 100%;
}

.preference__field {
   margin-bottom: .25em;
}

.preference__field--checkbox {
   display: inline-block;
   margin-right: 1.5em;
   margin-top: .5em;
}

.preference__field--head {
   border-bottom: 1px solid #d3d3d3;
   padding-bottom: .25em;
}

.preference__fieldset .layout-grid__col {
   margin: .5em 0;
}

.preference__fieldset .layout-grid__col--33 {
   border-right: 1px solid #d3d3d3;
   margin-right: .5%;
   padding: 0 1%;
   width: 30.9%;
}

.preference__fieldset .layout-grid__col--33:last-child {
   border-right: 0;
   margin-right: 0;
}
/* ------------------------------------------------------------------- Report */
.preference-report {
    height: 520px;
}

.preference-report-tabPanes {   
    margin: .5em 0 0 0;
    padding: 0;
    position: relative;
 }

.preference-report__editor {
    height: 465px;
    margin-bottom: .5em;
    width: 800px;
}

.preference-report__image-box {
   background: #fff;
   background-repeat: no-repeat;
   cursor: move;
   height: 165px;
   width: 800px;
   margin-bottom: .5em;
   outline: 1px solid #ddd;
   overflow: hidden;
   position: relative;
}

.preference-report__thumb-box {
   position: absolute;
   top: 7px; /*207.5px*/
   left: 10px; /*270px*/
   width: 780px; /*260px*/
   height: 150px; /*50px*/
   box-sizing: border-box;
   border: 1px solid #ddd;
   box-shadow: 0 0 0 1000px rgba(239, 239, 239, .9);
   background: none repeat scroll 0% 0% transparent;
   border: 10px solid;

   border-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 11' width='11' height='11'><g fill=''><rect width='1' height='5'/><rect y='6' width='1' height='5'/><rect x='10' y='6' width='1' height='5'/><rect x='10' width='1' height='5'/><rect width='5' height='1'/><rect y='10' width='5' height='1'/><rect x='6' y='10' width='5' height='1'/><rect x='6' width='5' height='1'/></g></svg>") 5;

}

/*---*/
.preference-report__image-box_react {
   background: #fff;
   background-repeat: no-repeat;
   cursor: move;
   height: 165px;
   width: 100%;
   outline: 1px solid #ddd;
   overflow: hidden;
   position: relative;
}

.preference-report__thumb-box_react {
   position: absolute;
   
   width: 100%; /*260px*/
   height: 100%; /*50px*/
   box-sizing: border-box;
   border: 1px solid #ddd;
   box-shadow: 0 0 0 1000px rgba(239, 239, 239, .9);
   background: none repeat scroll 0% 0% transparent;
   border: 10px solid;

   border-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 11' width='11' height='11'><g fill=''><rect width='1' height='5'/><rect y='6' width='1' height='5'/><rect x='10' y='6' width='1' height='5'/><rect x='10' width='1' height='5'/><rect width='5' height='1'/><rect y='10' width='5' height='1'/><rect x='6' y='10' width='5' height='1'/><rect x='6' width='5' height='1'/></g></svg>") 5;

}
/*---*/


.preference-report__loading {
   position: absolute;
   top: 17.25em;
   left: 0;
   bottom: 0;
   right: 0;
   text-align: center;
   line-height: 1.5em;
}

.preference-report__actions {
   text-align: center;
}

.preference-report__zoom {
   padding-left: .5em;
   padding-right: .5em;
}

.preference-report__browse {
   float: left;
}

.preference-report__btn-save {
   float: right;
}