@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 20 Nov 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Search widget
 */
.search {
    display: inline-block;
    margin: 0;
    padding: 0;
}

.search__fieldset {
    border: 0;
    color: #333;
    margin: 0;
    padding: 0;
}

.search__legend {
}

.search__box {
    background-color: #fff;
    border: 1px solid #d3d3d3;
    color: #333;
    display: flex;
}

.search__box:hover {
    border-color: #2a7092;
}

.search__label {
}

.search__input {
    border: 0;
    display: inline-block;
    font-size: 100%;
    margin: 0;
    padding: 1px 2px;
    width: 100%;
}

.search__input:hover {
    background-color: #fff;
}

.search .button {
    border: 0;
    border-left: 1px solid #d3d3d3;
    color: #000;
    font-size: 100%;
    padding: 2px;
    vertical-align: top;
    width: 2em;
}

.search .button:hover {
    border-color: #d3d3d3;
}

.search .button:focus {
    background-color: #2a7092;
    color: #fff;
}

.search-isDisabled {
    position: relative;
}

.search-isDisabled .search-layer {
    background-color: rgba(0, 0, 0, 0);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.search-isDisabled .search__box,
.search-isDisabled .search__input {
    background-color: #efefef;
}

.search-isDisabled .button {
    background-color: #d3d3d3;
    color: #818181;
}
/* ---------------------------------------------------------------------- app */
.search--app {
    margin-left: 1.25em;
    margin-top: 0.5em;
    vertical-align: middle;
    width: 16em;
}
/* ------------------------------------------------------------------ Results */
.search__results {
    min-width: 30em;
}

.search__results-entity {
    background-color: #000;
    color: #fff;
    font-weight: 600;
    padding: 0.5em 0.25em;
}

.search__result {
    padding: 0.5em 0.25em;
}

.search__result:focus,
.search__result:hover {
    background-color: #2a7092;
    color: #fff;
    cursor: pointer;
}

.search__results--empty:hover {
    background-color: #fff;
    color: #333;
    cursor: default;
}

.search__results-total {
    background-color: #000;
    color: #fff;
    font-weight: 600;
    padding: 0.5em 0.25em;
}

.search__results-all {
    background-color: #000;
    color: #fff;
    cursor: pointer;
    padding: 0.5em;
    text-align: right;
}
/* ------------------------------------------------------ Security            */
.search__result-property {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.search__result-ticker {
    width: 5em;
}

.search__result-name {
    margin-left: 0.5em;
    width: 19.5em;
}

.search__result-type-exchange {
    margin-left: 0.5em;
    width: 17.5em;
}
/* -------------------------------------------------------- Dialog            */
.search-dialog {
    height: 100%;
}

.search-dialog__loading {
    font-size: 120%;
    margin-top: 12em;
    text-align: center;
}

.search-viewer {
    height: 100%;
}

.search-viewer__title {
    font-size: 120%;
    margin-bottom: 0.75em;
}

.search-viewer__input {
    font-weight: 600;
}

.search-viewer__filter {
    display: inline-block;
    font-weight: normal;
    margin-left: 1em;
    vertical-align: top;
}

.search-viewer__filter .filter-bar__field-label {
    margin-left: 1em;
}

.search-viewer__filter .filter-bar__field-label:first-child {
    margin-left: 0;
}

.search-viewer__paginator {
    margin-bottom: 0.25em;
    text-align: right;
}

.search-viewer__grid {
}

.search-viewer__grid .field-ticker {
}

.search-viewer__grid .field-name {
}

.search-viewer__grid .field-type {
    width: 7.25em;
}

.search-viewer__grid .field-country {
    width: 12em;
}
