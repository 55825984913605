@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 27 Jan 2016
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Percentage bar
 */
.percentage-bar {
   display: inline-block;
   height: 1em;
   line-height: 1em;
   outline: 1px solid #d3d3d3;
   overflow: hidden;
   width: 100%;
}

.percentage-bar__negative,
.percentage-bar__positive {
   display: inline-block;
   vertical-align: top;
   width: 50%;
}

.percentage-bar__negative {
   text-align: right;
}

.percentage-bar__value {
   display: inline-block;
   height: 1em;
   line-height: 1em;
}

.percentage-bar__value--benchmark {
    background-color: #2a7092;
}

.percentage-bar__value--positive {
   background-color: green;
}

.percentage-bar__value--negative {
   background-color: red;
}

.percentage-bar__value--outlierNegative {
    background-image: linear-gradient(
        rgba(255, 255, 255, .5) 2px,
        rgba(255, 255, 255, .5) 2px
    );
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 2px 100%;
}

.percentage-bar__value--outlierPositive {
    background-image: linear-gradient(
        rgba(255, 255, 255, .5) 2px,
        rgba(255, 255, 255, .5) 2px
    );
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-size: 2px 100%;
}

.percentage-bar--panel {
   width: 6em;
}