@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 14 Jan 2016
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Select range widget
 */
.select-range {
   display: inline-block;
   width: 15em;
   vertical-align: middle;
}

.select-range,
.select-range .select,
.select-range .select thead {
   border-spacing: 1px;
   margin: 0;
   padding: 0;
}

.select-range .select {
   width: 100%
}

.select-range .select .interval {
   background-color: #fff;
   cursor: pointer;
   font-weight: normal;
   outline: 1px solid #d3d3d3; /*#b5bcc7*/
   text-align: center;
   width: 20%;
}

.select-range--custom {
   width: 18em;
}

.select-range--custom .select .interval {
   border-right: 1px solid #d3d3d3;
   outline: 0;
   transition: border-color .5s ease;
}

.select-range--custom:hover .select .interval {
   border-color: #2a7092;
}

.select-range--custom,
.select-range--custom .select,
.select-range--custom .select thead {
   border-spacing: 0;
}

.select-range .select .checked {
   background-color: #2a7092;/*#2F7ED8*/
   color: #fff;
}

.select-range .select .interval:hover {
   color: #2a7092;/*#2F7ED8*/
   /*color: #d3d3d3;*/
}

.select-range .select .checked:hover {
   background-color: #2a7092;/*#2F7ED8*/
   color: #fff;
}

.disabled .select,
.disabled .select .checked,
.disabled .select .interval:hover,
.disabled .select .checked:hover {
   background-color: #efefef;
   color: #818181;
}