.modalContentOverride {
  display: flex;
  overflow: hidden;
  min-width: 0;
}
.outlinedBox {
  padding: 16px;
  display: flex;
  border: 1px solid #2a7090;
  border-radius: 4px;
  min-width: 0;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }
}
.listInDialog {
  display: flex;
  flex: 1;
  gap: 5px;
  min-width: 0;
}
.listItem {
  cursor: pointer;
  transition: 0.5s;
  padding: 3;
  min-width: 0;

  &:hover {
    background-color: #2a7090;
    color: white;
  }
}
.listItemCheckboxes {
  cursor: pointer;
  transition: 0.5s;
  padding: 3;
  min-width: 0;
}
.column {
  border-right: 1px solid #dedede;
  padding-right: 20px;
  min-height: 0;
  min-width: 0;
  overflow-y: auto;
  overflow-x: hidden;

  &:last-child {
    border-right: none;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
    margin-left: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d3d3d3;
  }
}
.listVoice {
  min-width: 0;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.listVoiceCheckboxes {
  min-width: 0;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
