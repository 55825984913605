.tAnalysisOverview {
  &-performerBox {
    ul {
      display: flex;
      flex-direction: column;
    }
  }

  &-performerBoxRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-performerBoxLabel {
    flex: 3;
  }

  &-performerBoxValue {
    display: flex;
    flex: 2;

    div {
      &:first-child {
        flex: 1;
      }
      &:last-child {
        flex: 1;
      }
    }
  }
}

.overlay {
  background-color: white;
  padding: 10px;
  overflow: auto;
  min-width: 40vw;
}
