.spinner_loader__wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error_status__loader {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 20px;
    font-size: 16px;
    font-weight: bold;
}
