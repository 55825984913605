.listItemCard {
  display: flex;
  position: relative;
  cursor: pointer;
  border: 1px solid;
  border-color: transparent;
  transition: 0.5s;
  min-width: 100px;

  &:hover {
    border-color: #2a7090;
  }
}

.listItemCardContent {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 5px 20px !important;
  transition: 0.5s;
}

.cardActive {
  border: 2px solid #ffc001 !important;
  color: #2a7090 !important;
}

.disabled {
  opacity: 0.4;
}
