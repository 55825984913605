@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 04 Dec 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Fast access widget for collections
 */
.fast-access {}

.fast-access__item {
   cursor: pointer;
}

.fast-access__item--leaf {}

.claro .dijitMenu .fast-access__item td {
    background-color: #000;
    background-image: none;
    border-bottom: 1px solid #ffc001;
    color: #fff;
}

.claro .dijitMenu .fast-access__item:hover td {
   background-color: #2a7092;
}

.claro .fast-access__item .dijitMenuArrowCell {
   display: table-cell;
}

.fast-access__label {
   display: inline-block;
   font-weight: 600;
   margin: .15em .25em;
}

.fast-access__item--leaf .fast-access__label {
   padding-left: .5em;
   width: 5em;
}