@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 24 May 2017
 * Project: Trendrating Web Application
 *
 * Security Rating in the last 3 years widget
 */
.chartYearsRate {
   padding: .5%;
   position: relative;
}

.chartYearsRate-buttonEnlarge {
   border-bottom: 1px solid rgba(211, 211, 211, 1);
   border-left: 1px solid rgba(211, 211, 211, 1);
   cursor: pointer;
   padding: .25em;
   position: absolute;
   right: 0;
   top: 0;
}