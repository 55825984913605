.radio__labels,
.switch__label {
    font-size: 0.8vw !important;
}

.outlined {
    border: 2px solid #ddd;
    border-radius: 4px;
    flex: 1;
    display: flex;
    max-height: 100%;

    &:hover {
        border-color: #2a7090;

        .outlined__label {
            color: #2a7090;
        }
    }

    &__label {
        color: darkgrey;
        margin-left: 5px;
        font-size: 0.8vw;
    }

    &__content {
        padding: 10px;
        padding-top: 5px;
        display: flex;
        justify-content: space-between;
        flex: 1;
    }
}

.animated__wrapper {
    width: 100%;
    transition: 0.5s;
    display: flex;
    min-height: 0;
    max-height: 100%;
}
