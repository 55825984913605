.tab-style {
    border-bottom: none;
    position: relative;
    margin-left: -5px;

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        height: 50%;
        right: -2px;
        border-right: 5px solid white;
    }
}

.tree-wrapper {
    width: 100%;
    overflow: hidden;
}

.custom-row-wrapper {
    display: flex;
    flex-direction: row;
    overflow: auto;

    &::-webkit-scrollbar {
        height: 7px;
        width: 5px;
        background-color: transparent;
        margin-left: 5px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #d3d3d3;
    }
}

.selectable__label {
    &:hover {
        color: #2a7090;
        text-decoration: underline;
        cursor: pointer;
    }
}

.single__chioce--selected {
    color: #2a7090;
    text-decoration: underline;
    cursor: pointer;
}

.more__details__clickable {
    color: #2a7090;
    cursor: pointer;
}

.content__by__config {
    display: flex;
    flex: 1;
    min-width: 0;

    &__column {
        display: flex;
        flex-direction: column;
        min-width: 0;
    }
}

.list__items__by__config {
    flex: 1;
    overflow: hidden;
    min-width: 0;
    display: flex;
}

.fildset__by__config {
    overflow: hidden;
    min-width: 0;
}

.singleSelectionLabel {
    &:hover {
        color: #2a7090;
        cursor: pointer;
    }
}

.isLabelActive {
    color: #2a7090
}
