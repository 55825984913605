.systematicPortfoliosWrapper {
  display: flex;
  height: 98%;
  min-height: 0;
  min-width: 0;
  flex-direction: column;
  position: relative;

  .systematicPortfoliosContent {
    display: flex;
    min-height: 0;
    flex: 1;
  }

  .changeViewBtn {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 2;
  }
}

.errorBoxSms {
  height: 100%;
  width: 100%;
  padding: 8px;
}

.modal {
  display: flex;
  overflow: hidden;
}
