@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 26 May 2017
 * Project: Trendrating Web Application
 *
 * Tag widget
 */
.tag {
   background-color: rgba(42, 112, 146, 1);
   border: 1px solid rgba(211, 211, 211, 1);
   color: rgba(255, 255, 255, 1);
   display: inline-block;
   line-height: 1em;
   margin-right: .5em;
   padding: .5em;
}

.tag-icon { }

.tag-label {
   display: inline-block;
   margin-right: .5em;
}

.tag-buttonRemove {
   color: rgba(221, 221, 221, 1);
   cursor: pointer;
}