.reactRationale {
  position: relative;
  max-height: 100%;
  overflow: hidden;
  //   & .tabulator {
  //     max-height: 100%;
  //     & .tabulator-header {
  //       position: sticky;
  //       top: 0;
  //     }
  //   }
}
