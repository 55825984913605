.constraints__card__wrapper {
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;

    .card__selector__list {
        display: flex;
        box-shadow: none;
    }
}

.button__list {
    font-size: 12px;
    color: #2a7090;
    background-color: white;
    border: 1px solid #2a7090;
    padding: 6px 10px;
    margin-right: 2px;
    border-radius: 4px;

    &:last-child {
        margin-right: 0 !important;
    }

    &:hover {
        color: white;
        background-color: #2a7090;
        border: 1px solid #2a7090;
    }

    &-selected {
        color: white;
        background-color: #2a7090;
        border: 1px solid #2a7090;
    }
}
