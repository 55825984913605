@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 19 May 2017
 * Project: Trendrating Web Application
 *
 * Security History Tile widget
 */
.tileSecurityHistory { }

.tileSecurityHistory-title {
   /*display: flex;*/
   justify-content: flex-start;
   margin-bottom: .25em;
   margin-top: .5em;
}

.tileSecurityHistory-container {
   /*background-color: rgba(255, 255, 255, 1);
   border: 1px solid rgba(211, 211, 211, 1);
   padding: .45em .25em;*/
}

.tileSecurityHistory-tagsBar {
   display: flex;
   margin-bottom: .25em;
}

.tileSecurityHistory-tags {
   /*font-size: 70%;
   margin-left: 1em;*/
}

.tileSecurityHistory-buttonCompare {
   background-color: #fff;
   border: 1px solid #2a7092;
   color: #2a7092;
   font-size: 150%;
   line-height: 0;
   outline: 0;
   padding: .15em 0 .25em 0;
   width: 1.4em;
}

.tileSecurityHistory-stage {
   margin-top: .5em;
   position: relative;
}

.tileSecurityHistory-stageScene {
   overflow: hidden;
   position: relative;
}

.tileSecurityHistory-buttonEnlarge {
   border-bottom: 1px solid rgba(211, 211, 211, 1);
   border-left: 1px solid rgba(211, 211, 211, 1);
   cursor: pointer;
   padding: .25em;
   position: absolute;
   right: 0;
   top: 0;
   z-index: 10;
}