.root__select {
  font-size: 12px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.screenerControl {
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    color: #2a7090;
  }

  &__active {
    color: #2a7090;
  }
}
