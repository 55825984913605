.templateRowContent {
  display: flex;
  gap: 8px;
  flex: 1;
  min-height: 0;

  .templateListContainer {
    display: flex;
    flex-direction: column;
    min-height: 0;
  }

  .rulesEditorTable {
    min-height: 0;
    display: flex;
    flex-direction: row;
  }
}

.cardContent {
  display: flex;
  padding: 0 !important;
  flex-direction: column;
  flex: 1;
  min-height: 0;

  .cardHeader {
    padding: 8px;
    // background-color: rgba(0, 0, 0, 0.1);
    // color: rgba(0, 0, 0, 0.5);
    color: #2a7090;
    text-transform: uppercase;
    font-size: 0.8vw;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .cardContentListBox {
    flex: 1;
    padding: 8px 0;

    ul {
      li {
        padding: 2px 8px;
        display: flex;
        justify-content: space-between;
        span {
          cursor: pointer;
          transition: color 0.5s;
          white-space: nowrap;
        }

        &:hover {
          border-color: #2a7090;
          color: #2a7090 !important;
          font-weight: bold;
        }

        .cardContentListBox__edit-tools {
          display: flex;
          gap: 8px;

          &-btn {
            padding: 0 3px;
            border: 1px solid white;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

.activeTemplateItem {
  border: 1px solid #2a7090 !important;
  color: #2a7090 !important;
  font-weight: bold;
}

.numberBox {
  height: 25px;
  width: 25px;
  border: 1px solid #2a7090;
  background-color: rgba(255, 192, 1, 1);
  font-size: 0.9vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: black;
  border-radius: 6px;
}
