@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 13 Jun 2017
 * Project: Trendrating Web Application
 *
 * Style guide : MaintainableCSS (http://maintainablecss.com)
 *
 * User favorites securities
 */
.favorites {
   background-color: rgba(255, 255, 255, 1);
   border: 1px solid rgba(42, 112, 146, 1);
   color: rgba(0, 0, 0, 1);
}

.favorites-title {
   background-color: rgba(0, 0, 0, 1);
   color: rgba(255, 255, 255, 1);
   font-weight: 600;
   padding: .25em;
}

.favorites-list {
   border-collapse: collapse;
   min-width: 30em;
   width: 100%;
}

.favorites-item td {
   padding: .5em .25em;
}

.favorites-item:focus,
.favorites-item:hover {
   background-color: #2a7092;
   color: #fff;
   cursor: pointer;
}