@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 11 May 2015
 * Project: Trendrating Application
 *
 * Custom report
 */
.report-custom {
}

.report-custom .subnav {
   border-bottom: 1px solid #d3d3d3;
   margin: 0;
   margin-bottom: .25em;
   padding: 0 0 .5em 0;
}

.report-custom-template-list {
   margin: 15% auto 0 auto;
   width: 80%;
}

.report-custom-template-list .layout-grid__col--50 {
   height: 30em;
   margin: 0 1%;
   padding: 1%;
   width: 46%;
}

.report-custom-template-list .layout-grid__col--50:first-child {
   border-right: 1px solid #d3d3d3;
   overflow: auto;
   width: 40%;
}

.report-custom-template-list .title {
   border-bottom: 1px solid #d3d3d3;
   padding-bottom: .15em;
}

.report-custom-template-list .template-list {
   list-style: none;
   margin: 0;
   padding: 0;
}

.report-custom-template-list .template-list .template-item {
   margin: .25em 0;
   padding: .25em;
}

.report-custom-template-list .template-list .template-item:hover {
   background-color: #2a7092;
   color: #fff;
   cursor: pointer;
}

.report-custom-template-list .button {
   display: block;
   margin: 9em auto;
   width: 10em;
}

.report-custom-template-editor {
   background-color: #fff;
   border: 1px solid #d3d3d3;
   margin: 0 auto;
   padding: .5em;
   width: 85em;
}

.report-custom-template-editor .template-page {
   border-bottom: 4px solid #fb0;
   padding-bottom: .25em;
}

.report-custom-template-editor .name,
.report-custom-template-editor .delete {
   display: inline-block;
   font-size: 99%;
   margin-top: .1em;
   vertical-align: top;
}

.report-custom-template-editor .delete {
   margin-top: .2em;
   padding: .625em .75em;
   text-align: center;
}

.report-custom-template-editor .name {
   margin-right: .5em;
   width: 50em;
}

.report-custom-template-editor .editor-content {
   margin: 1em auto;
}

.report-custom-template-editor .addSection {
   background-color: #fb0;
   /*background-image: linear-gradient(rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 90%);*/
   border: 1px solid #d3d3d3;
   border-radius: 50%;
   color: #000;
   cursor: pointer;
   display: block;
   font-size: 2em;
   font-weight: 600;
   height: 2em;
   margin: 1em auto;
   padding: 0;
   width: 2em;
}

.report-custom-template-editor .section {
   background-color: #fff;
   border: 1px solid #2a7092;
   margin: 1em 0;
   padding: .5em;
   padding-top: 1em;
   position: relative;
}
.report-custom-template-editor .section .dnd-handler {
   background-color: #2a7092;
   height: 8px;
   left: 0;
   position: absolute;
   top:0;
   width: 100%;
}
.report-custom-template-editor .section .remove {
   position: absolute;
   right: .5em;
   top: .25em;
}

.report-custom-template-editor .section .row-param {
   margin: .75em 0;
}

.report-custom-template-editor .section .row-param label {
   margin-left: 1em;
}

.report-custom-template-editor .section .row-param label:first-child {
   margin-left: 0;
}

.report-custom-template-editor .builder .row-param label,
.report-custom-template-editor .screening .row-param label {
   margin-left: 0;
   vertical-align: middle;
}

.report-custom-template-editor .section .title {
   font-weight: 600;
   margin-bottom: .5em;
}

.dialogSectionAdd {
   text-align: center;
}

.dialogSectionAdd .button {
   display: inline-block;
   width: 12em;
}

.dialogSectionAdd .button {
   margin-bottom: 1em;
}

.dialogSectionAdd .btn:last-child {
   margin-bottom: 0;
}