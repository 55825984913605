.analisysSecurityMain {
  display: flex;
  height: 95%;
  width: 60%;
  margin: 0 auto;
  flex-direction: column;

  .bottomPanelMain {
    display: flex;
    flex: 1;
    gap: 8px;
  }

  .rightPanelMain {
    display: flex;
    flex: 3;
    flex-direction: column;
    gap: 8px;

    &__section {
      display: flex;
      flex: 1;
      min-width: 100%;
      gap: 8px;
      flex-direction: column;

      .tabsContentMain {
        display: flex;
        flex: 1;
      }
    }
  }

  .searchBarMain {
    display: flex;

    .searchCardMain {
      flex: 1;
      min-height: 100%;
      display: flex;

      .searchCardContent {
        flex: 1;
        display: flex;
      }
    }
  }

  .recentSideBarMain {
    display: flex;
    min-height: 100%;
    flex: 1;

    .recents__list {
      margin-top: 20px;
      flex: 1;
      min-height: 0;
      min-width: 0;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 8px;

      &__item {
        display: flex;
        gap: 8px;
        border-radius: 4px;
        background-color: rgba($color: #000000, $alpha: 0.1);
        padding: 8px;
        transition: all 0.5s;
        cursor: pointer;
        min-width: 0;

        &__cell {
          flex: 1;
          min-width: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:hover {
          background-color: rgba($color: #000000, $alpha: 1);
          color: white;
        }
      }
    }
  }

  .securityGeneralInfoBar {
    display: flex;
    gap: 8px;

    .securityGeneralInfoBox {
      flex: 1;
      display: flex;

      &_content {
        flex: 1;
      }
    }

    .securityTitleBox {
      flex: 4;
      display: flex;

      &_content {
        flex: 1;
      }
    }
  }
}

.tabRank__main {
  flex: 1;
  min-height: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__title__box {
    display: flex;
    min-height: 0;
    min-width: 0;

    &__card {
      display: flex;
      flex: 1;
      min-height: 0;
      min-width: 0;

      &__content {
        display: flex;
        flex: 1;
        min-height: 0;
        min-width: 0;

        &__main {
          display: flex;
          gap: 5px;
          align-items: center;
          justify-content: center;
          font-size: 1.5em;

          &__peer__label {
            color: #2a7090 !important;
            padding: 0 !important;
            font-size: 1em !important;
            cursor: pointer;
            background-color: white !important;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  &__content__box {
    display: flex;
    gap: 8px;
    min-height: 0;
    min-width: 0;
    flex: 1;

    &__panel__1,
    &__panel__2 {
      display: flex;
      min-height: 0;
      min-width: 0;

      &__card {
        display: flex;
        flex: 1;
        min-height: 0;
        min-width: 0;

        &__content {
          display: flex;
          flex: 1;
          min-height: 0;
          min-width: 0;
        }
      }
    }

    &__panel__1 {
      flex: 2;

      &__card__content {
        flex-direction: column;

        &__title__box {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &__main__box {
          flex: 1;
        }
      }
    }

    &__panel__2 {
      flex: 3;
      flex-direction: column;
      gap: 8px;

      &__row__rating {
        display: flex;
        gap: 8px;

        &__rc__card {
          flex: 1;
        }

        &__rc__dist__card {
          flex: 3;

          &__content {
            &__title {
              display: flex;
              align-items: center;
              gap: 5px;
            }

            &__main {
              margin-top: 8px;

              &__list {
                display: flex;
                justify-content: space-between;

                li {
                  flex: 1;
                }
              }
            }
          }
        }
      }

      &__row__performance {
        flex: 2;
        display: flex;

        &__card {
          flex: 1;
        }
      }
    }
  }
}

.tabCompare__main {
  display: flex;
  flex: 1;
  gap: 8px;

  &__editor__box,
  &__chart__box {
    display: flex;
    &__card {
      display: flex;
      flex: 1;

      &__content {
        display: flex;
        flex: 1;
      }
    }
  }

  &__editor__box {
    &__card {
      &__content {
        flex-direction: column;
        gap: 8px;

        &__table__box {
          display: flex;
          gap: 8px;
          justify-content: center;
        }
      }
    }
  }

  &__chart__box {
    flex: 1;
  }
}

.tabHistorical__main {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;

  &__card {
    display: flex;
    flex: 1;

    &__content {
      display: flex;
      flex: 1;
    }
  }
}

.tabAlternatives__main {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  min-height: 0;

  &__card {
    flex: 1;
    display: flex;
    min-height: 0;

    &__content {
      display: flex;
      flex: 1;
      min-height: 0;
      flex-direction: column;

      &__switch__container {
        display: flex;
        gap: 8px;
        margin-bottom: 15px;
      }
      &__scrollable__container {
        display: flex;
      }
    }
  }
}

.infoTable {
  border-collapse: collapse;
  border: none;
  table-layout: fixed;
  empty-cells: show;
  width: 100%;
  height: 100%;
}

.infoCell {
  padding: 0.5em 0.25em;
  vertical-align: middle;
  border: 1px solid #ddd;
}
