@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 21 Set 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Switch widget
 */
.switch,
.switch__cursor,
.switch__label {
   display: inline-block;
   cursor: pointer;
   padding: 0 .15em;
}
 
.switch {
   cursor: pointer;
   margin-right: 1em;
   position: relative;
   vertical-align: text-bottom;
}

.switch__cursor,
.switch__label {
   vertical-align: middle;
}

.switch__cursor {
   height: 1em;
   width: 1.15em;
}

.switch__cursor--on {
   color: #2a7092;
}

.switch__cursor--off {
   color: #999;
}

.switch__label {}