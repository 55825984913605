

.listItem {
  width: 100%;
  color: black;
  overflow:hidden;
  white-space:nowrap;
  height: auto;
  display: block;
  border: solid 1px #2a7092;
  border-radius: 4px;
  position: relative;
  text-overflow: ellipsis;
  padding: 10px;
  // margin: 5px;
  cursor: grab;
  &:active {
    cursor: grabbing;
    // transition: all .2s;
    // transform: scale(1.1);
  }

  &:hover {
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.289);
    
    .closeBTN {
      display: block;
    }
  }
}
.horizontal {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
}
.vertical {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.list{
  display: flex;
  justify-content: space-between;
}

.before_vert {
  border-top: black 5px solid;
  border-bottom: #2a7092 1px solid;
  border-left: #2a7092 1px solid;
  border-right: #2a7092 1px solid;
}

.after_vert {
  border-bottom: black 5px solid;
  border-top: #2a7092 1px solid;
  border-left: #2a7092 1px solid;
  border-right: #2a7092 1px solid;
}

.before_horiz {
  border-top: #2a7092 1px solid;
  border-bottom: #2a7092 1px solid;
  border-left: black 5px solid;
  border-right: #2a7092 1px solid;
}

.after_horiz {
  border-bottom: #2a7092 1px solid;
  border-top: #2a7092 1px solid;
  border-left: #2a7092 1px solid;
  border-right: black 5px solid;
}
