.bar {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
  gap: 8px;
  border-bottom: 1px solid #ddd;
  padding: 8px 0;
  margin-bottom: 8px;
}
