@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 09 Apr 2018
 * Project: Trendrating Web Application
 *
 * Style guide : MaintainableCSS (http://maintainablecss.com)
 *
 * Help page
 */

.help {
  margin: 0 1em;
}

.help h1 {
  font-size: 150%;
  margin-bottom: 0.5em;
}

.help h2 {
  font-size: 130%;
  font-weight: normal;
  margin-bottom: 0.25em;
}

.help h3 {
  font-size: 120%;
}

.help p {
  margin-bottom: 0.5em;
}

.help-layout {
  display: flex;
  justify-content: space-between;
}

.help-layout-contact {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.help-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 69%;
}

.tHelpPage .help-left h1,
.tHelpPage .help-contact h1 {
  border-bottom: none;
}

.help-right {
  width: 29%;
}

.help-contact {
  width: 50em;
}

.help-contact-numbers {
  margin-left: 2em;
  margin-bottom: 3.5em;
  width: 25em;
}

.help-box {
  background-color: #fff;
  border: 1px solid #2a7092;
  /*border-left-width: 3px;*/
  color: #000;
  margin-bottom: 4%;
}

.help .help-box h2 {
  background-color: #2a7092;
  color: #fff;
  padding: 0.5em;
  margin: 0;
}

.help-box--50 {
  width: 47%;
}

.help-box.help-box--alt {
  border: 1px solid #d3d3d3;
  border-left: 3px solid #ffc001;
}

.help .help-box.help-box--alt h2 {
  background-color: #fff;
  color: #000;
  padding: 0.75em 0.75em 0.25em;
}

.howTo {
  list-style: none;
  margin: 0;
  padding: 1em;
}

.howTo-item {
  display: list-item;
  margin: 0.5em;
}

.help-box-content {
  margin: 0;
  padding: 1em;
}

.help-useCases-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  table-layout: fixed;
  margin-bottom: 10px;
  border: 1px solid #2a7092;
}

.help-useCases-table-header-row {
  background-color: #2a7092;
}

.help-useCases-table-body-cell,
.help-useCases-table-header-cell {
  padding: 16px 8px;
  text-align: justify;
  vertical-align: top;
}

.help-useCases-table-body-row > .help-useCases-table-body-cell:first-child {
  vertical-align: middle;
  font-weight: bold;
  font-style: none;
}

.help-useCases-table-body-cell:nth-child(2) {
  vertical-align: middle;
  font-style: none;
}

.help-useCases-table-header-row > .help-useCases-table-header-cell {
  color: white;
  font-weight: bold;
}

.help-useCases-table-body-row:nth-child(even) {
  background-color: #f2f2f2;
}
