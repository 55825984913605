.tippy-box {
    border: 1px transparent;
}
.tippy-box[data-placement^="top"] > .tippy-arrow:after {
    border-top-color: inherit;
    border-width: 8px 8px 0;
    bottom: -8px;
    left: 0;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow:after {
    border-bottom-color: inherit;
    border-width: 0 8px 8px;
    top: -8px;
    left: 0;
}
.tippy-box[data-placement^="left"] > .tippy-arrow:after {
    border-left-color: inherit;
    border-width: 8px 0 8px 8px;
    right: -8px;
    top: 0;
}
.tippy-box[data-placement^="right"] > .tippy-arrow:after {
    border-width: 8px 8px 8px 0;
    left: -8px;
    top: 0;
    border-right-color: inherit;
}
.tippy-box[data-placement^="top"]
    > .tippy-svg-arrow
    > svg:first-child:not(:last-child) {
    top: 17px;
}
.tippy-box[data-placement^="bottom"]
    > .tippy-svg-arrow
    > svg:first-child:not(:last-child) {
    bottom: 17px;
}
.tippy-box[data-placement^="left"]
    > .tippy-svg-arrow
    > svg:first-child:not(:last-child) {
    left: 12px;
}
.tippy-box[data-placement^="right"]
    > .tippy-svg-arrow
    > svg:first-child:not(:last-child) {
    right: 12px;
}
.tippy-arrow {
    border-color: inherit;
}
.tippy-arrow:after {
    content: "";
    z-index: -1;
    position: absolute;
    border-color: transparent;
    border-style: solid;
}
