@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 04 Dec 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Reset and base styles
 */
article,
body,
div,
fieldset,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
li,
main,
p,
section,
ul {
    border: 0;
    display: block;
    margin: 0;
    padding: 0;
}

html {
    background-color: #f2f2f2;
    /* background-color: #fff; */
    height: 100%;
    box-sizing: border-box; /* apply a natural box layout model to all elements, but allowing components to change */
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body,
html {
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-size: 100%;
}

abbr {
    cursor: help;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

h1 {
    font-size: 1.5em;
}

img {
    border: 0;
}

ul {
    list-style: none;
}

input,
textarea {
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-size: 100%;
}

textarea {
    resize: none;
}

input:focus,
textarea:focus {
    background-color: #fff;
    outline: 0;
}

:focus,
:focus:focus {
    outline: none;
    outline-color: transparent;
    outline-style: auto;
    outline-width: 0;
}

hr {
    display: none;
}

a:link,
a:visited {
    color: #2a7092;
    text-decoration: none;
}

a:hover,
a:active {
    text-decoration: underline;
}

strong,
th {
    font-weight: 600;
}
