@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 10 Feb 2016
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Dialog import from Excel
 */
.import {
}

.import__data {
    border-collapse: collapse;
    /* width: 100%; */
}

.import__data td,
.import__data th {
    border: 1px solid #ccc;
}

.import__data th {
    background-color: #eee;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0) 100%
    );
}

.import__data td {
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    max-width: 250px;
}

.import__label {
    display: inline-block;
    font-size: 110%;
    margin-right: 0.5em;
}

.import__settings {
    padding: 1.75em 0;
}

.import__settings .layout-grid {
    text-align: left;
}

.import__settings .layout-grid__col--20 {
    margin: 0 2em 0 0;
    padding: 0;
    width: auto;
}

.import__step {
    display: inline-block;
    border: 1px solid #2a7092;
    border-radius: 50%;
    color: #277397;
    font-size: 80%;
    font-weight: 600;
    text-align: center;
    width: 1.35em;
}

.import__img-col,
.import__img-row,
.import__img-world {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    min-height: 6em;
}

/* .import__img-col {
    background-image: url("img/excel_col.png");
}

.import__img-row {
    background-image: url("img/excel_row.png");
}

.import__img-world {
    background-image: url("img/excel_world.png");
} */

.import__unrecognized {
    border-bottom: 1px solid #d3d3d3;
    padding: 0.25em 0.25em 0.25em 0;
}

.import__unrecognized-option {
    background-color: #fff;
    border: 1px solid #d3d3d3;
    cursor: pointer;
    display: block;
    font-family: "Open Sans", sans-serif;
    font-size: 1em;
    width: 100%;
    margin: 0 0 0.25em 0;
    padding: 0.15em 0.25em;
    vertical-align: middle;
}

.import__unrecognized-option:hover {
    border-color: #2a7092;
}

.import__unrecognized-label {
    display: block;
    font-weight: 600;
    padding-bottom: 5px;
}

.import__unrecognized-name,
.import__unrecognized-ticker {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    white-space: nowrap;
    min-width: 2em;
}

.import__unrecognized-name {
    max-width: 40%;
}

.import__unrecognized-ticker {
    max-width: 4em;
}
