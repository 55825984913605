@charset "UTF-8";
@import "./variables";

@import "./critical.css";

@import "./react-tabs";
@import "./react-tabs-classic";
@import "../components/table/TrendratingTable.scss";
@import "../components/Tooltip/Tooltip.scss";
@import "../components/ListsDropdown/ListsDropdown.module.scss";
@import "../components/SecurityTooltip/SecurityTooltip.module.scss";
@import "../components/SecurityTooltip/CreateListDialog.module.scss";
@import "../components/SecurityChartModal/SecurityChartModal.module.scss";
@import "../components/Accordion/Accordion.scss";
@import "../components/LayoutElements/Block/Block.module.scss";
@import "../components/Modal/Modal.module.scss";
@import "../components/Dropdown/Dropdown.module.scss";
@import "../components/InputSelect/InputSelect.module.scss";
@import "../components/WhatWherePie/WhatWherePie.module.scss";
@import "../components/Spinner/Spinner.module.scss";
@import "../js/app/widgets/dialog/rationale/ReactDialogRationale.module.scss";
@import "../js/app/pages/analysisLists/analyze/tabs/TabImprove/Alternatives.module.scss";
@import "../components/ComponentStatusWizard/ComponentStatusWizard.module.scss";
@import "../components/PeerSelect/PeerSelect.module.scss";
@import "../js/app/pages/analysisMarkets/ETFs/widgets/FiltersBarMarketDetail/FiltersBarMarketDetail.module.scss";
@import "../js/app/pages/analysisMarkets/ETFs/widgets/ContentETFMarkets/widgets/SecuritiesView/SecuritiesView.module.scss";
@import "../js/app/pages/analysisMarkets/ETFs/widgets/ContentETFMarkets/widgets/DispersionView/DispersionBlock/DispersionBlock.module.scss";
@import "../components/InputNumber/InputNumber.module.scss";
@import "../components/AlertsSelect/AlertsSelect.module.scss";
@import "../js/app/pages/analysisMarkets/ETFs/widgets/ContentETFMarkets/widgets/InstrumentsTableForAnalytics/InstrumentTableForAnalytics.module.scss";
@import "../js/app/pages/analysisMarkets/ETFs/widgets/ContentETFMarkets/widgets/DispersionView/DispersionView.module.scss";
@import "../js/app/pages/analysisMarkets/ETFs/widgets/ContentETFMarkets/widgets/RatingHistoryView/RatingHistoryView.module.scss";
@import "../js/app/pages/analysisMarkets/ETFs/widgets/PeerPresetsRow/PeerPresetsRow.module.scss";
@import "../js/app/pages/analysisMarkets/ETFs/widgets/BreadcrumbsRow/BreadcrumbsRow.module.scss";
@import "../js/app/pages/strategies/builder/editors/advancedWidgets/AdvancedFieldControllers/InvestmentUniverseController/widgets/ScreenerPanel/ScreenerPanel.module.scss";
@import "../js/app/pages/strategies/builder/editors/advancedWidgets/AdvancedFieldControllers/InvestmentUniverseController/InvestmentUniverseController.module.scss";
@import "../js/app/pages/strategies/builder/editors/advancedWidgets/AdvancedFieldControllers/WeightingRulesController/WeightingRulesController.module.scss";
@import "../js/app/pages/strategies/builder/editors/advancedWidgets/AdvancedFieldControllers/WeightingRulesController/widgets/SmartBetaTable/SmartBetaTable.module.scss";
@import "../js/app/pages/analysisMarkets/widgets/PerformanceSinceTrend/PerformanceSinceTrendTab.module.scss";

@import "css/style.scss";
@import "css_new/style.scss";
@import "app-account/index.scss";
@import "../js/trendrating-report/resources/css/trendrating-report.scss";
@import "../js/app/pages/analysisMarkets/ETFs/widgets/BackNavigation/BackNavigation.module.scss";

@import "../js/app/pages/analysisLists/analyze/tabs/TabDispersion/Dispersion.module.scss";
@import "../js/app/pages/analysisLists/analyze/tabs/TabOverview/widgets/PerformerBox.module.scss";
@import "../components/DialogUpload/widgets/Matches.module.scss";
@import "../components/SortableList/SortableList.module.scss";

iframe {
  border: 0;
}

.ratio {
  position: relative;
  width: 100%;

  &::before {
    display: block;
    padding-top: 0;
    content: "";
  }

  &.ratio-16x9::before {
    padding-top: calc(9 / 16 * 100%);
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.tr_scrollbar_custom {
  &::-webkit-scrollbar {
    height: 7px;
    width: 5px;
    background-color: transparent;
    margin-left: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d3d3d3;
  }
}
