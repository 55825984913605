@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 26 May 2017
 * Project: Trendrating Web Application
 *
 * Compare list widget
 */
.compareList {
   height: 99%;
}

.compareList-selection {
   height: 93.5%;
   outline: 1px solid rgba(211, 211, 211, 1);
}

.compareList-header {
   background-color: rgba(211, 211, 211, 1);
   font-size: 100%;
   font-weight: 600;
   padding: .15em;
}

.compareList-list {
   list-style: none;
   margin: 0;
   padding: 0;
}

.compareList-item {
   border-bottom: 1px solid rgba(211, 211, 211, 1);
   display: flex;
   padding: .25em .15em;
   justify-content: space-between;
}

.compareList-buttonRemove {
   background-color: transparent;
   border: 0;
   font-weight: normal;
   margin: 0;
   padding: 0;
}