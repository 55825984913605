@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 04 Dec 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Button
 */
.buttonBar {
  text-align: right;
}

.button {
  background-color: #fff;
  border-color: #d3d3d3;
  border-style: solid;
  border-width: 1px;
  color: rgba(42, 112, 146, 1);
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 100%;
  padding: 0.26em 0.25em;
  text-align: center;
}

.button:hover {
  border-color: rgba(42, 112, 146, 1);
}

.button--primary {
  background-color: rgba(42, 112, 146, 1);
  color: #fff;
}

.button--login {
  background-color: #ffc001;
  border-color: #f5b901;
  color: #000;
  display: block;
  font-size: 110%;
  margin: 0 auto;
  padding: 2% 0.5%;
  width: 99.45%;
}

.button--login:hover {
  border-color: #d6a000;
}

.button--disabled,
.button[disabled],
.button--primary[disabled] {
  background-color: #d3d3d3;
  border: 1px solid #d3d3d3;
  color: #666;
  cursor: default;
}

.button--disabled:hover,
.button[disabled]:hover,
.button--primary[disabled]:hover {
  border-color: #d3d3d3;
  color: #666;
}

.button--left {
  float: left;
}

.button--margin-left {
  margin-left: 0.5em;
}

.button--switch {
  font-size: 85%;
  margin-right: 0.25em;
}

.button--alerts {
  font-size: 50%;
  margin-top: 0.5em;
  vertical-align: top;
}

.button--expand {
  background-color: #fff;
  border-bottom: 1px solid #d3d3d3;
  border-left: 1px solid #d3d3d3;
  cursor: pointer;
  padding: 0.5em;
  position: absolute;
  right: 0;
  top: 0;
}

.button--expand-screening {
  border: 1px solid #2a7090;
  border-radius: 4px;
  background-color: white;
  color: #2a7090;
  font-size: 0.7vw;
  z-index: 100;
  padding: 5px 5px;
  transition: all 0.5s;
}

.button--expand-screening:hover {
  background-color: #2a7090;
  color: white;
}

.button--help {
  font-family: "icons";
  padding: 0.3em;
  margin-left: 0;
}

.optimization__views .button--switch {
  font-size: 100%;
  margin: 0;
  padding: 0.25em;
  width: 8em;
}

.optimization__views .button--selected {
  background-color: #2a7092; /*#1d87b7*/
  color: #fff;
}

.button-bar {
  text-align: right;
}

.button-bar--analysis-collection-edit {
  margin: 0.15em 0;
}

.button-bar--interstitial {
  margin-top: 0.5em;
  text-align: center;
}

.button-bar--interstitial .button {
  background-color: #000;
  color: #fff;
  width: 10em;
}

.button-bar--interstitial .button:hover {
  background-color: #2a7092;
}
