.main {
  height: 100%;
  width: 100%;

  .filterRow {
    padding-bottom: 8px;
    display: flex;
    gap: 8px;
  }
}
