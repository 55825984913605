@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 15 Sep 2017
 *
 * Style guide : MaintainableCSS (http://maintainablecss.com)
 *
 * Button switch widget
 */

.trButtonSwitch {
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    min-height: 3.25em;
    outline: 1px solid rgba(211, 211, 211, 1);
}

.trButtonSwitch-icon {
    display: none;
    text-align: center;
}

.trButtonSwitch-label {
    display: none;
    text-align: center;
}

.trButtonSwitch--portfolioMomentumRating {
    background-image: url("../images/pmr_rate.png");
    width: 2.6em;
}

.trButtonSwitch--ratingWeight {
    background-image: url("../images/pmr_weights.png");
    width: 2.87em;
}

.trButtonSwitch--alert {
    background-image: url("../images/pmr_alerts.png");
    width: 3.5em;
}

.trButtonSwitch--marketAllocation {
    background-image: url("../images/pmr_markets.png");
    width: 9em;
}

.trButtonSwitch--sectorAllocation {
    background-image: url("../images/pmr_sectors.png");
    width: 9em;
}

.trButtonSwitch--upgrade {
    background-image: url("../images/ratingChanges_upgrades.png");
    width: 14.25em;
}

.trButtonSwitch--downgrade {
    background-image: url("../images/ratingChanges_downgrades.png");
    width: 14.25em;
}

.trButtonSwitch--newHigh {
    background-image: url("../images/newHL_high.png");
    width: 14.25em;
}

.trButtonSwitch--newLow {
    background-image: url("../images/newHL_low.png");
    width: 14.25em;
}

.trButtonSwitch--winner_05_days {
    background-image: url("../images/winners_05days.png");
    width: 9.3em;
}

.trButtonSwitch--winner_20_days {
    background-image: url("../images/winners_20days.png");
    width: 9.3em;
}

.trButtonSwitch--winner_60_days {
    background-image: url("../images/winners_60days.png");
    width: 9.3em;
}

.trButtonSwitch--loser_05_days {
    background-image: url("../images/losers_05days.png");
    width: 9.3em;
}

.trButtonSwitch--loser_20_days {
    background-image: url("../images/losers_20days.png");
    width: 9.3em;
}

.trButtonSwitch--loser_60_days {
    background-image: url("../images/losers_60days.png");
    width: 9.3em;
}

.trButtonSwitch--disabled {
    background-image: url("../images/isDisabled.png");
    background-repeat: repeat;
    background-size: 5px 5px;
}