$blur: blur(9px);
$rect: inset(0);

.toggle__control_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.header__summary__portfolio__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin-left: 20px;

  strong {
    font-size: 18px;
  }
}

.alternatives__content__left {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: 40%;

  .position__list__title__wrapper {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    margin-bottom: 20px;
    align-items: center;

    .position__list__title {
      margin-right: 15px;
      align-items: center;
    }

    .position__list__title__investment__universe__controls {
      display: flex;

      .basket__selector__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
      }
    }
  }
}

.positions__list__block {
  overflow: hidden;
  padding: 15px;

  .position__list__panel {
    display: flex;
    overflow: auto;
    flex: 1;

    .positions__list {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: auto;
      max-height: 100%;
      mask-image: linear-gradient(to bottom, black 60%, transparent);
      &::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
        margin-left: 5px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #d3d3d3;
      }
    }
  }
}

.error_message {
  font-size: 16px;
  font-weight: bold;
  color: #d8000c;
  padding: 20px 10px;
  border: 1px solid #d8000c;
  flex: 1;
  background-color: #ffd2d2;
  border-radius: 4px;
  text-align: center;
}

.warning_message {
  font-size: 16px;
  font-weight: bold;
  color: #9f6000;
  padding: 20px 10px;
  border: 1px solid #9f6000;
  flex: 1;
  background-color: #feefb3;
  border-radius: 4px;
  text-align: center;
}

.investment_universe_selector {
  display: flex;
  justify-content: center;
  font-size: 14px;

  label {
    margin-right: 5px;
  }
}

.alternatives__content__wrapper {
  display: flex;
  margin-top: 20px;
}

.card-active {
  border: 1px solid #2a7092;
}

.buttons_fade_wrapper {
  position: absolute;
  opacity: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  transition: 0.3s;

  &_btn {
    background-color: black;
    border: none;
    cursor: pointer;
    color: white;
    padding: 10px;
    font-size: 0.8vw;
    border-radius: 8px;

    &:hover {
      background-color: #2a7090;
    }
  }
}

.card_with_buttons:hover {
  .buttons_fade_wrapper {
    height: 50%;
    opacity: 1;
    width: 100%;
  }
}

.sold__card {
  border: 1px solid #ffc001;
}

.alternatives__card {
  flex: 1;
  position: relative;
  margin-bottom: 10px;
  margin-right: 0 !important;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;

  &:hover {
    box-shadow: inset 0px 4px 12px rgb(42 112 146 / 10%);
    border: 1px solid #2a7092;
  }
}

.alternatives__list__with__border {
  border: 1px solid #dddd;
  border-radius: 4px;
  padding: 20px;
}

.alternatives__sold__button__card {
  position: absolute;
  right: 10px;
  bottom: 10px;
  //   "./../../../../../styles/css_new/img/lamp-idea.svg"
  background-image: url("./../../../../../../../styles/css_new/img/lamp-idea.svg");
  background-size: cover;
  background-color: white;
  padding: 16px;
  border: none;
}

.position__list__item__card {
  display: flex;
  flex: 1;

  &__counter {
    position: absolute;
    top: 2;
    right: 10px;
    z-index: 2;
  }

  &__info {
    flex: 3;
    position: relative;

    &__rating {
      font-size: 16px;
      display: flex;
      align-items: center;

      strong {
        font-size: 18px;
        margin-right: 5px;
      }
    }
  }

  &__chart {
    flex: 2;
    padding: 0 0 0 10px;
    border-left: 1px solid #d3d3d3;
    display: flex;
    align-items: center;

    &__container {
      width: 100%;
      height: 100%;
    }
  }
}

.rank__template__title__wrapper {
  display: flex;
  align-items: center;

  p {
    &:first-child {
      margin-right: 5px;
    }
  }
}

@mixin swapListItem($borderColor) {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border-radius: 4px;
  margin-top: 10px;
  padding: 5px;
  position: relative;
  border: 1px solid $borderColor;

  &:first-child {
    margin-top: 0;
  }
}

.swap__list__custom__modal__body {
  height: 89.24%;
  display: flex;
  min-height: 0;
  flex: 1;
}

.dialog__swap__list__content {
  display: flex;
  flex: 1;
  min-height: 0;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 10%);
  padding: 10px;

  &__column {
    display: flex;
    flex: 1;
    min-height: 0;
    flex-direction: column;

    &__title__row {
      border-bottom: 1px solid #2a7092;
      padding-bottom: 5px;
      display: flex;
      padding: 10px;
      align-items: center;

      h2 {
        flex: 1;

        &:first-child {
          margin-right: 20px;
        }
      }
    }

    &__content__row {
      display: flex;
      overflow: auto;
      min-height: 90%;

      &::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #d3d3d3;
      }

      &__panel {
        flex: 1;
        display: flex;
        height: 100%;
        min-height: 0;
        flex-direction: column;

        &:first-child {
          margin-right: 20px;
        }

        &__list {
          display: flex;
          flex-direction: column;
          padding: 10px;
          margin-top: 10px;

          li {
            display: flex;
            align-items: center;
            margin-top: 5px;

            span {
              &:last-child {
                margin-left: 5px;
                color: #2a7092;
                font-size: 16px;
                display: none;
              }
            }

            &:hover {
              span {
                &:last-child {
                  margin-left: 5px;
                  color: #2a7092;
                  font-size: 16px;
                  display: block;
                  cursor: pointer;
                }
              }
            }
          }

          &__item {
            &__A {
              @include swapListItem(#168235);
            }

            &__B {
              @include swapListItem(#8bbd16);
            }

            &__C {
              @include swapListItem(#f9b352);
            }

            &__D {
              @include swapListItem(#f10408);
            }
            &__U {
              @include swapListItem(#2a7092);
            }
          }
        }
      }
    }
  }
}

.landing__swap__list {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #2a7092;
  border-radius: 4px;

  h3 {
    font-size: 22px !important; //Overwrite tooltip specific styles
    color: #2a7092;
    margin-bottom: 20px !important; //Overwrite tooltip specific styles
  }

  p {
    font-size: 18px !important; //Overwrite tooltip specific styles
  }
}
