@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 18 Dec 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Formatter
 */
.formatter {}

.formatter__number {}

.formatter__number--negative {
   color: #f00000;
}

.formatter__number--positive {
   color: #008000;
}

.formatter__string {}

.formatter__string--truncate {
   display: inline-block;
}