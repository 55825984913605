@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 22 Feb 2016
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Security analysis
 */
.analysis-security {
   margin: 0 auto;
   width: 70em;
}

.security-analysis__tip {
    background-color: #fff;
    border: 1px solid #d3d3d3;
    box-shadow: 3px 3px 3px 3px rgba(232, 232, 232, .75);
    font-size: 90%;
    margin-top: 1%;
    padding: .5em;
    position: relative;
    width: 45%;
    z-index: 100;
}

.security-analysis__tip-triangle {
    background-color: #fff;
    border-top: 1px solid #d3d3d3;
    border-right: 1px solid #d3d3d3;
    width: 15px;
    height: 15px;
    margin-left: 16px;
    margin-top: -14px;
    display: block;
    position: absolute;
    transform: rotate(-45deg);
    z-index: -1;
}

.security-analysis__tip-description {}

.security-analysis__tip-suggestion {}

.security-analysis__box {
   background-color: #fff;
   margin: 1% .5%;
   min-height: 16em;
   outline: 1px solid #d3d3d3;
   padding: 1% 2%;
   position: relative;
}

.security-analysis__box-title {
   margin-bottom: .5em;
}

.security-analysis__box--col-33 {
   height: 280px;
   margin-top: 6px;
   min-height: 0;
}

.security-analysis__box--col-50 {
   margin: .25%;
   margin-left: 0;
   width: 49.75%;
}

.security-analysis__box--col-50:last-child {
   margin-left: .25%;
   margin-right: 0;
}

.security-analysis__box--col-67 {
   height: 274px;
   margin-left: .25%;
   margin-right: 1%;
   margin-top: 6px;
   min-height: 0;
   overflow: hidden;
}

.security-analysis__box--col-100 {
   margin: 1% 0;
}

.security-analysis__box-tip {
   font-size: 80%;
}

.analysis-security__property {
   border-bottom: 1px solid #efefef;
   padding: .5em 0;
}
/*
.analysis-security__label,
.analysis-security__value {
   vertical-align: middle;
}
*/
.analysis-security__label {
   display: inline-block;
   width: 7em;
}

.analysis-security__value {
   display: inline-block;
   text-align: right;
   width: 14em;
}

.analysis-security__value--price {
   font-size: 150%;
}

.analysis-security__value--price-date {
   font-size: 90%;
}

.analysis-security__rate {
   display: inline-block;
   font-size: 200%;
   text-align: right;
   width: 7em;
}

.security-analysis__chart {
   width: 99%;
}