.searchInput {
  display: flex;
  overflow: hidden;

  input {
    all: unset;
    transition: width 0.5s;
    transition: background-color 0.5s;
    width: 0;
    overflow: hidden;

    &:focus {
      border-color: #2a7090;
      border-width: 2px;
      background-color: rgba(25, 118, 210, 0.08);
    }
  }

  &__open {
    input {
      padding: 2px 8px;
      border: 1px solid;
      border-color: rgba(0, 0, 0, 0.23);
      border-left: none;
      border-right: none;
      width: 85%;
    }
  }
}

.closeBtn {
  all: unset;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.23);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: none;
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: width 0.5s;
  transition: background-color 0.5s;

  color: rgba(42, 112, 144, 0.8);

  &:hover {
    color: rgba(42, 112, 144, 1);
  }

  &__focused {
    border-color: #2a7090;
    border-width: 2px;
    background-color: rgba(25, 118, 210, 0.08);
  }
}

.searchBox {
  display: flex;
}

.button {
  background-color: white;
  border: 1px solid #2a7090;
  border-radius: 4px;
  padding: 3px 5px;
  color: #2a7090;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #2a7090;
    color: white;
  }

  &__open {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: #2a7090;
    color: white;
  }
}

.resultsTable {
  width: 100%;
  border-spacing: 10px;
  border-collapse: collapse;

  tr {
    cursor: pointer;

    td {
      &:first-child {
        padding: 8px;
      }
    }

    &:hover {
      background-color: #2a7090;
      color: white;
    }
  }
}

.menuFooter {
  padding: 5px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.rowSelected {
  background-color: #2a7090;
  color: white;
}
