@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 28 Jan 2016
 * Project: Trendrating Application
 *
 * grid tools
 */
.grid-tools {
   display: flex;
   position: relative;
   text-align: center;
}

.grid-tools .add-to {
   flex: 0 0 auto;
}

.grid-tools .configurator {
    flex: 0 0 auto;
 }

.grid-tools__switch-view {
   flex: 0 0 auto;
}

.grid-tools .pagination {
   padding: .1em 0 .5em 0;
   position: relative;
   flex: 1 1 auto;
}

.grid-tools .pagination__items-per-page {
   flex: 0 0 auto;
}

.tSelectColumnDialogPopup {
    width: 95%;
}