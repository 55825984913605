.tooltip__content {
  &__issues__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.funnel__card {
  height: 100%;
  width: 35%;
  background-color: #f2f2f2 !important;

  &__content {
    background-color: #f2f2f2 !important;
    height: 100%;
    overflow: auto;
  }
}

.funnel__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__bar {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 4px 8px;

    &:hover {
      .funnel__list__bar__legend,
      .funnel__list__bar__number {
        font-weight: bold;
      }
    }

    &__legend {
      width: 25%;
      text-transform: none;
      color: black;
    }

    &__indicator {
      width: 70%;
      min-height: 46px;
      display: flex;
      padding: 4px;

      &__bar {
        border-radius: 10px;
        border: 2px solid transparent;
        cursor: pointer;
      }
    }

    &__number {
      width: 10%;
      text-align: center;
      text-transform: none;
    }
  }
}

.funnel__row {
  padding: 8px 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 10px;
  color: #2a7090;
  text-transform: uppercase;
  gap: 8px;
  cursor: pointer;
  border-color: #ddd;
  background-color: white;

  &:hover {
    opacity: 0.8;
    border-color: #2a7090;
  }

  &__gropLabel {
    text-align: "letf";
    border-bottom: 1px solid #ddd;
    width: 100%;
    margin-left: 1em;
  }

  &__subTable {
    width: 100%;
  }
}

.feedback__message {
  max-height: 9%;
  margin-bottom: 1%;
}

.tabsContainer {
  display: flex;
  height: 100%;
  gap: 8px;

  .tabsContainer__rightBox {
    width: 65%;
    height: 100%;
  }
}

.weights__chart__tab {
  height: 100%;
  width: 100%;
  overflow: auto;

  &__title {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.tabsRow__box {
  height: 10%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.headerHighlight {
  border-right: 1px solid #2a7090 !important;
  border-left: 1px solid #2a7090;
}

.legacyRationale {
  height: 100%;
}
