@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 22 Oct 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Select widget
 */
.form-select {
   display: inline-block;
   font-size: 1.5em;
   text-align: center;
   width: 1.5em;
}

.claro .form-select {
   background-color: transparent;
   background-image: none;
   border: 0;
}

.form-select--market {
   -moz-transform: rotate(-90deg);
   -ms-transform: rotate(-90deg);
   -webkit-transform: rotate(-90deg);
   transform: rotate(-90deg);
}