@charset "UTF-8";

/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 04 Dec 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Application icons
 */

// Force (dart-)sass to not change the unicode string to a unicode character
// it can shows issues in Chrome / WebKit sometimes, changing to Times New Roman
@function unicode($str) {
    @return unquote('"') + unquote(str-insert($str, "\\", 1)) + unquote('"');
}

@font-face {
    font-display: block;
    font-family: "icons";
    src: url("../fonts/icons.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

[class^="i-"],
[class*=" i-"] {
    font-family: "icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    text-rendering: geometricPrecision;
}

.i-apps:before {
    content: unicode("e909");
}
.i-arrow-angle:before {
    content: unicode("e908");
}
.i-color:before {
    content: unicode("e905");
}
.i-right-lite:before {
    content: unicode("e900");
}
.i-left-lite:before {
    content: unicode("e901");
}
.i-down-lite:before {
    content: unicode("e902");
}
.i-up-lite:before {
    content: unicode("e903");
}
.i-chart-pie-on:before {
    content: unicode("e906");
}
.i-chart-pie-off:before {
    content: unicode("e907");
}
.i-menu:before {
    content: unicode("e9bd");
}
.i-load:before {
    content: unicode("e904");
}
.i-enlarge:before {
    content: unicode("e989");
}
.i-collapse:before {
    content: unicode("e98a");
}
.i-chart:before {
    content: unicode("e99b");
}
.i-help:before {
    content: unicode("ea09");
}
.i-info:before {
    content: unicode("ea0c");
}
.i-cancel:before {
    content: unicode("ea0d");
}
.i-arrow-u:before {
    content: unicode("ea3a");
}
.i-upgrade:before {
    content: unicode("ea3b");
}
.i-arrow-r:before {
    content: unicode("ea3c");
}
.i-downgrade:before {
    content: unicode("ea3d");
}
.i-arrow-d:before {
    content: unicode("ea3e");
}
.i-page-break:before {
    content: unicode("ea68");
}
.i-calendar:before {
    content: unicode("e953");
}
.i-search:before {
    content: unicode("f002");
}
.i-skip:before {
    content: unicode("f00d");
}
.i-zoom-in:before {
    content: unicode("f00e");
}
.i-zoom-out:before {
    content: unicode("f010");
}
.i-settings:before {
    content: unicode("f013");
}
.i-delete:before {
    content: unicode("f014");
}
.i-edit:before {
    content: unicode("f040");
}
.i-left:before {
    content: unicode("f053");
}
.i-right:before {
    content: unicode("f054");
}
.i-alternative:before {
    content: unicode("f074");
}
.i-up:before {
    content: unicode("f077");
}
.i-down:before {
    content: unicode("f078");
}
.i-no-change:before {
    content: unicode("f07d");
}
.i-unchecked:before {
    content: unicode("f096");
}
.i-beta:before {
    content: unicode("f0ae");
}
.i-alpha:before {
    content: unicode("f0b0");
}
.i-replicate:before {
    content: unicode("f0c5");
}
.i-grid:before {
    content: unicode("f0ce");
}
.i-triangle-down:before {
    content: unicode("f0d7");
}
.i-triangle-up:before {
    content: unicode("f0d8");
}
.i-no-sort:before {
    content: unicode("f0dc");
}
.i-feedback:before {
    content: unicode("f0e5");
}
.i-switch:before {
    content: unicode("f0ec");
}
.i-bell:before {
    content: unicode("f0f3");
}
.i-undo:before {
    content: unicode("f112");
}
.i-more-content:before {
    content: unicode("f141");
}
.i-more-actions:before {
    content: unicode("f142");
}
.i-checked-partial:before {
    content: unicode("f146");
}
.i-checked:before {
    content: unicode("f14a");
}
.i-sort-asc:before {
    content: unicode("f160");
}
.i-sort-desc:before {
    content: unicode("f161");
}
.i-edu:before {
    content: unicode("f19d");
}
.i-chart-pie:before {
    content: unicode("f200");
}
.i-toggle-off:before {
    content: unicode("f204");
}
.i-toggle-on:before {
    content: unicode("f205");
}
.i-percent:before {
    content: unicode("f295");
}
