.tPinnedPreferences {
  width: 100%;

  &__container {
    display: flex;
    gap: 10px;
    width: 100%;
  }
}

.column {
  &:first-child {
    width: 60%;
  }
  &:nth-child(2) {
    width: 40%;
  }
  display: inline-block;

  .tFormList {
    width: none !important;
    border: 1px solid #d3d3d3;

    & .tFormListItems {
      background-color: transparent;
      // height: 197px;
      overflow: auto;
      position: relative;
    }
  }
}
