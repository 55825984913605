.main {
  height: 100%;
  width: 100%;

  &__card {
    height: 100%;
    width: 100%;

    &__content {
      height: 100%;
      width: 100%;
      display: flex;
      min-height: 0;
      padding: 0 !important;
      flex-direction: column;

      &__section__title {
        display: flex;
        align-items: "center";
        justify-content: space-between;
        padding: 8px;

        &__btnGroup {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }

      &__section__content {
        display: flex;
        height: 100%;
        width: 100%;
        min-height: 0;
        min-width: 0;

        &__scrollable {
          flex: 8.5;
          height: 100%;
          flex-direction: column;
          padding: 8px;
          padding-top: 0;
          display: flex;
          min-height: 0;
          min-width: 0;
        }

        &__filters {
          padding-left: 8px;
          gap: 8px;
          display: flex;
          flex-direction: column;
          flex: 1.5;
          overflow: auto;
        }
      }
    }
  }
}

.country__btn {
  padding: 5px;
  display: flex;
  border: 1px solid transparent;
  border-radius: 6px;
  width: 10vw;
  border: 2px solid #ddd;
  background-color: white;
  color: grey;
  cursor: pointer;
  justify-content: space-between;
  transition: all 0.5s;
  gap: 8px;

  &:hover {
    color: #2a7090;
    text-decoration: underline;
  }

  &__content {
    cursor: pointer;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  &__active {
    border-color: #2a7090;
    color: #2a7090;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.tableCard {
  margin-top: 8px;
  height: 100%;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__content {
    height: 100%;
    display: flex;

    &__tableCardTitle {
      text-transform: uppercase;
      color: #2a7090;
    }
  }
}
