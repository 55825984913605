@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 22 Apr 2021
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Grid ranking from date selector
 */

.ranking-from-date {
    padding: 0.25em 0.5em 0.25em 1.5em;
}

.rankingFromDate {
    margin-top: -0.15em;
}

.rankingFromDate label {
    font-weight: bold;
    color: #2a7092;
    vertical-align: middle;
}

.rankingFromDate .rankingFromDate-select {
    background-color: #2a7092;
    color: #fff;
}

.rankingFromDate .rankingFromDate-select:hover {
    background-color: #00496d;
    color: #fff;
}
