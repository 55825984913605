.root {
  font-size: 12px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.select_wrapper {
  display: flex;
  gap: 8px;
}

.card {
  flex: 1;
}
