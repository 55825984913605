@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 04 Dec 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Form
 */
.form {
}

.form__fieldset {
}

.form__fieldset--builder {
    /*background-image: radial-gradient(circle farthest-side at center bottom, #2a7092 0%, #fff 90%);
   background-position: 50% 100%;
   background-repeat: no-repeat;
   background-size: 100% 1px;*/
    background-color: #fff;
    border: 1px solid #d3d3d3;
    margin-bottom: 0.75em;
    /*padding-bottom: .35em;*/
    padding: 0.25em;
}

.form__legend {
    /*margin-bottom: .25em;*/
    margin: 0;
    padding: 0 0 1em 0;
}

.form__legend--builder {
    background-color: #fff;
    border: 1px solid #d3d3d3;
    font-weight: 600;
    padding: 0 0.25em 0 0;
    /*margin-left: .15em;*/
}

.form__field-list {
}

.form__label {
    display: inline-block;
    margin: 0 0 0.25em 0;
}

.form__label--checkBox {
    margin-left: 0.25em;
}

.form__label--checkBox-spacer {
    margin-right: 1em;
}

.form__label--rate {
    margin-right: 1em;
}

.form__separator {
    background-color: #2a7092;
    height: 1px;
    margin: 0.5em 0;
}

.form__field {
    margin-bottom: 0.75em;
}

.form__field-title {
    display: inline-block;
    margin-right: 1em;
}

.form__field-help {
    cursor: pointer;
}

.form__field-text,
.form__field-textarea {
    border: 1px solid #d3d3d3;
    display: block;
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    margin: 0 auto;
    padding: 1% 0.5%;
    width: 98.25%;
}

.form__field-text:hover,
.form__field-textarea:hover {
    background-color: #e5f2fe;
    border-color: #2a7092;
}

.form__field-text:focus {
    background-color: #fff;
    border-color: #2a7092;
}

.form__buttons {
    /* will replace form__item--button-bar */
    text-align: right;
}

.form__slider-rule {
    padding: 0 1.9em;
}

.form__reset-password {
}

.form__help {
    float: right;
}

.form__group-label {
    display: inline-block;
}

.form__more {
    /*height: 0;*/
}

.form__collapser {
    color: #2a7092;
    cursor: pointer;
    /*position: relative;*/
    text-align: right;
    /*top: -1.5em;*/
}
/* ----------------------------------------------------------------- Modifier */
.form__item--button-bar {
    text-align: right;
}

.form--login {
}

.form--login .form__legend {
    text-align: center;
    font-size: 150%;
    margin: 0;
    margin-bottom: 1.75em;
    padding: 0;
    width: 100%;
}

.form--login .form__field-list {
    background-color: #fff;
    border: 1px solid #d3d3d3;
    margin: 0 auto;
    width: 20em;
    padding: 1em;
}

.form--login .form__field-text {
    font-size: 110%;
}

.form__field--action-primary {
}

.form__field--action-secondary {
    margin-bottom: 0;
}

.form__field--child {
    margin-left: 1.25em;
}

.form__field--disclaimer {
    font-size: 70%;
}

/* ----------------------------------------------------------------- Field collapser */

.field_collapser {
    display: flex;
    flex-direction: column;
}

.field_collapser_controls {
    display: flex;
    align-self: flex-end;
    align-items: center;
    color: #2a7092;
    cursor: pointer;
}

.field_collapser_controls > p:first-child {
    margin-right: 2px;
}

.bottom-space {
    margin-bottom: 10px;
}
