.row {
  display: flex;
}

.dispersion_widgets_row {
  display: flex;
  gap: 8px;
}

.section_header {
  margin-bottom: 20px;
  display: flex;
  font-size: 16px;
  align-items: center;

  h2 {
    font-size: 16px;
  }
}

.tablePanel_wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
  gap: 8px;
}

.controls_wrapper {
  justify-content: space-around;
  align-self: center;
}

.controls_block {
  text-align: center;

  p {
    margin-bottom: 10px;
  }
}

.controls_title_tooltip {
  display: flex;
}

.warning {
  color: #9f6000;
  background-color: #feefb3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #9f6000;
  padding: 30px 20px;
  font-size: 14px;
  flex: 1 1 100%;
}

.error {
  color: #d8000c;
  background-color: #ffd2d2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #d8000c;
  padding: 30px 20px;
  font-size: 14px;
  flex: 1 1 100%;
}
