.loader {
    background-color: #f2f2f2;
    color: #000;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 900;
}

.loader__message {
    margin-top: 48vm; /* IE */
    margin-top: 48vmin;
    text-align: center;
}

.loader__submessage {
    margin-top: 0.5em;
    font-size: 1.15em;
    text-align: center;
}

.loader--data {
    position: absolute;
    z-index: 899;
}

.loader--data .loader__message {
    margin-top: 43vm; /* IE */
    margin-top: 43vmin;
}

.loader--data .loader__message-more {
    font-size: 110%;
    margin-top: 1em;
    text-align: center;
}
