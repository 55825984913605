.smsIndexCard {
  min-height: 30em;
  border: 2px solid;
  transition: 0.5s !important;
  cursor: pointer;
  border-color: transparent;

  &:hover {
    border-color: #2a7090;
  }

  .smsIndexCardContent {
    display: flex;
    flex-direction: column;
    min-height: 0;
    height: 100%;
    padding-bottom: 8px !important;
    padding-top: 8px !important;
    gap: 8px;

    .productNameTitle {
      font-size: 0.9vw;
    }

    .smsPreviewBox {
      display: flex;
      flex: 1;
      gap: 8px;
      min-height: 0;

      .analyticCard {
        flex: 1;
      }

      .chartCard {
        flex: 3;
        min-height: 0;
        display: flex;

        .chartCardContent {
          flex: 1;
          min-height: 0;
        }
      }
    }
  }
}
