.block__card {
  flex: 1;
  display: flex;
}

.top {
  border-left: 4px solid #008005 !important;
  border-right: 4px solid #008005 !important;
}

.middle {
  border-left: 4px solid #f01100 !important;
  border-right: 4px solid #f01100 !important;
}

.bottom {
  border-left: 4px solid #af0900 !important;
  border-right: 4px solid #af0900 !important;
}

.block__column {
  display: flex;
  flex: 1;
  flex-direction: column;

  &__rating__section {
    display: flex;
    flex: 1;
    margin: 5px 0;
  }

  &__interval__section {
    justify-content: center;
    font-size: 18px;
  }

  &__average__section {
    justify-content: center;

    span {
      strong {
        font-size: 18px;
      }
    }
  }

  &__cardinality__section {
    justify-content: center;
  }
}

.min__max__column {
  flex-direction: column;
  justify-content: space-between;
}

.rating__box__card {
  &:first-child {
    margin-right: 10px;
  }

  .rating__box__title {
    text-align: center;
    border-bottom: 1px solid #ddd;
  }

  .rating__box__content {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
