@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 18 Dec 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Grid pagination
 */
.pagination {
    display: inline-block;
    vertical-align: middle;
}

.pagination label {
    vertical-align: middle;
}

.pagination__extras {
}

.pagination__items-per-page {
    display: inline-block;
    float: right;
    margin-top: -0.1em;
}

.pagination__total {
    display: inline-block;
    margin-left: 7em;
    vertical-align: middle;
}

.pagination__navigator {
    display: inline-block;
    margin-left: 1em;
    vertical-align: middle;
}

.pagination__arrow {
    cursor: pointer;
}

.pagination__arrow--disabled {
    color: #d3d3d3;
    cursor: default;
}

.pagination__page {
    cursor: pointer;
    display: inline-block;
    padding: 0 0.25em;
}

.pagination__page--selected {
    cursor: default;
}
