.tab__selector__group {
  border-bottom: 2px solid transparent;
  border-bottom-width: 0;
  padding: 2px 10px;
  transition: 0.5s;
  cursor: pointer;

  &:first-child {
    margin-right: 5px;
  }

  &:hover {
    border-bottom-width: 100%;
    border-bottom: 2px solid #2a7090;
  }

  &__active {
    border-bottom: 2px solid #2a7090;
    border-bottom-width: 100%;
  }
}

.quartiles__table {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 6px;
  padding: 5px 8px;
  border: 2px solid;
  border-color: transparent;
  transition: 0.5;
  cursor: pointer;
  max-height: 100%;

  &:hover {
    border-color: #2a7090;
  }

  &__active {
    border-color: #2a7090;
  }

  &:last-child {
    margin-top: 8px;
  }

  tHead {
    tr {
      th {
        border-bottom: 1px solid #d3d3d3;
        padding: 5px 8px;
        font-size: min(1vw, 16px);

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  tBody {
    tr {
      td {
        &:last-child {
          text-align: right;
        }

        font-size: min(0.8vw, 16px);
        padding: 5px 10px;
      }
    }
  }

  tFoot {
    padding: 5px 8px;

    tr {
      border-radius: 6px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
        rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;

      td {
        font-size: min(1vw, 16px);
        padding: 5px 8px;
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}

.unhoverable {
  cursor: unset;
  &:hover {
    border-color: transparent;
  }
}

.clickcable__peer__card {
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    border-color: #2a7090;
  }
}

.clickableTable {
  border-radius: 6px;
  width: 100%;
  border-spacing: 10px 0;
  cursor: pointer;
  border: 2px solid transparent;

  &:hover {
    border-color: #2a7090;
  }
}
