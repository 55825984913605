.tippy-box[data-placement^="top"] > .tippy-backdrop {
    transform-origin: 0 25%;
    border-radius: 40% 40% 0 0;
}
.tippy-box[data-placement^="top"] > .tippy-backdrop[data-state="visible"] {
    transform: scale(1) translate(-50%, -55%);
}
.tippy-box[data-placement^="top"] > .tippy-backdrop[data-state="hidden"] {
    transform: scale(0.2) translate(-50%, -45%);
}
.tippy-box[data-placement^="bottom"] > .tippy-backdrop {
    transform-origin: 0 -50%;
    border-radius: 0 0 30% 30%;
}
.tippy-box[data-placement^="bottom"] > .tippy-backdrop[data-state="visible"] {
    transform: scale(1) translate(-50%, -45%);
}
.tippy-box[data-placement^="bottom"] > .tippy-backdrop[data-state="hidden"] {
    transform: scale(0.2) translate(-50%);
}
.tippy-box[data-placement^="left"] > .tippy-backdrop {
    transform-origin: 50% 0;
    border-radius: 50% 0 0 50%;
}
.tippy-box[data-placement^="left"] > .tippy-backdrop[data-state="visible"] {
    transform: scale(1) translate(-50%, -50%);
}
.tippy-box[data-placement^="left"] > .tippy-backdrop[data-state="hidden"] {
    transform: scale(0.2) translate(-75%, -50%);
}
.tippy-box[data-placement^="right"] > .tippy-backdrop {
    transform-origin: -50% 0;
    border-radius: 0 50% 50% 0;
}
.tippy-box[data-placement^="right"] > .tippy-backdrop[data-state="visible"] {
    transform: scale(1) translate(-50%, -50%);
}
.tippy-box[data-placement^="right"] > .tippy-backdrop[data-state="hidden"] {
    transform: scale(0.2) translate(-25%, -50%);
}
.tippy-box[data-animatefill] {
    background-color: transparent !important;
}
.tippy-backdrop {
    position: absolute;
    background-color: #333;
    border-radius: 50%;
    width: calc(110% + 32px);
    left: 50%;
    top: 50%;
    z-index: -1;
    transition: all cubic-bezier(0.46, 0.1, 0.52, 0.98);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.tippy-backdrop[data-state="hidden"] {
    opacity: 0;
}
.tippy-backdrop:after {
    content: "";
    float: left;
    padding-top: 100%;
}
.tippy-backdrop + .tippy-content {
    transition-property: opacity;
    will-change: opacity;
}
.tippy-backdrop + .tippy-content[data-state="hidden"] {
    opacity: 0;
}
