@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 27 Sep 2017
 * Project: Trendrating Web Application
 *
 * Dialog for report configuration
 */

.dialogReport {
    box-sizing: border-box;
    width: 70em;
    position: relative;
}

.dialogReport.dialogReport-designer {
}

.dialogReport.dialogReport--autoWidth {
    width: 85vw; /* markets: strategic allocation */
}

.dialogReport-buttonBar {
    padding: 8px;
}

.dialogReport-overlay {
    background-color: rgba(255, 255, 255, 0.85);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 300;
}

.dialogReport-overlay--disabled {
    display: none;
}

.dialogReport-overlayMessage {
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    position: relative;
}

.dialogReport-headerTitle {
    flex: 0 0 auto;
    height: 30px; /* same as dialogReport-top-item */
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1.3em;
}

.dialogReport-headerTitle.dialogReport-headerTitle--preview {
    text-align: center;
}

.dialogReport-header {
    margin-bottom: 0.5em;

    /*display: flex;
    align-items: baseline;
    justify-content: space-between;*/
}

.dialogReport-wrap {
    display: flex;
    flex-wrap: nowrap;
}

.dialogReport-wrap .dialogReport-left {
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
    padding: 8px 24px 0 8px;
}

.dialogReport-wrap .dialogReport-right {
    flex: 1 1 50%;
    padding: 8px 8px 0 24px;
}

.dialogReport-wrap .dialogReport-left-simple {
    display: flex;
    pointer-events: all;
    flex-direction: column;
    flex: 1 1 100%;
}

.dialogReport-wrap .dialogReport-left-complex {
    display: none;
    pointer-events: none;
    flex-direction: column;
    flex: 1 1 100%;
}

.dialogReport .dialogReport-left-complex .dialogReport-top {
    flex: 0 0 auto;
}

.dialogReport .dialogReport-left-complex .dialogReport-header {
    flex: 0 0 auto;
}

.dialogReport .dialogReport-left-complex .dialogReport-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
}

.dialogReport .dialogReport-left-complex .dialogReport-content label {
    flex: 0 0 auto;
}

.dialogReport
    .dialogReport-left-complex
    .dialogReport-content
    .tSectionSelector {
    flex: 1 1 auto;
    position: relative;
}

.dialogReport
    .dialogReport-left-complex
    .dialogReport-content
    .tSectionSelector-sections {
    height: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.isCustomizing .dialogReport-wrap .dialogReport-left-simple {
    display: none;
    pointer-events: none;
}

.isCustomizing .dialogReport-wrap .dialogReport-left-complex {
    display: flex;
    pointer-events: all;
}

.dialogReport-wrap .dialogReport-right .dialogReport-headerTitle {
    flex: 0 0 auto;
}

.dialogReport-wrap .dialogReport-right .dialogReport-designer {
    flex: 1 1 auto;
}

/* Content */

.dialogReport-buttonBar .dialogReport-buttonBar-simple {
    float: left;
    display: block;
    pointer-events: all;
}

.dialogReport-buttonBar .dialogReport-buttonBar-complex {
    float: left;
    display: none;
    pointer-events: none;
}

.dialogReport-buttonBar .tFormButton--preview {
    display: none;
}

.dialogReport-templateList {
    flex: 1 1 auto;
}

.isCustomizing .dialogReport-buttonBar .tFormButton--preview {
    display: inline-block;
}

.isCustomizing .dialogReport-buttonBar .dialogReport-buttonBar-simple {
    display: none;
    pointer-events: none;
}

.isCustomizing .dialogReport-buttonBar .dialogReport-buttonBar-complex {
    display: block;
    pointer-events: all;
}

.dialogReport-wrap
    .dialogReport-right
    .tFieldsetDynamic
    .tFieldDynamic-layoutWithSetting
    .tFieldDynamic-actionsWrap {
    display: none;
}

.isCustomizing
    .dialogReport-wrap
    .dialogReport-right
    .tFieldsetDynamic
    .tFieldDynamic-layoutWithSetting
    .tFieldDynamic-actionsWrap {
    display: block;
}

.tReportDesigner.dialogReport-designer .tFieldDynamic-layout {
    width: 100%;
}

.dialogReport-wrap
    .dialogReport-right
    .tReportDesigner.dialogReport-designer
    .tFieldDynamic {
    position: relative;
    padding: 2px;
    margin: 2px;
    border-color: transparent;
}

.dialogReport-wrap
    .dialogReport-right
    .tReportDesigner.dialogReport-designer
    .tFieldDynamic.tReportPageBreak {
    padding-top: 15px;
    padding-bottom: 15px;
}

.dialogReport-header-sections {
}

.dialogReport-header-section {
    margin-bottom: 0.5em;
    display: block;
    width: 100%;
}

.dialogReport-header-section strong {
    display: block;
    margin-bottom: 0.5em;
}

.dialogReport-header-section label {
    margin-right: 0.5em;
}

/******* 5 feb 2020 Custom design like Chrome print page **********************/
.dialogReport-top {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
}

.dialogReport-top-item {
    flex: 1 1 auto;
    font-size: 1.3em;
    height: 30px; /* same as dialogReport-headerTitle */
}

.dialogReport-top-item.dialogReport-top-item--right {
    text-align: right;
}

.dialogReport-top-item .dialogReport-fastAccess {
    display: inline-block;
}

.dialogReport-top-item .dialogReport-fastAccess .tFormFastAccess {
    padding: 0;
}

.dialogReport-top-item .dialogReport-fastAccess .tFormFastAccess-label {
    display: inline-block;
    text-overflow: ellipsis;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: bottom;
}

.dialogReport-designer {
}

.dijitDialog.trReportPrintStyle {
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.claro .dijitDialog.trReportPrintStyle .dijitDialogCloseIcon {
    top: 11px;
}

.claro .dijitDialog.trReportPrintStyle .dijitDialogPaneContent {
    padding: 0;
}

.dialogReport-designer .tFieldsetDynamic {
    border: none;
}

/******* End custom style *****************************************************/

.dialogReport-designer .tFieldDynamic {
    position: relative;
}

.dialogReport-designer .tFieldsetDynamic-fields {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 60vh;
    border: 1px solid #d3d3d3;
}

/* See reportContent.m.css for more styles, search for .isNotCustomizing */

.dialogReport-designer .tFieldsetDynamic-field {
    background-color: #ffffff;
    border-left: 1px solid black;
    border-right: 1px solid black;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.3);
    margin-left: 20px;
    margin-right: 20px;
}

.dialogReport-designer .tFieldsetDynamic-field:first-child {
    border-top: 1px solid black;
    margin-top: 30px;
}

.dialogReport-designer .tFieldsetDynamic-field:last-child {
    border-bottom: 1px solid black;
    margin-bottom: 30px;
}

.dialogReport-designer .tFieldDynamic.isEdited:after {
    display: block;
    position: absolute;
    right: 5px;
    top: 5px;
    content: "Edited";
    background-color: #ff7700;
    padding: 2px;
}

.dialogReport-designer .tFieldDynamic-layoutWithSetting {
    position: relative;
    min-height: 50px;
}

.dialogReport-designer
    .tFieldsetDynamic
    .tFieldDynamic-layoutWithSetting
    .tFieldDynamic-content {
    width: 100%;
}

.dialogReport-designer .tSectionSelector {
    width: 100%;
}

.dialogReport-designer .tSectionSelector-sections {
}

.dialogReport-designer.dialogReport-designer--strategy
    .tSectionSelector-sections {
}

.tSectionSelector {
}

.dialogReport-loader {
    background-color: rgba(255, 255, 255, 1);
    color: rgba(0, 0, 0, 1);
    width: 100%;
    z-index: 300;
}

/* http://dev.gojko.net/web/2015/09/19/material-design-progress-pure-css.html */
.dialogReport-loaderBar,
.dialogReport-loaderBar:before {
    height: 5px;
    width: 100%;
    margin: 0;
}

.dialogReport-loaderBar {
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
}

.dialogReport-loaderBar:before {
    background-color: rgba(33, 150, 243, 1); /* rgba(42, 112, 146, 1); */
    content: "";
    animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes running-progress {
    0% {
        margin-left: 0px;
        margin-right: 100%;
    }
    50% {
        margin-left: 25%;
        margin-right: 0%;
    }
    100% {
        margin-left: 100%;
        margin-right: 0;
    }
}
