.listItem {
  color: black;
  border: solid 1px transparent;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  position: relative;
  
  text-overflow: ellipsis;
  padding: 10px;

}

.listItemSortable {
  color: black;
  border: solid 1px transparent;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  position: relative;
  
  cursor: grab;
  text-overflow: ellipsis;
  padding: 10px;
  &:active {
    cursor: grabbing; 
  }
  &:hover {
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.289);
    .closeBTN{
      display: block;
    }
  }
}


.before {
  transition: all .2s ease-out;  
  border-top: black 5px solid;
  border-bottom: transparent 0px solid;
  border-left: transparent 0px solid;
  border-right: transparent 0px solid;
}

.after {
  transition: all .2s ease-out;
  border-bottom: black 5px solid;
  border-top: transparent 0px solid;
  border-left: transparent 0px solid;
  border-right: transparent 0px solid;
}


.closeBTN{
  display: none;
}