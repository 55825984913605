.label {
    color: #2a7092;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dropdown-list {
    position: fixed;
    z-index: 999999;
    border: solid 1px #2a7092;
    min-width: 120px;
    background-color: white;

    p {
        z-index: 999999;
        display: flex;
        align-items: center;
        padding: 5px;
        justify-content: space-between;

        &:hover {
            color: white;
            background-color: #2a7092;
        }
    }
}

.sublevel-list {
    z-index: 999999;
    position: fixed;
    border: solid 1px #2a7092;
    min-width: 120px;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;

    li {
        z-index: 999999;
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;

        span {
            margin-left: 5px;
        }

        &:hover {
            color: white;
            background-color: #2a7092;
        }
    }
}
