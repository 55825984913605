.root__select {
  font-size: 12px !important;
  padding: 2px 10px !important;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    color: #2a7090;
  }
}
