@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 07 Sep 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Collection wizard: suggests alternatives for bad securities
 */
.claro .dijitDialogPaneContent {
    padding: 4px 8px;
}

.wizard {
    clear: both;
    overflow: auto;
}

.wizard__bad-security {
    background-color: #f6f6f6;
    border-bottom: 1px solid #d3d3d3;
    border-top: 1px solid #d3d3d3;
    padding: 1% 6%;
}

.wizard__alternatives-settings,
.wizard__alternatives-summary,
.wizard__alternatives-tip {
    font-size: 150%;
    margin: 0.4em 0;
}

.wizard__alternatives-manual {
    margin-top: 0.5em;
}

.wizard__securities {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.wizard__security {
    background-color: #fff;
    color: #000;
    margin-bottom: 0.5em;
    border: 1px solid #d3d3d3;
    position: relative;
}

.wizard__security-weight {
    font-weight: 600;
}

.wizard__security-logo,
.wizard__security-name {
    display: inline-block;
    vertical-align: middle;
}

.wizard__security-name {
    font-size: 150%;
    margin-bottom: 0.5em;
}

.wizard__security-logo {
    background-image: none;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    float: right;
    height: 64px;
    outline: 1px solid #d3d3d3;
    width: 64px;
}

.wizard__security-type-and-market {
    color: #aaa;
    margin-bottom: 0.5em;
}

.wizard__security-rating {
    margin-bottom: 0.5em;
}

.wizard__security-price {
    font-size: 110%;
}

.wizard__security-chart-container {
    text-align: right;
}

.wizard__security-chart {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 15vh;
    width: 100%;
    /* height: auto;
   vertical-align: top;
   width: 100%; */
}

.wizard__security-actions {
    bottom: 0;
    right: 35%;
    position: absolute;
}

.wizard__alternatives {
    min-height: 10em;
    padding-top: 0.5%;
    overflow: hidden;
}

.wizard__alternatives-area {
    display: inline-block;
    vertical-align: middle;
}

.wizard__alternatives-list {
    margin: 0;
    width: 88%;
}

.wizard__alternatives-button {
    text-align: center;
    width: 5%;
}

/* .wizard__security-counter {
    font-weight: 600;
    left: 0;
    margin: 1%;
    position: absolute;
    top: 0;
} */
.wizard__security-counter {
    font-weight: 600;
    padding: 4px;
}

.wizard__security .layout-grid__col {
    vertical-align: middle;
}

.wizard__security .layout-grid__col--50 {
    background-image: linear-gradient(to bottom, #d3d3d3 0, #d3d3d3 100%),
        linear-gradient(to bottom, #d3d3d3 0, #d3d3d3 100%);
    background-position: 0 0, 100% 0;
    background-repeat: repeat-y;
    background-size: 1px;
    margin: 1em 0;
    padding: 0 1% 0 5%;
    width: 43%;
}

.wizard__security .layout-grid__col--15,
.wizard__security .layout-grid__col--35 {
    text-align: right;
}

.wizard__security .layout-grid__col--15 {
    font-size: 110%;
    padding: 0 5% 0 0;
    width: 10%;
}

.wizard__security .layout-grid__col--35 {
    padding-left: 2%;
    width: 33%;
}

.wizard__security .menu--actions {
    background-color: transparent;
    color: #000;
    font-weight: 600;
    overflow: hidden;
}

.wizard__security .menu__item {
    background-color: #000;
    color: #fff;
    margin: 0 0.5em 0.25em 0.5em;
    min-width: 6em;
    padding: 0.25em;
}

.wizard__security .menu__item:hover {
    background-color: #2a7092;
}

.wizard__switch .wizard__security .layout-grid__col--50 {
    background-image: linear-gradient(to bottom, #d3d3d3 0, #d3d3d3 100%);
    background-position: 100% 0;
    background-repeat: repeat-y;
    background-size: 1px;
    margin: 0.5em 0;
    padding: 0 1% 0 5%;
    width: 44%;
}

.wizard__switch .wizard__security .wizard__security-chart-container {
    background: none;
    padding: 0;
    padding-left: 1%;
    text-align: center;
    width: 49%;
}

.wizard__switch .wizard__security-chart {
    height: 30vh;
    /* height: 16em;
   vertical-align: top;
   width: auto; */
}

.wizard__switch .wizard__security .menu--actions {
    bottom: 2em;
    right: 50%;
}

.wizard__alternatives-settings .tFormToggle-cursor {
    font-size: 90%;
    display: inline-block;
    vertical-align: baseline;
}

.wizard__alternatives-manual .search {
    display: inline-block;
    vertical-align: middle;
}
