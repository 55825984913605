.listItemContent {
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    // color: #ffc001;
    font-weight: bolder;
  }
}

.listItem {
  &:not(:last-child) {
    margin-bottom: 5px;
  }
  display: flex;
  align-items: center;
}
