.strategyBoxHoverable {
  color: black;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  background-color: transparent;
  transition: 0.5s;
  font-size: 0.8vw;
  padding: 5px;

  &:hover {
    color: #2a7090;
    background-color: rgba(#000000, 0.1);
  }
}
