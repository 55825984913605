@charset "utf-8";

/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 13 Oct 2017
 *
 * Style guide : MaintainableCSS (http://maintainablecss.com)
 *
 * Report content widget
 */

.trReportContent {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 4.25em;
}

.trReportContent--disclaimer {
    min-height: auto;
}

.trReportContent-fields {
    list-style: none;
    margin: 0;
    padding: 0.5em;
}

.trReportContent-field {
    margin-bottom: 0.25em;
}

.trReportContent-field:last-child {
    margin-bottom: 0;
}

.trReportContent-label {
    display: inline-block;
    vertical-align: top;
    width: 8em;
}

.trReportContent-input {
    vertical-align: top;
    width: 20.35em;
}

.trReportContent-textarea {
    vertical-align: top;
    width: 98.5%;
}

.trReportContent-children {
    padding: 0.5em;
}

.trReportContent-childrenRow {
    display: flex;
    justify-content: space-between;
}

.trReportContent-previewImage {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
}

.trReportContent--allocation .trReportContent-childrenRow {
    justify-content: space-around;
}

.trReportContent-child {
}

/* --------------------------------------------------------------- Page break */
.tFieldsetDynamic .tReportPageBreak {
    background-color: transparent;
    margin-bottom: 0.3em;
}

.tFieldsetDynamic .tReportPageBreak .tFieldDynamic-actions {
    background-color: #fff;
    border-radius: 3px;
    top: -2px;
}

.tFieldsetDynamic .tReportPageBreak .tFieldDynamic-layoutWithSetting {
    display: block;
    position: relative;
}

.tFieldsetDynamic .tReportPageBreak .tFieldDynamic-setting {
    display: none;
}

.tFieldsetDynamic .tReportPageBreak .tFieldDynamic-content {
    width: 100%;
}
/* --------------------------------------------------------------- Disclaimer */
.trReportContent-disclaimer {
    border: 1px solid rgba(211, 211, 211, 1);
    color: rgba(0, 0, 0, 1);
    min-height: 1.25em;
    padding: 0 0.25em;
}

.trReportSection--disabled .trReportContent-disclaimer {
    background-color: rgba(211, 211, 211, 1);
    color: rgba(136, 136, 136, 1);
    filter: grayscale(100%);
}

.trReportContent-disclaimerLabel {
}
/* ------------------------------------------------------------------- Images */
.trReportContent--peerMR {
    background-image: url("../images/momentumBreakdown.png");
    width: 100%;
}

.trReportContent--table {
    background-image: url("../images/holdings_table.png");
    width: 100%;
}

.trReportContent--title {
    background-image: url("../images/title.png");
    width: 100%;
}

.trReportContent--allocation {
    background-image: url("../images/field_allocation.png");
    width: 100%;
}

.trReportContent--asOfTodayPerformance {
    background-image: url("../images/as_of_today_performance.png");
    width: 100%;
}

.trReportContent--breakdown {
    background-image: url("../images/field_breakdown.png");
    width: 100%;
}

.trReportContent--dispersionByChartBasket {
    background-image: url("../images/field_dispersion_chart.png");
    width: 100%;
}

.trReportContent--dispersionBasketBy {
    background-image: url("../images/field_dispersion_chart.png");
    width: 100%;
}

.trReportContent--chart {
    background-image: url("../images/holdings_strategy_chart.png");
    width: 100%;
}

.trReportContent--cover {
    background-image: url("../images/cover.png");
    width: 100%;
}

.trReportContent--disclaimer {
    /*background-image: url("../images/field_disclaimer.png");*/
    width: 100%;
}

.trReportContent--facts {
    background-image: url("../images/facts.png");
    width: 100%;
}

.trReportContent--header1 {
    background-image: url("../images/field_header1.png");
    width: 100%;
}

.trReportContent--holdingChart {
    background-image: url("../images/holdings_chart.png");
    width: 100%;
}

.trReportContent--holdings {
    background-image: url("../images/holdings.png");
    width: 100%;
}

.trReportContent--holdingTable {
    background-image: url("../images/holdings_table.png");
    width: 100%;
}

.trReportContent--keyFacts {
    background-image: url("../images/key_facts.png");
    width: 100%;
}

.trReportContent--momentumBreakdown {
    background-image: url("../images/field_momentumBreakdown.png");
    width: 100%;
}

.trReportContent--monthlyAnalytics {
    background-image: url("../images/monthly_analytics.png");
    width: 100%;
}

.trReportContent--quarterlyAnalytics {
    background-image: url("../images/quarterly_analytics.png");
    width: 100%;
}

.trReportContent--newHighLow {
    background-image: url("../images/field_new_high_new_low.png");
    width: 100%;
}

.trReportContent--pageBreak {
    background-image: url("../images/page_break.png");
    width: 100%;
}

.trReportContent--paragraph {
    background-image: url("../images/field_paragraph.png");
    width: 100%;
}

.trReportContent--peerAbChanges {
    background-image: url("../images/peerAbChanges.png");
    width: 100%;
}

.trReportContent--peerDispersion {
    background-image: url("../images/peerPerformanceDispersion.png");
    width: 100%;
}

.trReportContent--peerDispersionChildren {
    background-image: url("../images/peerPerformanceDispersionChildren.png");
    width: 100%;
}

.trReportContent--peerDispersionByChart {
    background-image: url("../images/peerDispersionByChart.png");
    width: 100%;
}

.trReportContent--peerDispersionBySectors {
    background-image: url("../images/peerDispersionBySectors.png");
    width: 100%;
}

.trReportContent--peerDispersionTcrTable {
    background-image: url("../images/peerDispersionTcrTable.png");
    width: 100%;
}

.trReportContent--peerDispersionRatioTable {
    background-image: url("../images/peerDispersionRatioTable.png");
    width: 100%;
}

.trReportContent--peerMarketOverview {
    background-image: url("../images/peerOverview.png");
    width: 100%;
}

.trReportContent--peerSectorOverview {
    background-image: url("../images/peerOverview.png");
    width: 100%;
}

.trReportContent--peerTcr {
    background-image: url("../images/peerOverview.png");
    width: 100%;
}

.trReportContent--peerTcrChanges {
    background-image: url("../images/peerTcrChanges.png");
    width: 100%;
}

.trReportContent--peerUpgradesDowngrades {
    background-image: url("../images/peerUpgradesDowngrades.png");
    width: 100%;
}

.trReportContent--performer {
    background-image: url("../images/field_performer_positive.png");
    width: 100%;
}

.trReportContent--ratingChange {
    background-image: url("../images/field_rating_change.png");
    width: 100%;
}

.trReportContent--spacing {
    background-image: url("../images/vertical_spacing.png");
    width: 100%;
}

.trReportContent--summary {
    background-image: url("../images/field_summary.png");
    width: 100%;
}

.trReportContent--tcr {
    background-image: url("../images/field_tcr.png");
    width: 100%;
}

.trReportContent--yearlyAnalytics {
    background-image: url("../images/yearly_analytics.png");
    width: 100%;
}

.trReportContent--yearToDateAnalytics {
    background-image: url("../images/year_to_date_analytics.png");
    width: 100%;
}

.trReportSection--disabled .trReportContent {
    background-image: url("../images/isDisabled.png");
    background-repeat: repeat;
    background-size: 5px 5px;
}

/* Special style for report designer */
.tFieldsetDynamic .tFieldDynamic-layoutWithSetting .tFieldDynamic-actionsWrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 20px;
    bottom: 0;
    width: 100%;
    padding-bottom: 20px;
    height: calc(100% - 40px);
    background-color: rgba(255, 255, 255, 0.6);
    text-align: center;

    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out 0.2s;
}

.dialogReport-wrap
    .dialogReport-right
    .tReportDesigner.dialogReport-designer
    .tFieldDynamic {
    transition: all 0.2s ease-in-out 0.2s;
}

.tFieldsetDynamic .tFieldDynamic-layoutWithSetting .tFieldDynamic-actions {
    display: flex;
    position: relative;

    /* Reset formDynamic styling */
    width: auto;
    left: 50%;
    top: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    justify-content: center;

    /*transition: all 0.2s ease-in-out 0.5s;*/
}

.tFieldsetDynamic-field .tFieldDynamic-layoutWithSetting .tFieldDynamic-action {
    position: relative;
    flex: 0 0 auto;
    display: block;
    width: 40px;
    height: 40px;
    border: 2px solid #2a7092; /* TODO not working */
    border-radius: 50%;
    margin-left: 4px;
    margin-right: 4px;

    opacity: 1;
}

.tFieldsetDynamic-field:hover
    .tFieldDynamic-layoutWithSetting
    .tFieldDynamic-action {
    position: relative;
    opacity: 1;
    transition: all 0s ease-in-out 0s;
}

.tFieldsetDynamic
    .tFieldDynamic-layoutWithSetting
    .tFieldDynamic-actions
    button
    span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 2em;
}

/* Custom status sections blocks */

.isCustomizing .dialogReport-wrap .dialogReport-left .dialogReport-headerTitle {
    display: none;
}

.isNotCustomizing
    .tFieldReportCommonPageBreak
    .tFieldDynamic-layoutWithSetting {
    display: none;
}

.isCustomizing
    .dialogReport-wrap
    .dialogReport-right
    .tReportDesigner.dialogReport-designer
    .tFieldsetDynamic-field:hover {
    border-color: #2a7092;
    color: #2a7092;
}

.isNotCustomizing .tFieldDynamic-layoutWithSetting {
    min-height: auto;
}

.isNotCustomizing .tFieldReportCommonPageBreak {
    display: block;
    background: transparent;
    padding: 6px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    box-shadow: none;
}

.isNotCustomizing .tFieldReportCommonSpacing .tFieldDynamic-header {
    visibility: hidden;
}

.isNotCustomizing .tFieldReportCommonSpacing .tFieldDynamic-content {
    display: none;
}

.isNotCustomizing .tFieldReportCommonSpacing .tFieldDynamic-layout {
    height: 20px;
    background-image: linear-gradient(
        45deg,
        #ffffff 37.5%,
        #f5f5f5 37.5%,
        #f5f5f5 50%,
        #ffffff 50%,
        #ffffff 87.5%,
        #f5f5f5 87.5%,
        #f5f5f5 100%
    );
    background-size: 8px 8px;
}

.tFieldsetDynamic-field:hover
    .tFieldDynamic-layoutWithSetting
    .tFieldDynamic-actionsWrap {
    opacity: 1;
    pointer-events: all;
}
