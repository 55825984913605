@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 08 Feb 2016
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Collection benchmark widget
 */
.collection-benchmark {
   background-color: #fff;
   border: 0;
   clear: both;
   min-height: 16.6em;
   margin: 0;
   padding: 0;
   position: relative;
}

.collection-benchmark__benchmark,
.collection-benchmark__collection {
   background-color: #f2f2f2;
   overflow: hidden;
   padding: 2%;
   text-overflow: ellipsis;
   white-space: nowrap;
}

.collection-benchmark__benchmark {
   border-top: 1px solid #d3d3d3;
   bottom: 0;
   position: absolute;
   width: 96%;
}

.collection-benchmark__collection {
   border-bottom: 1px solid #d3d3d3;
}

.collection-benchmark__chart {
   padding-top: 3em;
}

.collection-benchmark-rate {
   font-weight: 600;
   line-height: 1em;
}

.collection-benchmark__benchmark-rate .collection-benchmark-rate {
   font-size: 150%;
}

.collection-benchmark__collection-rate .collection-benchmark-rate {
   font-size: 200%;
}

.collection-benchmark-tick {
   color: #2a7092;
   font-size: 200%;
   line-height: .1em;
   height: 12px !important;
   margin: 0;
   padding: 0;
   text-align: center;
   width: 10%;
}

.collection-benchmark__chart-scale {
   background-image: linear-gradient(to right, #F00000 0%, #F48400 35%, #8BBC00 70%, #008000 100%);
   border: 1px solid #d3d3d3;
   color: #fff;
   height: 24px;
   margin: 0 auto;
   overflow: hidden;
   position: relative;
   width: 96%;
}
/* FIX for IE9 */
.dj_ie9 .collection-benchmark__chart-scale {
   /* background-image: url("img/benchmark.png"); */
}

.collection-benchmark__benchmark-rate,
.collection-benchmark__collection-rate {
   border-collapse: collapse;
   width: 100%;
}