.main {
  display: flex;
  min-height: 0;
  height: 100%;
  width: 100%;
  padding: 8px;

  &__card {
    width: 100%;
    height: 100%;

    &__content {
      display: flex;
      height: 100%;
      flex: 1;
      gap: 8px;
      min-height: 0;

      &__listPanel {
        display: flex;
        flex: 1;
        min-height: 0;
      }

      &__strategyPanel {
        flex: 2;

        &__content {
          height: 100%;
          display: flex;
          flex-direction: column;
          min-height: 0;
        }
      }
    }
  }
}

.strategySelector__main {
  display: flex;
  flex: 1;
  // padding: 8px;
  gap: 8px;
  min-height: 0;

  &__leftPanel,
  &__rightPanel {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-height: 0;
  }

  &__leftPanel {
    flex: 1;
    padding-right: 20px;
    border-right: 1px solid #d3d3d3;

    &__list {
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow: auto;

      li {
        cursor: pointer;
        color: black;
        transition: all 0.5s;

        &:hover {
          color: #2a7090;
        }
      }
    }
  }

  &__rightPanel {
    flex: 3;

    .insightsTableSelector {
      border-spacing: 0 10px;
      width: 100%;

      tr {
        td {
          border-bottom: 1px solid #d3d3d3;
          padding-bottom: 10px;

          ul {
            li {
              cursor: pointer;
              color: black;
              transition: all 0.5s;
              padding-top: 3px;

              &:hover {
                color: #2a7090;
              }
            }
          }
        }
      }
    }
  }
}

.customSelect {
  padding: 0 !important;
}

.results__main {
  display: flex;
  padding: 8px 0;
  flex: 1;
  height: 100%;
  width: 100%;
  min-height: 0;
}

.iconButtonWidget {
  background-color: white;
  border: 1px solid #2a7090;
  border-radius: 4px;
  padding: 3px 5px;
  color: #2a7090;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #2a7090;
    color: white;
  }

  &__active {
    background-color: #2a7090;
    color: white;
  }
}
