.mainWrapper {
  display: flex;
  flex: 1;
  min-height: 0;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  position: relative;
  padding: 8px;

  .section {
    display: flex;
    flex-direction: column;
    min-height: 0;

    .sectionTitle {
      text-transform: uppercase;
      color: #2a7090;
    }
  }

  .dialogButtons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}

.rankModalBody {
  display: flex;
  flex: 1;
  min-height: 0;
  height: 100%;
  padding: 0 !important;
}
