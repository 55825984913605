@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 17 Jul 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Menu
 */
.menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu__separator {
    border-top: 1px solid #d3d3d3;
}

.menu__item {
    padding: 0.5em 1em;
    text-decoration: none;
}

.menu__item:hover,
.menu__item-isHighlighted {
    background-color: #2a7092;
    color: #fff;
    text-decoration: none;
}

.menu--horizontal {
    text-align: center;
}

.menu--horizontal li {
    display: inline-block;
}

.menu--actions {
    background-color: #000;
    color: #fff;
}

.menu__item--selected {
    font-weight: 600;
}

.menu__item--delete:hover {
    background-color: #f00000;
}
/* ---------------------------------------------------------------- Main menu */
.menu--main {
}

.menu--main .menu__item {
    margin: 0;
    padding: 0;
}

.menu--main .menu__item:hover {
    background-color: transparent;
}

.menu--main .menu__item {
    color: #fff;
    display: block;
    margin: 0.5em 0.75em;
    padding: 0.15em 0.25em;
    text-transform: uppercase;
}

@media screen and (max-width: 1279px) {
    .menu--main .menu__item {
        margin: 4px;
        padding: 2px;
    }
}

.menu--main .menu__item:hover {
    background-color: #ffc001;
    color: #000;
    text-decoration: none;
}

.menu--main .menu__item--selected,
.menu--main .menu__item--selected:hover {
    background-color: #ffc001;
    color: #000;
    margin-bottom: 0;
    padding-bottom: 0.75em;
}
/* ----------------------------------------------------------------- Workflow */
.menu--workflow {
    font-size: 125%;
}

.menu--workflow .menu__item {
    font-weight: normal;
    padding-left: 1.8em;
    padding-right: 1.8em;
}

.menu--workflow .menu__item,
.menu--workflow .menu__item .menu__link {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

.app__customer-care .menu__item {
    padding-left: 1em;
    padding-right: 1em;
}

.menu--interstitial {
    border-bottom: 3px solid #ffc001;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
}

/* -------------------------------------------------------------- Preferences */
.menu--preference {
}

.menu--preference .menu__item {
    border-bottom: 1px solid #d3d3d3;
}

.menu--preference .menu__item-label {
}

.menu--preference .menu__item-action {
    color: #2a7092;
    float: right;
}

.menu--preference .menu__item:hover .menu__item-action {
    color: #fff;
}

.menu--preference .system-info {
    background-color: #000;
    color: #fff;
    font-size: 80%;
    font-weight: normal;
    padding: 0.5em;
}
/* -------------------------------------------------------------- Save dialog */
.menu--dialog .menu__item:hover {
    background-color: transparent;
    color: #000;
}

/* -------------------------------------------------------------- New Save dialog */
.primary {
    color: white;
    background-color: #2a7092;
}

.save__dialog {
    min-width: 35%;
}

.save__dialog__wrapper > .form__field-feedback {
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
}

.dialog__save__input__tooltip {
    padding: 5px;
}

.save__dialog > .dijitDialogPaneContent {
    padding: 0 !important;
}

.save__dialog > .dijitDialogTitleBar {
    padding: 10px 15px;
}

.save__dialog__wrapper {
    width: 100% !important;
}

.save__dialog__wrapper > .item-disabled {
    opacity: 0.1;
    pointer-events: none;
}

.save__dialog__wrapper > .menu__item {
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.save__dialog__wrapper > .menu__item > .hide {
    -webkit-animation: fadeout 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeout 1s; /* Firefox < 16 */
    -ms-animation: fadeout 1s; /* Internet Explorer */
    -o-animation: fadeout 1s; /* Opera < 12.1 */
    animation: fadeout 1s;
}

@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.save__dialog__wrapper > .menu__item > .menu__item__input__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    align-items: flex-start;
    width: 100%;

    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.save__dialog__wrapper
    > .menu__item
    > .menu__item__input__wrapper
    > .form__field-text {
    margin: 0 0 10px 0;
}

.save__dialog__wrapper > .menu__item > .menu__item__item-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.save__dialog__wrapper > .menu__item > .menu__item__item-wrapper > button {
    min-width: 150px;
    padding: 10px 0;
}

.save__dialog__wrapper > .menu__item > .menu__item__item-wrapper > p {
    font-size: 16px;
    margin-left: 20px;
}

.save__dialog__wrapper
    > .menu__item
    > .menu__item__input__wrapper
    > .button-bar
    > button {
    min-width: 80px;
    padding: 5px 0;
    margin-right: 10px;
}

.save__dialog__wrapper > .menu__item > .menu__item__input__wrapper > p {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}

.shorter__separator {
    margin: 0 30px;
}

.banner__action-close {
    width: 100%;
    padding: 6px;
    background-color: #2a7092;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    min-height: 30px;
}

.banner__action-close > button {
    min-width: 60px;
}

/* -------------------------------------------------------------- Fast access */
.menu--fast-access {
    background-color: #000;
    color: #ffc001;
}

.claro .fast-access__item td.dijitMenuItemIconCell,
.claro .fast-access__item td.dijitMenuItemLabel,
.claro .fast-access__item .dijitMenuArrowCell {
    margin: 0;
    padding: 0;
}

.claro .fast-access__item .dijitMenuArrowCell {
    display: none;
}

.menu--fast-access .menu__item {
    font-weight: normal;
}

.menu--fast-access .menu__item:hover {
    background-color: #ffc001;
    color: #000;
}
