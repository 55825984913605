@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 16 Oct 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Simulate a radio button bar
 */
.radio-bar {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
}

.radio-bar.isVertical {
    display: block;
}

.radio-bar__option {
    cursor: pointer;
    display: inline-block;
    padding: 0 8px 0 8px;
    margin-right: 0.75em;
}

.radio-bar.isVertical .radio-bar__option {
    display: block;
    margin: 0;
    padding: 4px 2px;
}

.radio-bar__option:last-child {
    margin-right: 0;
}

.radio-bar__label {
    border-bottom: 2px solid transparent;
    cursor: pointer;
    display: inline-block;
    letter-spacing: -0.5px;
    padding: 6px 0 6px 0;
}

.radio-bar__option--selected .radio-bar__label {
    border-bottom-color: #2a7092;
    color: #2a7092;
    cursor: default;
}

.radio-bar__option:hover {
    background-color: #ddd;
}

.radio-bar__option--selected:hover {
    background-color: transparent;
    cursor: default;
}

.radio-bar--alerts {
    font-size: 150%;
    margin-left: 1em;
}

@media screen and (min-width: 1025px) {
    .analysis-peer-map__header .switcher .radio-bar__option {
        margin-right: 2.5em;
    }
}
