@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 23 May 2017
 * Project: Trendrating Web Application
 *
 * Table Performance Widget (Security Analyisis)
 */
.tablePerformance {
   margin: .25em 0;
   padding: 0 .25em;
}

.tablePerformance-table {
   border-collapse: collapse;
   margin: 0;
   padding: 0;
   width: 100%;
}

.tablePerformance-rate {
   display: inline-block;
   font-size: 200%;
   line-height: 1em;
   margin-right: .15em;
}

.tablePerformance-tableTd {
   border-bottom: 1px solid rgba(239, 239, 239, 1);
   padding: .25em 0;
   vertical-align: bottom;
}

.tablePerformance-tableTd--right {
   text-align: right;
}

.tablePerformance-tableTd--disabled {
   border-color: rgba(255, 255, 255, 1);
}

.tablePerformance-tableDataPercentage {
   font-weight: 600;
   text-align: right;
   width: 4.45em;
}

.tablePerformance tr:last-child .tablePerformance-tableTd {
   border-bottom: 0;
}