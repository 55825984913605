.headerWrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
}
.firstRow {
  background: white;
  display: flex;
  padding-bottom: 8px;
}
.secondRow {
  display: flex;
  border: 1px solid #ddd;
  border-top: none;
}
.outlined__box {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 2px 5px;
}
.outlined__box__label {
  color: #2a7090;
  margin-left: 5px;
}
.outlined__box__content {
  display: flex;
  flex: 1;
  padding: 2px 10px;
  border-radius: 0px;
  background-color: white;
  transition: all 0.3s;

  &:hover {
    background-color: #2a7090;
    border-radius: 4px;
    visibility: visible !important;

    p {
      color: white;
      opacity: 1 !important;
    }
  }
}
.title__label {
  font-size: 1.6vw !important;
  font-weight: normal;
  color: black;
  cursor: pointer;

  &:hover {
    color: white;
    visibility: visible !important;
  }
}
.listItems {
  padding: 2px 5px;
  border: 1px solid #ddd;
  cursor: pointer;
  background-color: white;
  transition: 0.5s;
  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:hover {
    background-color: #2a7090;
    color: white;
  }
}
.listItemsWithRange {
  &:last-child {
    border-radius: 0;
  }
}

.openSliderButton {
  background: white;
  padding: 2px 5px;
  border: 1px solid #ddd;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin: 0;
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.active {
  color: white;
  background-color: #2a7090;
}
.inputSlider {
  padding: 2px 5px !important;
  font-size: 0.8vw !important;
  text-align: right;
}
