.list_wrapper {
  display: flex;
  min-width: 0;

  .list {
    display: flex;
    padding: 8px;
    gap: 8px;
    min-width: 0;
    overflow: auto;
  }
}
