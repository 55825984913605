@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 17 Jul 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Tooltip for collection
 */
.collection-tooltip {
}

.collection-tooltip__content {
   margin: .25em .5em;
}

.collection-tooltip__name {
}

.collection-tooltip__name,
.collection-tooltip__benchmark {
   margin: 0;
   padding: 0;
}

.collection-tooltip__section {
   border-bottom: 1px solid #2a7092;
   font-size: 1em;
   font-weight: 600;
   margin-top: .5em;
   width: 90%;
}

.collection-tooltip__rate {
   margin: .25em 0;
}

.collection-tooltip__alert {
   cursor: pointer;
}

.collection-tooltip__alert:hover {
   color: #2a7092;
}