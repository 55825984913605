@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 19 May 2017
 * Project: Trendrating Web Application
 *
 * Security History Comparation widget
 */
.securityHistoryCompare {
   height: 65vh;
}

.securityHistoryCompare-dataViz {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   width: 100%;
}

.securityHistoryCompare-dataVizPane {
   background-color: rgba(255, 255, 255, 1);
   border: 1px solid rgba(211, 211, 211, 1);
   /*height: 62vh;*/
   margin: 0;
   overflow: hidden;
   padding: 0 .25%;
   width: 23.5%;
}

.securityHistoryCompare-collapserButton {
   cursor: pointer;
   text-align: right;
}