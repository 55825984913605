@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 26 May 2017
 * Project: Trendrating Web Application
 *
 * Dialog compare to widget
 */
.dialogCompare {}

.dialogCompare-feedback {
   margin-bottom: .5em;
   text-align: center;
}

.dialogCompare-content {
   display: flex;
   justify-content: space-between;
}

.dialogCompare-buttons {
   text-align: right;
}

.dialogCompare-search {
   height: 33em;
   width: 74%;
}

.dialogCompare-compareList {
   height: 33em;
   width: 24%;
}