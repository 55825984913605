.button__list {
    display: flex;
    .button {
        font-size: 12px;
        color: #2a7090;
        background-color: white;
        border: 1px solid #dddd;
        padding: 4px 10px;

        margin-right: 10px;
        cursor: pointer;

        div {
            margin-right: 5px;
        }

        &:hover {
            color: white;
            background-color: #2a7090;
        }

        &-selected {
            color: white;
            background-color: #2a7090;
            border: 1px solid #2a7090;
        }
    }
}
