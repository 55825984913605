@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 08 Feb 2016
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Collection distribution rate widget: shows a preview of securities ordered by
 * performance 5 for basic visualization, 20 on overlay
 */

.collection-distribution {}

.collection-distribution:focus {
   outline: 0;
}

/*.collection-distribution .list,
.collection-distribution__overlay .list {
   list-style: none;
   margin-top: .15em;
   padding: 0;
}*/

.collection-distribution .list {
   width: 100%;
}

.collection-distribution .list .bar-container,
.collection-distribution .list .bar-container .bar-percentage,
/*.collection-distribution .list .ticker,*/
.collection-distribution__overlay .bar-container,
.collection-distribution__overlay .bar-container .bar-percentage/*,
.collection-distribution__overlay .ticker*/ {
   /*display: inline-block;*/
   width: 5em;
}

.collection-distribution .list .bar-container .bar-percentage,
.collection-distribution__overlay .bar-container .bar-percentage {
   display: inline-block;
}

.collection-distribution .list .more {
   line-height: .5em;
   margin-bottom: .5em;
   margin-top: .5em;
   outline: none;
}

.collection-distribution .list .more:hover {
   cursor: pointer;
}

.collection-distribution .list .ticker,
.collection-distribution__overlay .ticker {
   position: relative;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   /*width: 6em;*/
}

.collection-distribution .list .bar-container,
.collection-distribution__overlay .bar-container {
   background-image: linear-gradient(to right, #d3d3d3 1px, #d3d3d3 1px);
   background-position: 50% 0;
   background-repeat: repeat-y;
   background-size: 1px 1px;
   line-height: 1em;
   width: 6em;
}

.collection-distribution .list .bar-container,
.collection-distribution__overlay .bar-container {
   padding: .5em 0;
}

.collection__security-performance {
   /*display: inline-block;*/
   text-align: right;
   width: 4.5em;
}

.collection-distribution .list .bar-container .bar-percentage,
.collection-distribution__overlay .bar-container .bar-percentage {
   background-color: transparent;
   height: 1em;
   vertical-align: middle;
}

.collection-distribution .list .bar-container .positive,
.collection-distribution__overlay .bar-container .positive {
   background-color: #008000;
}

.collection-distribution .list .bar-container .negative,
.collection-distribution__overlay .bar-container .negative  {
   background-color: #f00000;
}

.collection-distribution .list .bar-container .outlier-p,
.collection-distribution__overlay .bar-container .outlier-p {
   background-image: linear-gradient(rgba(255, 255, 255, .5) 2px, rgba(255, 255, 255, .5) 2px);
   background-position: 100% 0;
   background-repeat: no-repeat;
   background-size: 2px 100%;
}

.collection-distribution .list .bar-container .outlier-n,
.collection-distribution__overlay .bar-container .outlier-n {
   background-image: linear-gradient(rgba(255, 255, 255, .5) 2px, rgba(255, 255, 255, .5) 2px);
   background-position: 0 0;
   background-repeat: no-repeat;
   background-size: 2px 100%;
}

.collection-distribution .list .bar-container .benchmark,
.collection-distribution__overlay .bar-container .benchmark {
   background-color: #2a7092;
}

.collection-distribution__overlay .bar-container .benchmark {
   height: .7em;
}

.collection-distribution__overlay {
   background-color: #fff;
   box-shadow: 2px 2px 2px 2px rgba(232, 232, 232, .75);
   color: #333;
   left: 0;
   max-height: 43em;
   outline: 1px solid #d3d3d3;
   overflow: auto;
   padding: .5em;
   position: absolute;
   top: 0;
   z-index: 900;
}

.collection-distribution__overlay .list {
   margin: 0;
}

.collection-distribution__overlay .benchmark {
   padding-bottom: .25em;
}