.buttonGroup {
  display: flex;
  align-items: center;
  gap: 8px;

  &__withBorder {
    padding-left: 30px;
    border-left: 1px solid #ddd;
  }

  &__btn {
    border: 2px solid #ddd;
    color: #808080;
    background-color: white;
    border-radius: 6px;
    cursor: pointer;
    padding: 0.5em 2em;
    font-size: 0.7vw;
    text-transform: uppercase;
    transition: all 0.5s;

    &__active,
    &:hover {
      color: #2a7090;
      border-color: #2a7090;
    }

    &__mt {
      margin-top: 8px;
    }
  }
}
