.option__list {
    display: flex;
    overflow-y: auto;
    max-height: 300px;
    flex-direction: column;
    padding: 0 5px 20px 5px;

    li {
        padding: 10px 0;
        cursor: pointer;

        &:hover {
            color: #2a7090;
            text-decoration: underline;
        }
    }
}

.drawer {
    position: absolute;
    background-color: white;
    border-left: 1px solid #2a7090;
    border-bottom: 1px solid #2a7090;
    top: 0;
    padding: 10px 30px;
    height: 50vh;
    z-index: 999999999;
    transform: translateX(100%);
    right: 0px;
    transition: transform 0.3s;

    &--open {
        transform: translateX(0%);
    }
}

.widget {
    display: flex;
    justify-content: flex-end;
}

.button-back__navigator {
    font-size: 12px;
    background-color: white;
    border: 1px solid #dddd;
    padding: 4px 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;

    div {
        margin-right: 5px;
    }

    &:hover {
        color: #2a7090;
        border: 1px solid #2a7090;
    }

    &-selected {
        color: white;
        background-color: #2a7090;
        border: 1px solid #2a7090;
    }
}
