.list {
  display: flex;
  gap: 5px;
  flex: 1;
  min-height: 0;
  overflow: auto;
  flex-direction: column;
}
.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding: 4px;
}
