.editorList {
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 100%;
  flex: 1;
  min-height: 0;
  overflow: auto;
  padding: 8px;

  .editorListRowWrap {
    border-radius: 4px;
    border: 1px solid transparent;

    .editorListRow {
      padding: 4px 8px;
      display: flex;
      gap: 10px;
      font-size: 0.8vw;
      cursor: pointer;

      .editorListRowCellWrapper {
        flex: 1;
        display: flex;
        flex-direction: column;

        .editorListRowCell {
          display: flex;
          gap: 8px;
          align-items: center;
        }

        li {
          &:hover {
            color: #2a7090;
          }
        }

        .trashIconBtn {
          visibility: hidden;
          padding: 5px;
          border-radius: 4px;
          background-color: transparent;
          cursor: pointer;

          &:hover {
            color: white;
            background-color: #2a7090;
          }
        }
      }

      .editorListRowCellWrapperProperty {
        flex: 8;
      }
    }

    &:hover {
      background-color: white;
      border: 1px solid #2a7090;

      .editorListRow {
        .editorListRowCellWrapper {
          .trashIconBtn {
            visibility: visible;
          }
        }
      }
    }
  }

  .activeRow {
    background-color: white;
    border: 1px solid #2a7090;
    z-index: 6;

    .editorListRow {
      .editorListRowCellWrapper {
        .trashIconBtn {
          visibility: visible;
        }
      }
    }
  }

  .editorFooter {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding: 8px;
  }
}

.inputNumber {
  padding: 2px 5px !important;
  font-size: 0.8vw !important;
  text-align: right;
}

.popover-list-item {
  cursor: pointer;
  color: black;
  margin-left: 10px;
  transition: 0.5s;

  &:hover {
    color: #2a7090;
  }
}

.blurModal {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  display: none;
  background-color: rgba($color: black, $alpha: 0.5);
  z-index: -1;
  backdrop-filter: blur(3px);
  transition: all 0.5s;

  &_active {
    opacity: 1;
    display: block;
    z-index: 5;
  }
}
