@charset "utf-8";
/*
* Author      : Trendrating <info@trendrating.net>
* Created     : 05 Dec 2017
*
* Style guide : MaintainableCSS (http://maintainablecss.com)
*
* Screening page
*/
.screening {
    position: relative;
}

.screening-dataLoader {
    background-color: rgba(242, 242, 242, 0.85);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 800;
}

.screening-dataLoaderMessage {
    margin-top: 42.5vh;
    text-align: center;
}

.tableColumnConfigurator {
    display: inline;
}

.tRankingDialog {
    font-size: 120%;
    min-width: 40em;
}

.tRankingDialog[disabled] {
    cursor: default;
    pointer-events: none;
}

.tRankingDialog[disabled] .tRanking-buttonAdd {
    background-color: #F2F2F2;
    color: #666;
}

.tRankingDialog[disabled] .tRanking-buttonAdd:focus {
    outline: none;
}

.tRankingDialog[disabled] .tRankingRule {
    background-color: #F2F2F2;
}

.tRankingDialog[disabled] .tRankingRule .tFormSelect {
    background-color: #F2F2F2;
    color: #666;
}

/* .tRankingDialog-panel-isHidden {
    display: none;
} */

.tRankingDialog-highlight {
    margin: 0.75em 0 0 0;
}

.tRankingDialog-buttons {
    margin-top: 0.5em;
    text-align: right;
}
/* ------------------------------------------- TO BE MOVED IN A INDEPENDENT FILE 
 *                                             AND UNIFIED AMONG PAGES
 * -----------------------------------------------------------------------------
*/
.dgrid .ranking {
    background-color: rgba(42, 112, 146, 0.1);
}

.dgrid .dgrid-content .ranking {
    /*  padding-right: 3vw; */
    /* text-align: right; */
}

.dgrid .ranking--highlight {
    background-color: rgba(42, 112, 146, 0.5);
    font-weight: 600;
    /* text-transform: uppercase; */
}

.dgrid .ranking-separator {
    border-left: 2px solid rgb(42, 112, 146);
}

.screening .grid .ranking {
    background-color: rgba(42, 112, 146, 0.1);
}

.screening .grid .dgrid-content .ranking {
    /* padding-right: 3vw; */
    /* text-align: right; */
}

.screening .grid .ranking--highlight {
    background-color: rgba(42, 112, 146, 0.5);
    font-weight: 600;
    /* text-transform: uppercase; */
}

.screening .grid .ranking-separator {
    border-left: 2px solid rgb(42, 112, 146);
}

.dgrid .dgrid-content .ranking-outlier {
    text-align: center;
}

.dgrid .dgrid-content .ranking-quatile {
    padding-right: 3vw;
}

.dgrid .dgrid-content .ranking-rank {
    padding-right: 3vw;
}

.dgrid .dgrid-content .ranking-rankDiff {
    padding-right: 2vw;
}

.dgrid .dgrid-content .ranking-rankFromDate {
    padding-right: 3vw;
}

.dgrid .dgrid-content .ranking-threshold {
    text-align: center;
}

.dgrid .dgrid-content .ranking-value {
}
