.list {
  display: flex;
  gap: 24px;
}
.item {
  padding: 6px 0 6px 0;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}
.active {
  color: #2a7090;
  border-bottom-color: #2a7090;
}
