.context__menu {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #2a7092;
    height: 200px;
    overflow: auto;
    position: fixed;
    z-index: 9999999;

    li {
        cursor: pointer;
        padding: 10px;

        &:hover {
            background-color: #2a7092;
            color: white;
        }
    }
}
