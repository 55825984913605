.openDialogButton {
  background-color: white;
  border: 1px solid #2a7090;
  border-radius: 4px;
  padding: 3px 5px;
  color: #2a7090;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #2a7090;
    color: white;
  }
}

.modalContentBox {
  display: flex;
  flex: 1;
  min-width: 0;
  min-height: 0;
  gap: 8px;

  .checkboxesCol {
    flex: 3;
  }

  .templateCol {
    flex: 1;

    .templateNamesList {
      display: flex;
      flex-direction: column;

      .templateNamesListItem {
        cursor: pointer;
        background-color: transparent;
        color: black;
        transition: 0.5s;
        padding: 8px;
        display: flex;
        justify-content: space-between;

        &:hover {
          background-color: #2a7090;
          color: white;
        }

        .templateNamesListItemActions {
          align-self: flex-end;
          display: none;
          gap: 5px;

          span {
            padding: 3px;
          }
        }
      }

      .activeTemplateItem {
        background-color: #2a7090;
        color: white;

        .templateNamesListItemActions {
          display: flex;

          span {
            color: white;
            border-radius: 4px;
            border: 1px solid white;
          }
        }
      }
    }
  }
}

.editorContentCardContent {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .editorContentBoxHeader {
    background-color: rgba($color: #000000, $alpha: 0.1);
    color: rgba($color: #000000, $alpha: 0.5);
    font-size: 0.7vw;
    padding: 8px;
  }

  .columnsAvailableBox {
    padding: 8px;
    display: flex;
    justify-content: space-between;

    .checkboxGroupWrapper {
      display: flex;
      flex-direction: column;

      .checkboxGroup {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
