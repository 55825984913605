.modal__main {
  flex: 1;
  display: flex;
  min-height: 0;
  min-width: 0;
  gap: 8px;

  &__left,
  &__right {
    display: flex;
    flex: 1;
    min-height: 0;
    min-width: 0;
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__selector {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__table__container {
      overflow: auto;
    }
  }
}

.modalBody {
  display: flex;
  height: auto;
  width: auto;
  flex: 1;
  min-height: 0;
  flex-direction: column;
}
