@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 13 Jul 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Layout
 */
.layout {
    display: flex;
    margin: 0;
    padding: 0;
    width: 100%;
}

.layout--space-between {
    justify-content: space-between;
}

.layout--title {
    border-bottom: 1px solid #d3d3d3;
    /* margin-bottom: .25em; */
    min-height: 2.25em;
    padding-bottom: 0.25em;
}

.layout__content-pane {
    margin: 0;
    padding: 0;
}

.layout--2-flex-col {
    height: 100%;
    justify-content: space-between;
}

.layout--2-flex-col .layout__content-pane {
    width: 49.5%;
    display: flex;
}

.layout--2-flex-col .layout__content-pane > div {
    width: 100%;
    overflow: auto;
}

.layout--2-col {
    justify-content: space-between;
}

.layout--2-col .layout__content-pane {
    width: 49.5%;
}

.layout--3-col {
    justify-content: space-between;
}

.layout--3-col .layout__content-pane {
    width: 32%;
}

.layout--4-col {
    justify-content: space-between;
}

.layout--4-col .layout__content-pane {
    width: 24.5%;
}
/* ---------------------------------------------------------------------- OLD */
.layout-grid {
    clear: both;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
    width: 100%;
}

.layout-grid__col {
    display: inline-block;
    /*margin-left: -4px;*/ /*Avoid spaces among blocks: http://css-tricks.com/fighting-the-space-between-inline-block-elements */
    text-align: left;
    vertical-align: top;
}

.layout-grid__col--10 {
    width: 10%;
}

.layout-grid__col--15 {
    width: 15%;
}

.layout-grid__col--20 {
    width: 20%;
}

.layout-grid__col--25 {
    width: 25%;
}

.layout-grid__col--30 {
    width: 30%;
}

.layout-grid__col--33 {
    width: 33%;
}

.layout-grid__col--34 {
    width: 34%;
}

.layout-grid__col--34-alert {
    margin-right: 1%;
    width: 33%;
}

.layout-grid__col--35 {
    width: 35%;
}

.layout-grid__col--45 {
    width: 45%;
}

.layout-grid__col--50 {
    width: 50%;
}

.layout-grid__col--60 {
    width: 60%;
}

.layout-grid__col--66 {
    width: 66%;
}

.layout-grid__col--67 {
    width: 67%;
}

.layout-grid__col--55 {
    width: 55%;
}

.layout-grid__col--70 {
    width: 70%;
}

.layout-grid__col--75 {
    width: 75%;
}

.layout-grid__col--85 {
    width: 85%;
}

.layout-grid__col--90 {
    width: 90%;
}

.layout-grid__col--100 {
    width: 100%;
}

.layout-grid__col--inline {
    display: inline-block;
}

.layout-grid__col--collection-list {
    background-color: #fff;
    margin-right: 1%;
    outline: 1px solid #000;
    padding: 1%;
    width: 47%;
}

.layout-grid__col--collection-list:last-child {
    margin-right: 0;
    margin-left: 1%;
}

.layout-grid__col--v-middle {
    vertical-align: middle;
}

.layout-grid__col--optimization {
    padding: 0 1%;
    width: 48%;
}

.layout-grid__col--buy-sell,
.layout-grid__col--contributions {
    width: 49%;
}

.layout-grid__col--buy-sell:last-child,
.layout-grid__col--contributions:last-child {
    margin-left: 2%;
}

.layout-grid__col--75-builder {
    margin-left: 0.5%;
    width: 74.5%;
}

.layout-grid--analysis-collection-analysis {
    background-color: #f2f2f2;
    outline: 1px solid #d3d3d3;
    padding: 1%;
    padding-top: 2%;
    width: 98%;
}

.analysis-security .layout-grid__col--33-analysis-security {
    margin-left: 0.5%;
    margin-right: 0.5%;
}
