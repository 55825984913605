@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 24 May 2017
 * Project: Trendrating Web Application
 *
 * Security History Rate Chart widget
 */
.chartHistoryRate {
   background-color: rgba(255, 255, 255, 1);
   border: 1px solid rgba(211, 211, 211, 1);
   /*height: 62.5vh;*/
   height: 65vh;
   overflow: hidden;
   position: relative;
}

.chartHistoryRate-buttonEnlarge {
   border-bottom: 1px solid rgba(211, 211, 211, 1);
   border-left: 1px solid rgba(211, 211, 211, 1);
   cursor: pointer;
   padding: .25em;
   position: absolute;
   right: 0;
   top: 0;
   z-index: 10;
}
