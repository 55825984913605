.main {
  height: 100%;
  width: 100%;
  display: flex;
  gap: 8px;
  min-height: 0;

  .menu {
    &__list {
      margin-top: 4em;
      display: flex;
      flex-direction: column;
      gap: 1.2em;

      &__item {
        flex: 1;
        border-right: 2px solid transparent;
        cursor: pointer;
        text-transform: uppercase;
        padding: 8px 16px;

        &__active {
          border-right: 2px solid #2a7090;
          color: #2a7090;
        }
      }
    }
  }

  .chart__box {
    display: flex;
    height: 100%;
    width: 100%;

    &__card {
      height: 100%;
      width: 100%;
      display: flex;

      &__content {
        width: 100%;
        height: 100%;
      }
    }
  }
}
