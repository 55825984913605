@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 26 Nov 2013
 * Project: Trendrating Application
 *
 * dgrid styles
 */
.dgrid {
    background-color: #fff;
    border-color: #d3d3d3;
    clear: both;
}

.dgrid .dgrid-header,
.dgrid .dgrid-footer {
    background-color: #fff;
    background-image: none;
}

.dgrid .dgrid-footer {
    border-top: 1px solid #d3d3d3;
    padding: 0.25em;
}

.dgrid-sort-arrow {
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    height: 14px;
    margin: 0 0 0 -2px;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: -2px;
    width: 14px;
}

.dgrid .dgrid-header-scroll {
    background-color: #f1f1f1;
}

.dgrid-spacer-row {
    display: none;
}

.dgrid .dgrid-content {
    height: auto;
}

.dgrid .dgrid-cell {
    text-align: right;
    vertical-align: middle;
}

.claro .dgrid-header .dgrid-cell {
    text-align: left;
}

.dgrid .dgrid-content .dgrid-cell {
    padding-bottom: .5em;
    padding-top: .5em;
}

.dgrid .dgrid-row {
    background: none;
}

.dgrid .dgrid-row:hover {
    background-color: #eee;
}

.dgrid .dgrid-selected {
    background-color: #ddd;
}

.dgrid .dgrid-selected:hover {
    background-color: #eee;
}

/* ------------------------------------------------------- Trendrating fields */
.dgrid .dgrid-header .dgrid-cell,
.dgrid .dgrid-content .field-country,
.dgrid .dgrid-content .field-domicile,
.dgrid .dgrid-content .field-etfclas,
.dgrid .dgrid-content .field-etfgeo,
.dgrid .dgrid-content .field-icb,
.dgrid .dgrid-content .field-industry,
.dgrid .dgrid-content .field-name,
.dgrid .dgrid-content .field-sector,
.dgrid .dgrid-content .field-ticker {
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
}
/* -------------------------------------------------------------------- Width */
.dgrid .selector {
    padding: 0.15em 0em 0.15em 0.1em;
    text-align: center;
    width: 2.5em;
}


.dgrid .field-ticker {
    width: 6em;
}

.dgrid .field-duration,
.dgrid .field-magnitude {
    width: 7em;
}

.dgrid .field-dr {
    width: 6.5em;
}

.dgrid .field-rank {
    width: 7.75em;
}

.dgrid .field-rankDiff {
    width: 6em;
}

.dgrid .field-rankFromDate {
    width: 7em;
}

.dgrid .field-rc {
    width: 5.25em;
}

.dgrid .field-upi {
    width: 4.5em;
}

.dgrid .field-weight {
    width: 4.5em;
}
/* -------------------------------------------------------------------- Align */
.dgrid .dgrid-content .field-country,
.dgrid .dgrid-content .field-domicile,
.dgrid .dgrid-content .field-etfclass,
.dgrid .dgrid-content .field-etfgeo,
.dgrid .dgrid-content .field-icb,
.dgrid .dgrid-content .field-industry,
.dgrid .dgrid-content .field-isin,
.dgrid .dgrid-content .field-name,
.dgrid .dgrid-content .field-sector,
.dgrid .dgrid-content .field-ticker,
.dgrid .dgrid-content .field-S /* first header on analytics grid of strategies */ {
    text-align: left;
}

.dgrid .dgrid-content .field-rate,
.dgrid .dgrid-content .field-rc,
.dgrid .dgrid-content .field-rrr {
    text-align: center;
}

.dgrid .dgrid-content .field-vc {
    text-align: left;
}
