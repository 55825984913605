.custom__cell {
    display: flex;
    flex: 1;

    &__number__column {
        flex: 1;
        text-align: right;
    }
}

.custom__cell__icon {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    &__background {
        &:hover {
            background-color: #2a7090;

            .custom__cell__icon__background__icon {
                color: #fff;
            }
        }

        margin-left: 5px;
        background-color: white;
        border-radius: 5px;
        border: 1px solid #2a7090;
        display: flex;
        padding: 2px;
        align-items: center;
        justify-content: center;

        &__icon {
            color: #2a7090;
            font-size: 14px !important;
        }
    }
}

.up__down__cell {
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}
