.wrapper-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.3);
  z-index: 1000;
}

@mixin main {
  max-width: 700px;
  width: 100%;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999999;
  transform: translate(-50%, -50%);
  max-height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  // border-radius: 10px;
  border: 1px solid rgb(42, 112, 146);

  @media only screen and (max-width: 500px) {
    width: 90%;
  }
}

.main {
  @include main;
}

.main-resizable {
  @include main;

  max-width: 100%;
  width: 40%;
  resize: both;
  overflow: hidden;
}

@mixin modalHeader {
  padding: 10px;
  font-size: 1.091rem;
}
.modalHeader {
  @include modalHeader();
}
.modalHeaderBorder {
  border-bottom: rgba($color: #000000, $alpha: 0.2) solid 1px;
  @include modalHeader();
}

.modalHeaderColored {
  background-color: #2a7091;
  color: white;
  @include modalHeader();
}

.modalHeaderColored {
  background-color: #2a7091;
  color: white;
  @include modalHeader();
}

.body {
  padding: 10px;
  overflow: auto;
  position: relative;
}

.btns {
  border-top: rgba($color: #000000, $alpha: 0.2) solid 1px;
  padding: 10px;
  display: flex;
  width: 100%;

  & button:not(:last-child) {
    margin-right: 10px;
  }

  &__btnBox {
    flex: 1;
    display: flex;

    &__left {
      align-items: center;
      justify-content: flex-start;
    }

    &__center {
      align-items: center;
      justify-content: center;
    }

    &__right {
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.no-padding__body {
  padding: 0px !important;
  overflow: hidden !important;
}

.close__icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
