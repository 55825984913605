@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 18 May 2017
 * Project: Trendrating Web Application
 *
 * Security Peer Tile widget
 */
.tileSecurityPeer {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   width: 100%;
}

.tileSecurityPeer-title {
   margin-top: .5em;
   width: 100%;
}

.tileSecurityPeer-tile {
   background-color: rgba(255, 255, 255, 1);
   display: flex;
   flex-wrap: wrap;
   justify-content: space-around;
   margin-top: 1%;
   outline: 1px solid rgba(211, 211, 211, 1);
   overflow: hidden;
   padding: .5%;
   position: relative;
   width: 48.5%;
}

.tileSecurityPeer-tile--0_25x{
   outline: 0;
   overflow: hidden;
   width: 23%;
}

.tileSecurityPeer-tile--0_5x {
   outline: 0;
   overflow: hidden;
   width: 48%;
}