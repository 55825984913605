@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 19 May 2017
 * Project: Trendrating Web Application
 *
 * Security Historycal Trend Chart widget
 */
.chartTrend {}

.chartTrend-chart {
   height: 12.5em;
}