.reportsHubMain {
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  padding: 8px;
  gap: 8px;
  min-height: 0;

  &__content {
    display: flex;
    height: 100%;
    width: 100%;
    min-height: 0;
    flex-direction: column;
    gap: 8px;

    &__card {
      display: flex;
      min-height: 0;
      height: 100%;

      &__content {
        display: flex;
        min-height: 0;
        height: 100%;
        flex-direction: column;
        gap: 0px;
        overflow: auto;

        &__table {
          table-layout: fixed;
          width: 100%;
          height: 100%;
          border-collapse: separate;
          border-spacing: 0 8px;

          &__row {
            border-radius: 6px;

            &__cell {
              overflow-y: hidden;
              overflow-x: visible;
              padding: 0 10px;
              font-size: 1.8em !important;
              color: #2a7090;
              min-height: 0;

              &__title {
                font-size: 1.8em !important;
                text-transform: uppercase;
                color: #2a7090;
                text-align: center !important;
              }
            }
          }
        }
      }
    }

    &__cards__row {
      height: 50%;
      display: flex;
      gap: 8px;
      flex-direction: row;

      &__card {
        display: flex;
        flex: 1;

        &__content {
          display: flex;
          flex: 1;
          flex-direction: column;
          gap: 8px;
          text-align: center;

          &__title {
            font-size: 1.8em !important;
            text-transform: uppercase;
            color: #2a7090;
          }

          &__list {
            display: flex;
            flex: 1;
            flex-direction: column;
            gap: 8px;
            align-items: center;

            &__item {
              border-radius: 6px;
              cursor: pointer;
              padding: 1% 10%;
              display: flex;
              align-items: center;
              justify-content: center;

              &__content {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 8px;
                min-width: 6vw;
              }
            }
          }
        }
      }
    }
  }

  &__help {
    display: flex;
    flex: 1;
    border-radius: 4px;
    padding: 8px;
    min-height: 0;

    &__text {
      font-size: 1.2vw !important;
      font-weight: bold;
    }
  }
}

.cellRightBorder {
  border-right: 1px solid lightgray;
}

.pdfIcon {
  display: flex;
  flex-direction: column;
  gap: 3px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: transparent;
  transition: all 0.5;
}

.pdfImg {
  height: 6vh;
  width: auto;
  border: 1px solid #ddd;
  display: block;
  padding: 5px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

  background-color: white;
}

.styles.countryBtnCell {
  padding-bottom: 5px;
}

.rankDialog__content {
  display: flex;
  gap: 8px;
  flex: 1;

  &__card {
    &__content {
      width: 100%;

      &__title {
        color: #2a7090;
        text-transform: uppercase;
        font-size: 0.7vw !important;
      }

      &__table {
        width: 100%;
        overflow: auto;
        border-collapse: collapse;

        &__row {
          width: 100%;

          &__cell {
            padding: 3px;
            position: relative;
            cursor: pointer;
          }

          &:hover {
            background-color: rgba($color: #ddd, $alpha: 0.3);

            .rankDialog__content__card__content__table__row__cell__name {
              text-decoration: underline;
            }

            .rankDialog__content__card__content__table__row__cell {
              .floatingButtons {
                visibility: visible;

                .buttonBox {
                  visibility: visible;
                }
              }
            }
          }

          &__head {
            width: 50%;
            text-align: left;
            color: #2a7090;
            text-transform: uppercase;
          }
        }

        &__list {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;

          &__item {
            cursor: pointer;
            transition: 0.5s;
            min-width: 0;
            display: flex;
            cursor: pointer;
            overflow: hidden;
            padding: 0 5px;

            &:hover {
              .buttonBox {
                display: flex;
              }

              // .rankDialog__content__card__content__list__item__voice {
              //   width: 20%;
              // }
            }

            &__voice {
              min-width: 0;
              display: inline-block;
              flex: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              transition: all 0.5s;

              &__directClick {
                width: 100%;

                &:hover {
                  color: #2a7090;
                }
              }
            }
          }
        }
      }
    }
  }
}

.rankDialog__content__card__content__table__row__head__fullWidht {
  width: 100%;
  text-align: left;
  color: #2a7090;
  text-transform: uppercase;
}

.floatingButtons {
  visibility: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  border: 1px solid #2a7090;
  background-color: white;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  padding: 5px;

  &:hover {
    .buttonBox {
      visibility: visible;
    }
  }

  .buttonBox {
    visibility: hidden;
  }
}

.buttonBox {
  visibility: hidden;
  gap: 8px;
  // transition: all 0.5s;
  display: flex;
  justify-content: flex-end;

  .btn {
    font-size: 0.7vw;
    flex: 1;
    cursor: pointer;
    border-radius: 6px;
    padding: 2px 3px;
    white-space: nowrap;
    border: 1px solid transparent;
    font-weight: bold;

    &:hover {
      color: white;
      border: 1px solid #2a7090;
      background-color: #2a7090;
    }
  }
}
