.mainWrapper {
  display: flex;
  min-height: 0;
  padding: 8px;
  height: 100%;
  overflow: hidden;
  gap: 8px;

  .col {
    display: flex;
    flex: 1;
    gap: 8px;
    min-height: 0;

    &-sx {
      flex-direction: column;

      .chartCard,
      .pieCard {
        flex: 1;
        display: flex;

        .chartCardContent,
        .pieCardContent {
          flex: 1;
        }
      }
    }

    &-dx {
      .anagraphicInfoCol {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 8px;
        overflow: auto;
      }
    }
  }
}
