@charset "utf-8";
/*
* Author : Trendrating <info@trendrating.net>
* Created: 06 Jun 2017
* Project: Trendrating Web Application
*
* Alternative widget
*/
.alternative {
   background-color: rgba(255, 255, 255, 1);
   border-bottom: 1px solid rgba(211, 211, 211, 1);
   padding: 1% 2%;
}

.alternative:last-child {
   border-bottom: 0;
}

.alternative-header {}

.alternative-headerTicker {}

.alternative-headerName {
   clear: both;
   display: inline-block;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   width: 14.7em;
}

.alternative-headerName--logo {
   width: 16em;
}

.alternative-headerLogo {
   float: right;
   margin-left: .25em;
}

.alternative-chart {
   background-position: 50% 50%;
   background-repeat: no-repeat;
   background-size: contain;
   clear: both;
   height: 0;
   padding: 0;
   padding-bottom: calc(100% * 3 / 5);
   width: 100%;
}