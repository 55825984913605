.outlined__box {
    border: 2px solid #ddd;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    flex: 1;

    &:first-child {
        margin-right: 10px;
    }

    &:hover {
        border-color: #2a7090;

        .outlined__box__label {
            color: #2a7090;
        }
    }

    &__label {
        color: darkgrey;
        margin-left: 5px;
        font-size: 0.8vw;
    }

    &__content {
        padding: 10px;
        padding-top: 5px;
        display: flex;
        justify-content: space-between;
        flex: 1;
    }
}

.screener__panel__content {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.taxonomy__box {
    border: 2px solid #ddd;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    flex: 1;
    margin-right: 10px;

    &:last-child {
        margin-right: 0;
    }

    &:hover {
        border-color: #2a7090;

        .taxonomy__box__label {
            color: #2a7090;
        }
    }

    &__label {
        color: darkgrey;
        margin-left: 5px;
        font-size: 0.8vw;
    }

    &__list {
        display: flex;
        flex-direction: column;
        padding: 10px;
        padding-top: 5px;

        ul {
            max-height: 100px;
            overflow: auto;

            &::-webkit-scrollbar {
                width: 5px;
                background-color: transparent;
                margin-left: 5px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #d3d3d3;
            }
        }
    }
}
