.iconButtonWidget {
  background-color: white;
  border: 1px solid #2a7090;
  border-radius: 4px;
  padding: 3px 5px;
  color: #2a7090;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #2a7090;
    color: white;
  }
}

.iconButtonWidgetActive {
  background-color: #2a7090;
  color: white;
  border: 1px solid #2a7090;
  border-radius: 4px;
  padding: 3px 5px;
  transition: all 0.5s;
  cursor: pointer;
}
