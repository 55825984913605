.react-tabs-classic {
    -webkit-tap-highlight-color: transparent;

    .react-tabs__tab-list {
        border-bottom: 1px solid #d3d3d3;;
        padding: 0;
    }

    .react-tabs__tab {
        display: inline-block;
        border: 1px solid #d3d3d3;
        border-bottom: none;
        bottom: -1px;
        position: relative;
        list-style: none;
        padding: 6px 12px;
        cursor: pointer;

        &--selected {
            background: #fff;
            border-color: #d3d3d3;
            color: black;
            border-top: 5px solid $primary;
        }

        &--disabled {
            color: GrayText;
            cursor: default;
        }

        &:focus {
            box-shadow: 0 0 5px hsl(208, 99%, 50%);
            border-color: hsl(208, 99%, 50%);
            outline: none;

            &:after {
                content: "";
                position: absolute;
                height: 5px;
                left: -4px;
                right: -4px;
                bottom: -5px;
                background: #fff;
            }
        }
    }

    .react-tabs__tab-panel {
        display: none;

        &--selected {
            display: block;
            padding: 8px;
            border: 1px solid #d3d3d3;
            border-top: none;
        }
    }
}
