@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 08 Feb 2016
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Collection analysis widget
 */
.analysis-collection {
}
/* ------------------------------------------------------------------- Header */
.analysis-collection .layout__content-pane {
  width: 100%;
}

.analysis-collection-header {
  display: flex;
  width: 100%;
}

.analysis-collection-header__name {
  display: inline-block;
}

.analysis-collection-header__editBenchmark {
  padding-left: 1em;
  width: 24em;
}

.analysis-collection-header__editName input {
  font-size: 150%;
  font-weight: normal;
}

.analysis-collection-header__loader {
  width: 7em;
}

.analysis-collection-header__loader--edit {
  padding-top: 1.9em;
}
/* ----------------------------------------------------------------- Overview */

/* --------------------------------------------------------------------- Edit */
.analysis-collection-edit {
}

.analysis-collection-edit .layout-grid__col--50 {
  margin-right: 1%;
  width: 49%;
}

.analysis-collection-edit .layout-grid__col--50:last-child {
  margin-left: 1%;
  margin-right: 0;
}

.analysis-collection-edit__grid {
  clear: both;
}

.analysis-collection-edit__grid-footer {
  display: inline-block;
  width: 16em;
}

.analysis-collection-edit__grid-footer-notice {
  display: inline-block;
  width: auto;
}

.analysis-collection-edit__message {
  font-size: 150%;
  margin: 0 2%;
  margin-top: 42.5%;
}
/* ----------------------------------------------------------------- Optimize */
.analysis-collection-optimize {
}

.optimization {
  height: 635px;
}

.optimization-interstitial {
  font-size: 130%;
}

.optimization-interstitial .i-help {
  cursor: pointer;
}

.optimization-interstitial__info {
  padding: 0.25em 0;
}

.optimization__tooltip {
  margin: 0 1em;
  width: 26em;
}

.optimization__tooltip-legend {
  border-radius: 50%;
  display: inline-block;
  height: 0.8em;
  width: 0.8em;
}

.optimization__tooltip-legend--loose {
  background-color: #ff0;
}

.optimization__tooltip-legend--strict {
  background-color: #0070c0;
}

.optimization__tooltip-title,
.optimization__tooltip-text {
  margin: 0;
  margin-top: 0.5em;
  padding: 0;
}

.optimization__tooltip-text {
  font-size: 120%;
}

.optimization__tooltip-img {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.claro .optimization .dgrid-selected {
  background-color: #fff;
}

.optimization__title,
.optimization__summary {
  margin-bottom: 0.25em;
  padding-bottom: 0.25em;
}

.optimization__title {
  min-height: 1.45em;
  padding-bottom: 0.2em;
  padding-top: 0.25em;
}

.optimization__title .button--nav {
  margin-right: 0.5em;
  padding: 0 0.25em;
}

/* .optimization__header {
    min-height: 3.25em;
} */

.optimization__summary {
  font-size: 110%;
  background: #fefefe;
  border: 1px solid #cccccc;
  padding: 4px;
  margin-bottom: 1em;
  min-height: 24px;
}

.optimization__summary-strategy,
.optimization__summary-turnover {
  display: inline-block;
  padding-top: 2px;
}

.optimization__summary-turnover {
  float: right;
  padding-top: 2px;
}

.optimization__buy-sell {
  margin-bottom: 0.2em;
}

.optimization__change-action {
  color: #2a7092;
  cursor: pointer;
  font-size: 60%;
  text-decoration: none;
}

/* .optimization__benchmark, */
.optimization__collection {
  margin-bottom: 0.5em;
}

/* .optimization__name, */
.optimization__rate {
  display: inline-block;
}

.optimization__rate {
  /* float: left; */
  margin-right: 0.25em;
}

.optimization__name--collection {
  font-size: 150%;
}

.optimization__rate--collection {
  font-size: 140%;
  vertical-align: middle;
  line-height: 1em;
}

.optimization__grid-container {
  height: 565px;
  position: relative;
}

.optimization__grid {
  bottom: 0;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.optimization__actions {
  font-size: 150%;
  outline: 1px solid #d3d3d3;
  margin: 0 0 0 0;
  padding-top: 10%;
}

.optimization__action {
  cursor: pointer;
  display: inline-block;
  margin: 1% 1% 5% 1%;
  min-height: 12em;
  text-align: center;
  vertical-align: top;
  width: 48%;
}

.optimization__action:hover {
  outline: 1px solid #d3d3d3;
}

.optimization__action-description,
.optimization__action-name {
  margin: 0;
  padding: 0;
}

.optimization__action-icon {
  color: #2a7092;
  font-size: 300%;
  text-align: center;
}

.optimization__action-name {
  color: #2a7092;
  display: inline-block;
  font-weight: normal;
  margin-bottom: 0.25em;
  padding: 0 0.15em;
}

.optimization__action-description {
  padding: 0 0.3em;
}

.optimization__views {
  text-align: center;
  margin: 1em 0;
}
/* --------------------------------- app/modules/analysis-collection/Analysis */
.tAnalysis {
  margin-top: 0.25em;
}

.claro
  .tAnalysis
  .dijitTabContainerNested
  .dijitTabContainerTop-tabs
  .dijitTab {
  padding: 6px 8px;
}

.claro
  .tAnalysis
  .dijitTabContainerNested
  .dijitTabContainerTop-tabs
  .dijitTab:focus,
.claro
  .tAnalysis
  .dijitTabContainerNested
  .dijitTabContainerTop-tabs
  .dijitTab
  .tabLabel:focus {
  outline: none;
}

.claro
  .tAnalysis
  .dijitTabContainerNested
  .dijitTabContainerTop-tabs
  .dijitTab:hover {
  background-color: #ddd;
}

/* #region --- app/modules/analysis-collection/analysis/allocation/Allocation */
.tAnalysisAllocation {
  height: 100%;
  overflow: hidden;
}

.tAnalysisAllocation-constraintsWrap {
  flex: 1 1 auto;
}

.tAnalysisAllocation-wrap {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-bottom: 12px;
}

.tAnalysisAllocation-title {
  flex: 0 1 auto;
  min-width: 15em;
}

.tAnalysisAllocation-switchView {
  display: inline-block;
  float: right;
  font-size: 0.75em;
  margin-top: 0.85em;
}

.tAnalysisAllocation-content {
  overflow: auto;
}

.tAnalysisAllocation-table {
}

.tAnalysisAllocation-cellAreaRegion {
  padding: 1em 0.25em;
  width: 14em;
}

.tAnalysisAllocation-td {
}

.tAnalysisAllocation-cellMarket {
  display: inline-flex;
  min-height: 4em;
  min-width: 4em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tAnalysisAllocation-cellMarket .cellText {
  font-size: 0.9em;
}

.tAnalysisAllocation-cellMarket .format-rate {
  display: inline-block;
  font-size: 1.5em;
}

.tAnalysisAllocation-cellMarket .tPeerTable-arrow {
  font-size: 1.3em;
}

.tAnalysisAllocation-cellMarket .tPeerTable-arrow.tPeerTable-arrow--upgrade,
.tAnalysisAllocation-cellMarket .tPeerTable-arrow.tPeerTable-arrow--downgrade {
  font-size: 1.7em;
}

.tAnalysisAllocation-td,
.tAnalysisAllocation-th {
  text-align: center;
}

.tAnalysisAllocation-td {
  border: 1px solid #d3d3d3;
  cursor: pointer;
}

.tAnalysisAllocation-td.tAnalysisAllocation-td--empty {
  cursor: default;
}

.tAnalysisAllocation-td--color .format-rate {
  color: #fff;
  padding-top: 1.5em;
}

.tAnalysisAllocation-td--colorA {
  background-color: #008000;
}

.tAnalysisAllocation-td--colorB {
  background-color: #8bbc00;
}

.tAnalysisAllocation-td--colorC {
  background-color: #f48400;
}

.tAnalysisAllocation-td--colorD {
  background-color: #f00000;
}

.tAnalysisAllocation-td--empty {
  background-color: #f2f2f2;
}

.tPeerTable-tableCell--hasTooltip.tAnalysisAllocation-td--empty {
  background-color: #ffffff;
  cursor: pointer;
}

.tAnalysisAllocation-th {
  cursor: pointer;
}

.tAnalysisAllocation-th--what {
  border: 1px solid #d3d3d3;
  border-left: 3px solid #d3d3d3;
  padding-left: 0.5em;
  text-align: left;
}

.tAnalysisAllocation-th--subject {
}

.tAnalysisAllocation-th--subSubject {
  font-size: 80%;
}

.tAnalysisAllocation-th--whatContent {
  width: 21em;
}

.tAnalysisAllocation-th--where {
  border: 1px solid #d3d3d3;
  border-top: 3px solid #d3d3d3;
}

.tAnalysisAllocation-th--whatA {
  border-left-color: #008000;
}

.tAnalysisAllocation-th--whereA {
  border-top-color: #008000;
}

.tAnalysisAllocation-th--whatB {
  border-left-color: #8bbc00;
}

.tAnalysisAllocation-th--whereB {
  border-top-color: #8bbc00;
}

.tAnalysisAllocation-th--whatC {
  border-left-color: #f48400;
}

.tAnalysisAllocation-th--whereC {
  border-top-color: #f48400;
}

.tAnalysisAllocation-th--whatD {
  border-left-color: #f00000;
}

.tAnalysisAllocation-th--whereD {
  border-top-color: #f00000;
}
/* #endregion --------------------------------------------------------------- */

/* #region ------- app/modules/analysis-collection/analysis/holdings/Holdings */
.tAnalysisHoldings {
}

.tAnalysisHoldings-dataLoader {
  background-color: rgba(242, 242, 242, 0.85);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 800;
}

.tAnalysisHoldings-dataLoaderMessage {
  margin-top: 40vh;
  text-align: center;
}

.tAnalysisHoldings-dataTools {
  margin-bottom: 0.25em;
}

.tAnalysisHoldings-dataViewerChart {
}

.tAnalysisHoldings-dataViewerTable {
}

.tAnalysisHoldings-isHidden {
  display: none;
}
/* #endregion --------------------------------------------------------------- */

/* #region ------- app/modules/analysis-collection/analysis/overview/Overview */
.tAnalysisOverview {
}

.tAnalysisOverview-isHidden {
  display: none;
}

.tAnalysisOverview-layout {
  display: flex;
  justify-content: space-between;
}

.tAnalysisOverview-layout.tAnalysisOverview-layout--vertical {
  flex-direction: column;
  flex-wrap: wrap;
}

.tAnalysisOverview-layout--allocation {
  outline: 1px solid #d3d3d3;
}

.tAnalysisOverview-layout--marginTop {
  /* margin-top: 1em; */
  margin-top: 16px;
}

.tAnalysisOverview-layout--gap--2 {
  /* margin-top: 1em; */
  gap: 16px;
}

.tAnalysisOverview-box {
  background-color: #ffffff;
  height: auto;
  padding: 15px;
  flex: 1;
  border-radius: 4px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.tAnalysisOverview-box--9 {
  width: 9%;
}

.tAnalysisOverview-box--16 {
  width: 16%;
}

.tAnalysisOverview-box--17 {
  width: 17%;
}

.tAnalysisOverview-box--24 {
  width: 24%;
}

.tAnalysisOverview-box--29 {
  width: 29%;
}

.tAnalysisOverview-box--39 {
  width: 39%;
}

.tAnalysisOverview-box--49 {
  width: 49.3%;
}

.tAnalysisOverview-box--69 {
  width: 69%;
}

.tAnalysisOverview-box--100 {
  width: 100%;
}

.tAnalysisOverview-box--allocation {
  border: 0;
  margin-top: 0;
  padding: 0.25em 0 0.5em 0;
}

.tAnalysisOverview-box--distribution {
  border: 0;
  flex: 1 1;
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.tAnalysisOverview-box--downgradeUpgrade {
  border: 0;
  font-size: 150%;
  padding: 10px;
}

.tAnalysisOverview-box--news {
  margin-bottom: 1em;
  position: relative;
  width: 100%;
}

.tAnalysisOverview-box--performer {
  margin-bottom: 0.5em;
  min-height: 15em;
}

.tAnalysisOverview-title .tFormSelect {
  padding: 0;
}

.tAnalysisOverview-collapser {
  color: #2a7092;
  cursor: pointer;
  position: absolute;
  right: 0.5em;
  top: 0.5em;
}

.tAnalysisOverview-news {
  max-height: 14em;
  overflow: auto;
  position: relative;
}

.tAnalysisOverview-newsItem {
  margin: 0.5em 0;
}

.tAnalysisOverview-tcr {
  font-size: 400%;
  text-align: center;
}

.tAnalysisOverview-tcr.different-past-tcr {
  font-size: 250%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5em;
}

@media screen and (min-width: 1200px) {
  .tAnalysisOverview-tcr.different-past-tcr {
    font-size: 320%;
    padding-top: 0;
  }
}

@media screen and (min-width: 1600px) {
  .tAnalysisOverview-tcr.different-past-tcr {
    font-size: 340%;
    padding-top: 0;
  }
}

.tAnalysisOverview-tcr .tTableAlerts-arrow {
  padding-left: 0.2em;
  padding-right: 0.2em;
  font-size: 40%;
  padding-top: 0.15em;
}

.tAnalysisOverview-tcrAlert {
  color: #ffbb00;
  font-size: 70%;
}

.tAnalysisOverview-rate {
  font-size: 200%;
}

.tAnalysisOverview-distributionValue {
  display: inline-block;
  font-size: 150%;
  text-align: right;
}

.tAnalysisOverview-allocationChart {
  height: 9em;
}

.tAnalysisOverview-scrollable {
  height: 9.75em;
  overflow: auto;
}

.tAnalysisOverview--ratingChange .tAnalysisOverview-scrollable {
  margin-top: 1em;
}

.tAnalysisOverview-table {
  width: 100%;
}

.tAnalysisOverview-tableLabel {
  font-weight: normal;
  text-align: left;
  width: 5.5em;
}

.tAnalysisOverview-tableValue {
}

.tAnalysisOverview--allocation .tWhatWherePie .tWhatWherePie-itemNameValue {
  width: auto;
}

.tAnalysisOverview-ratingChangeRate {
  text-align: center;
  width: 10%;
}

.tAnalysisOverview-ratingChangeName {
  width: 80%;
}

.tAnalysisOverview-ratingChangeDate {
  width: 10%;
}

.tAnalysisOverview-performerRate {
  font-size: 100%;
}
/* PerformerBox */
.tAnalysisOverview-performerBox {
  border-collapse: collapse;
}

.tAnalysisOverview-performerBoxRow {
}

.tAnalysisOverview-performerBoxRow:hover .tAnalysisOverview-performerBoxInfo {
  display: block;
}

.tAnalysisOverview-performerBoxLabel {
  position: relative;
  width: 12vw;
}

.tAnalysisOverview-performerBoxLabelValue {
  display: inline-block;
  line-height: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
  width: 12vw;
}

.tAnalysisOverview-performerBoxInfo {
  background-color: #ffffff;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  right: 0;
  padding: 0;
  position: absolute;
  top: 0.25em;
}

.tAnalysisOverview-performerBoxBar {
  padding-left: 1vw;
  width: 6vw;
}

.tAnalysisOverview-performerBoxValue {
  text-align: right;
  width: 5vw;
}

.tAnalysisOverview-performerBoxMore {
  text-align: right;
}

.tAnalysisOverview-performerBoxButtonMore {
  background-color: transparent;
  border: 0;
  padding: 0.5em 0 0 0;
}

.tAnalysisOverview-performerBoxOverlay {
  background-color: #fff;
  box-shadow: 2px 2px 2px 2px rgba(232, 232, 232, 0.75);
  color: #333;
  left: 0;
  max-height: 43em;
  outline: 1px solid #d3d3d3;
  overflow: auto;
  padding: 0.5em;
  position: absolute;
  top: 0;
  z-index: 900;
}

.performers-box-aggregated {
  flex: 1;
  display: flex;
  gap: 16px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  min-width: 0;
  padding: 16px;
}

.performers-card {
  flex: 1;
}
/* #endregion --------------------------------------------------------------- */

/* #region - app/modules/analysis-collection/analysis/pointInTime/PointInTime */
.tAnalysisPointInTime {
}

.tAnalysisPointInTime-title {
  margin-bottom: 0.75em;
}

.tAnalysisPointInTime-dataLoader {
  background-color: rgba(255, 255, 255, 0.85);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 800;
}

.tAnalysisPointInTime-dataLoaderMessage {
  margin-top: 40vh;
  text-align: center;
}

.tAnalysisPointInTime-warning {
  background-color: rgba(255, 255, 255, 0.85);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 800;
}

.tAnalysisPointInTime-warningMessage {
  margin-top: 40vh;
  text-align: center;
}

.tAnalysisPointInTime-isHidden {
  display: none;
}
/* #endregion --------------------------------------------------------------- */

/* #region --------- app/modules/analysis-collection/analysis/ranking/Ranking */
.tAnalysisRanking {
}

.tAnalysisRankingEdit {
  font-size: 120%;
}

.tAnalysisRankingEdit .filter-bar {
  border-bottom: none;
}

.tAnalysisRankingEdit-title {
  font-size: 130%;
  font-weight: 600;
  margin-bottom: 0.5%;
}

.tAnalysisRankingEdit-option {
}

.tAnalysisRankingEdit-radioLabel {
  display: inline-block;
  min-height: 2.5em;
  vertical-align: top;
  padding-top: 0.245em;
  width: 10em;
}

.tAnalysisRankingEdit-params {
  display: inline-block;
  width: 68em;
}

.tAnalysisRankingEdit-params .filter-bar__row {
  padding: 0;
}

.tAnalysisRankingEditUniverse {
  display: inline-block !important;
}

.tAnalysisRankingEditUniverse-feedback {
  margin: 1% 0;
}

.tAnalysisRankingEditUniverse-feedback .tFeedback-message {
  margin: 0;
  padding: 0.5% 1%;
  width: 98%;
}
/* #endregion --------------------------------------------------------------- */

/* #region ------------ app/modules/analysis-collection/analysis/trends/Trends */
.tAnalysisTrend {
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.tAnalysisTrend-menu {
  list-style: none;
  margin: 4em 0 0 0;
  padding: 0;
}

.tAnalysisTrend-menuItem {
  color: #000;
  font-size: 120%;
}

.tAnalysisTrend-menuLink {
  border-right: 0.25em solid transparent;
}

.tAnalysisTrend-menuItem .tAnalysisTrend-menuLink {
  display: block;
  padding: 0.5em 0.5em 0.5em 0;
}

.tAnalysisTrend-menuLink:link,
.tAnalysisTrend-menuLink:visited {
  color: #000;
}

.tAnalysisTrend-menuLink:hover,
.tAnalysisTrend-menuLink:active {
  text-decoration: none;
}

.tAnalysisTrend-menuLink-isActive:link,
.tAnalysisTrend-menuLink-isActive:visited,
.tAnalysisTrend-menuLink-isActive:hover,
.tAnalysisTrend-menuLink-isActive:active {
  border-right-color: #2a7092;
  color: #2a7092;
}

.tAnalysisTrend-menuLink-isDisabled:link,
.tAnalysisTrend-menuLink-isDisabled:visited,
.tAnalysisTrend-menuLink-isDisabled:hover,
.tAnalysisTrend-menuLink-isDisabled:active {
  cursor: default;
}

.tAnalysisTrend-message {
  line-height: 180%;
  margin-top: 35vh;
  text-align: center;
}

.tAnalysisTrend-what {
  height: 100%;
}

.tAnalysisTrend-content {
  border: 1px solid #d3d3d3;
  height: 100%;
  width: 86%;
}
/* #endregion --------------------------------------------------------------- */

/* #region ------------------------------------------------------ Create/Edit */
.tAnalysisListCreate {
  color: #000;
  padding: 0.5em 0;
}

.tAnalysisListEditor {
}

.tAnalysisListEditor-lastAdded td {
  background-color: #2a7092;
  color: #fff;
  transition: all 0.25s linear;
}

.tAnalysisListEditor-lastAddedReset td {
  background-color: #fff;
  color: #000;
  transition: all 0.25s linear;
}

.tAnalysisListEditor-toolbar {
  margin: 0.15em 0;
}

.tAnalysisListEditor-portfolioTools {
  display: inline-block;
  float: right;
}

.tAnalysisListEditor .dgrid .field-_s_action {
  width: 4.5em;
}

.tAnalysisListEditor .dgrid .field-_s_where {
  text-align: left;
  width: 9em;
}

.tAnalysisListEditor .dgrid .field-vc {
  width: 8em;
}

.tAnalysisListEditor .dgrid .field-weight {
  width: 8em;
}

.tAnalysisListDataUpload {
  padding-left: 1em;
}

.tAnalysisListDataUpload-header {
  font-size: 150%;
  margin-bottom: 1.75em;
}

.tAnalysisListDataUpload-multiple {
  max-height: 51vh;
  overflow: auto;
}

.tAnalysisListDataUpload-unrecognized {
  margin-bottom: 1em;
  max-height: 11.8vh;
  overflow: auto;
}

.tAnalysisListDataUpload-importSource {
  padding-top: 40vh;
  text-align: center;
}

.tAnalysisListDataUpload-importSource .tFormButton {
  font-size: 140%;
}

.tAnalysisListDataUpload-importSourceMessage {
  color: #000;
  margin-top: 1em;
}

.tAnalysisListDataUpload-importSource--collapsed {
  margin: 0.15em 0;
  padding-bottom: 0.32em;
  padding-top: 0;
  text-align: right;
}

.tAnalysisListDataUpload-importSource--collapsed .tFormButton {
  font-size: 100%;
}

.tAnalysisListDataUpload-importSource--collapsed
  .tAnalysisListDataUpload-importSourceMessage {
  display: none;
}

.tAnalysisListDataUpload-importTarget {
}

.tAnalysisListDataUpload-importTargetMultiple {
  margin-bottom: 3em;
}

.tAnalysisListDataUpload-importTargetMultiple,
.tAnalysisListDataUpload-importTargetUnrecognized {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  color: #000;
  padding: 0.25em 0.5em;
}

.tAnalysisListDataUploadMultiple:not(:last-child) {
  border-bottom: 1px solid #d3d3d3;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5em;
}

.tAnalysisListDataUploadMultiple:last-child {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5em;
}

.tAnalysisListDataUploadMultiple-label {
  font-size: 120%;
  font-weight: 600;
  margin-right: 1%;
  padding-top: 0.2em;
  width: 20%;
}

.tAnalysisListDataUploadMultiple-options {
  width: 79%;
}

.tAnalysisListDataUploadMultiple-option {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  cursor: pointer;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: 120%;
  margin: 0 0.25em 0.5em 0;
  padding: 0.15em 0.5em;
  vertical-align: middle;
}

.tAnalysisListDataUploadMultiple-option:hover {
  background-color: #2a7092;
  color: #fff;
}

.tAnalysisListDataUploadMultiple-ticker {
  display: inline-block;
}

.tAnalysisListDataUploadMultiple-name {
  display: inline-block;
  margin: 0 1em;
}

.tAnalysisListDataUploadMultiple-currencyAndType {
  display: inline-block;
}

.tAnalysisListDataUploadUnrecognized {
}

.tAnalysisListDataUploadUnrecognized-label {
  font-size: 120%;
  font-weight: 600;
  margin-bottom: 0.5em;
}
/* #endregion --------------------------------------------------------------- */
