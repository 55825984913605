.widgetWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  gap: 8px;

  &__inputBox {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
