.content {
  .form {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    padding: 0 15px;

    label {
      font-size: 14px;
      font-weight: bold;
      color: #2a7092;
    }

    .form__input-wrapper {
      height: 30px;
      margin-top: 10px;

      input {
        font-size: 14px;
        padding: 4px;
        width: 72.5%;
        height: 100%;
        border: 1px solid #dedded;
        border-radius: 4px;

        &:focus {
          border: 1px solid #2a7092;
          -webkit-box-shadow: 0px 0px 5px rgba(42, 112, 146, 0.75);
          -moz-box-shadow: 0px 0px 5px rgba(42, 112, 146, 0.75);
          box-shadow: 0px 0px 5px rgba(42, 112, 146, 0.75);
        }
      }

      button {
        width: 25%;
        margin-left: 2.5%;
      }
    }
  }
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 8px;
  display: flex;
  justify-content: flex-end;
}
