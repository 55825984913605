.historyTable {
  width: 100%;
  border-collapse: collapse;
  position: relative;

  thead {
    position: sticky;
    top: 0;
    z-index: 2;

    &::before {
      content: "";
      display: block;
      height: 1px;
      background-color: #d3d3d3;
      width: 100%;
      position: absolute;
      top: 0;
    }
  }

  th {
    min-width: fit-content;
    background-color: #f0f0f0;
    min-width: 100px;
    border: 1px solid #d3d3d3;
    cursor: pointer;
    transition: all 0.5s;

    .headerCell {
      display: flex;
      white-space: nowrap;
      gap: 8px;
      padding: 3px;
      align-items: "center";

      .headerBtnBox {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;

        .headerBtn {
          visibility: hidden;
          min-width: 0;
          min-height: 0;
          height: auto;
          padding: 2px;
          background-color: white;
          color: #2a7090;
        }
      }
    }

    &:hover {
      background-color: #2a7090;
      color: white;

      .headerCell {
        .headerBtnBox {
          .headerBtn {
            visibility: visible;
          }
        }
      }
    }

    &:first-child {
      position: sticky;
      left: 0;
      border-right: none;

      &::before {
        content: "";
        display: block;
        background-color: #d3d3d3;
        width: 1px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1px;
      }

      &::after {
        content: "";
        display: block;
        background-color: #d3d3d3;
        width: 1px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
      }
    }

    &:nth-child(2) {
      border-left: none;
    }
  }

  td {
    text-align: center;
    border: 1px solid #d3d3d3;

    &:first-child {
      border-left: none;
      border-right: none;
    }

    &:nth-child(2) {
      border-left: none;
    }
  }

  .firstColumn {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: white;
    text-align: left;

    .firstColumnContent {
      display: flex;
      justify-content: space-between;

      .instrumentNameBox {
        display: flex;
        align-items: center;
        gap: 8px;

        .tooltipIcon {
          visibility: hidden;
          cursor: pointer;
        }
      }

      .firstColumnButtonBox {
        .firstColumnButton {
          visibility: hidden;
          min-width: 0;
          min-height: 0;
          height: auto;
          padding: 2px;
        }
      }
    }

    &::before {
      content: "";
      display: block;
      background-color: #d3d3d3;
      width: 1px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -1px;
    }

    &::after {
      content: "";
      display: block;
      background-color: #d3d3d3;
      width: 1px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
    }

    &:hover {
      .firstColumnContent {
        .firstColumnButtonBox {
          .firstColumnButton {
            visibility: visible;
          }
        }

        .instrumentNameBox {
          .tooltipIcon {
            visibility: visible;
          }
        }
      }
    }
  }
}

.modalBodyOverride {
  display: flex;
  overflow: hidden;
  height: 100%;
  min-width: 0;
}

.unavailable {
  color: #aaa;
}

.expired {
  color: red;
}

.edited {
  color: blue;
}

.rebalanced {
  color: green;
}

.headerWithMenu {
  span {
    font-size: 0.8vw;
    color: transparent;
  }

  &:hover {
    span {
      color: #2a7090;
    }
  }
}

.cellIdx {
  p {
    color: transparent;
  }
}

.openDialogButton {
  background-color: white;
  border: 1px solid #2a7090;
  border-radius: 4px;
  padding: 3px 5px;
  color: #2a7090;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #2a7090;
    color: white;
  }
}

.modalContentBox {
  display: flex;
  flex: 1;
  min-width: 0;
  min-height: 0;
  gap: 8px;

  .checkboxesCol {
    flex: 1;
  }

  .editorContentCardContent {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;

    .editorContentBoxHeader {
      background-color: rgba($color: #000000, $alpha: 0.1);
      color: rgba($color: #000000, $alpha: 0.5);
      font-size: 0.7vw;
      padding: 8px;
    }

    .columnsAvailableBox {
      padding: 8px;
      display: flex;
      justify-content: space-between;

      .checkboxGroupWrapper {
        display: flex;
        flex-direction: column;

        .checkboxGroup {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

.rowHistoricalTable {
  &:hover {
    .cellIdx {
      p {
        color: #2a7090;
      }
    }
  }
}
