@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 15 May 2017
 * Project: Trendrating Web Application
 *
 * Page Security Analysis
 */
.pageAnalysisSecurity {
   margin: 0 auto;
   width: 80em;
}

.pageAnalysisSecurity .search {
   font-size: 150%;
}

.pageAnalysisSecurity-mutantLayout {
   display: flex;
   justify-content: space-between;
}

.pageAnalysisSecurity--showAlternatives {
   width: 85%;
}

.pageAnalysisSecurity--showAlternatives .tileSecurity,
.pageAnalysisSecurity--showAlternatives .tileSecurityPeer {
   width: 60em;
}

@media all and (min-width:1025px) {

   .pageAnalysisSecurity--showAlternatives {
      margin: 0 auto;
      width: 80em;
   }

   .pageAnalysisSecurity--showAlternatives .tileAlternatives {
      margin-top: 2%;
      width: 16.75em;
   }

   .pageAnalysisSecurity--showAlternatives .tileAlternatives-filter {
      margin-right: .85em;
   }

   .pageAnalysisSecurity--showAlternatives .tileAlternatives-alternatives {
      max-height: 43.8em;
   }
}