@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 26 Jul 2016
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * List
 */
.list {
   list-style: none;
   margin: 0;
   padding: 0;
}

.list__item {
   border-bottom: 1px solid #d3d3d3;
   padding: .5em 0;
}

.list__item--cursor {
   cursor: pointer;
}