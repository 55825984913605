.outerBox {
  flex: 1;
  display: flex;
  gap: 2px;
}

.coloredBarCol {
  min-height: 10vh;
  min-width: 1vw;
}

.ratingBar {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.ratingIndicatorBoxWrapper {
  display: flex;
  align-items: center;
  gap: 2px;
}
.ratingIndicatorBox {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-left: none;
  padding-right: 5px;
  white-space: nowrap;
  flex: 1;
}
.textBox {
  min-width: 0;
  display: flex;
  align-items: center;
  flex: 9;
}

.ellipsisText {
  white-space: nowrap;
}
