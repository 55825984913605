@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 29 Apr 2016
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Tree List (expanded) widget
 */

/* ----------------------------------- NEW ---------------------------------- */
.tree {}

.tree__dropdown {}

.tree__layout {
   background: #eee;
   display: flex;
   justify-content: space-between;
}

.tree__area {
   /* margin-right: 4em; */
   padding: .5em;
}

.tree__area .form__label {
   margin-right: .25em;
}

.tree__area--to-right {
   /* padding-left: 30%; */
   text-align: center;
}
/* ----------------------------------- OLD ---------------------------------- */
.tree-list {
   font-size: 90%;
}

.tree-list__list {
   list-style: none;
   margin: 0;
   padding: 1px;
}

.tree-list__list > .tree-list__list {
   padding-left: 1em;
   margin-bottom: 1px;
}

.tree-list__item {
   padding: .25em .15em;
}

.tree-list__label {
   margin-left: .25em;
}

.tree-list__list--leaf {
   display: inline-block;
}

.tree-list__list--root {}

.tree-list__list--root > li {
   background-color: #2a7092;
   color: #fff;
   margin-bottom: 1px;
   padding-bottom: .4em;
   padding-left: .35em;
}

.tree-list__list--parent-0 > li,
.tree-list__list--parent-1 > li,
.tree-list__list--parent-2 > li {
   font-weight: 600;
}
/* ----------------------------------------------------- Select widget */
.tree-list-select__container {
   outline: none;
}

.tree-list-select__dropdown  .button-bar {
   background-color: #eee;
   border-top: 1px solid #2a7092;
   padding: .15em;
}
/* ------------------------------------------------------------ Colors */
.tree-list__list--parent-0 {
   background-color: #D1DAE1;
}

.tree-list__list--parent-1 {
   background-color: #E8E6E5;
}

.tree-list__list--parent-2 {
   background-color: #E6F4F3;
}