.smart_beta_table_row {
    display: flex;
    align-items: center;
    padding: 2px 12px;
    margin-bottom: 2px;
    border: 2px solid transparent;
    border-bottom: 1px solid #ddd;
    transition: 0.5s;

    &:hover {
        background-color: #f2f2f2;
    }

    &:last-child {
        margin-bottom: 0;
        border-bottom: none;
    }
}

.scrollable__list {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #d3d3d3;
    }
}
