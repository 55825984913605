.listItemContent {
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    // color: #ffc001;
    font-weight: bolder;
  }
}

.listItem {
  &:not(:last-child) {
    margin-bottom: 5px;
  }
}
