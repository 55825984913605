.main_wrapper {
  height: 100%;
  width: 100%;
  min-height: 0;

  display: flex;
  flex-direction: column;
  gap: 8px;
}

.row {
  display: flex;
  min-width: 0;
}

.tableWrapperRow {
  height: 100%;
  width: 100%;
}

.clustersBrickRowContainer {
  display: flex;

  .segmentSelectorBox {
    display: flex;
    gap: 8px;
  }
}

.listItemCard {
  display: flex;
  position: relative;
  cursor: pointer;
  border: 1px solid transparent;
  transition: 0.5s;

  &:hover {
    border: 1px solid #2a7090;
    color: #2a7090;
  }
}

.listItemCardContent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px !important;
  border: 1px solid transparent;
  cursor: pointer;
  border-radius: 4px;
  transition: 0.5s;

  &:hover {
    border: 1px solid #2a7090;
    color: #2a7090;
    background-color: white;
  }
}

.activeCluster {
  font-weight: bold;
  color: #2a7090;
  border: 1px solid #2a7090;
  position: relative;
}

.cardActive {
  background-color: #2a7090 !important;
  color: white !important;
}

.listScrollable {
  display: flex;
  gap: 1;
  min-width: 0;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 4px;
    background-color: transparent;
    margin-left: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d3d3d3;
  }
}
