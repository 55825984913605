.radio__labels,
.switch__label {
    font-size: 0.8vw !important;
}

.switch__label__info {
    font-size: 0.8vw !important;
    cursor: help !important;
}

.outlined__box {
    border: 2px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    flex: 1;

    &:hover {
        border-color: #2a7090;

        .outlined__box__label {
            color: #2a7090;
        }
    }

    &__label {
        color: darkgrey;
        margin-left: 5px;
        font-size: 0.8vw;
    }

    &__content {
        padding: 10px;
        padding-top: 5px;
        display: flex;
        justify-content: space-between;
        flex: 1;
    }
}

.modal__selection__custom {
    min-height: 0;
    display: flex;
    flex: 1;
}
