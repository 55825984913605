.popover-list-item {
    cursor: pointer;
    color: black;
    margin-left: 10px;
    transition: 0.5s;

    &:hover {
        color: #2a7090;
    }
}

.isInputDisabled {
    cursor: not-allowed;
    opacity: 0.6;

    &:hover {
        color: black;
    }
}
