@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 25 Nov 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Security window
 */
.security-window {
   padding: .5em;
}

.security-window__button-bar {
   padding: .5em;
}

.security-window__chechbox {
   display: inline-block;
   float: right;
   margin-top: .3em;
}

.security-window__chart {
   overflow: hidden;
   width: 100%;
}