@charset "utf-8";

/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 20 Oct 2017
 *
 * Style guide : MaintainableCSS (http://maintainablecss.com)
 *
 * Momentum range widget
 */

.tSelectRangeMomentum {
    display: inline-block;
}

.tSelectRangeMomentum:hover .tSelectRangeMomentum-value {
    border-color: rgba(42, 112, 146, 1);
}

.tSelectRangeMomentum-layout {
    display: flex;
    justify-content: space-between;
}

.tSelectRangeMomentum-value {
    border-right: 1px solid rgba(211, 211, 211, 1);
    margin: 0;
    outline: 0;
    padding: .15em .25em;
    transition: border-color .5s ease;
}
/* ------------------------------------------------------------------- Dialog */
.tSelectRangeMomentumDialog {
    background-color: rgba(255, 255, 255, 1);
    padding: .25em;
}

.tSelectRangeMomentumDialog-slider {
    margin: 1em 0;
    width: 14em;
}

.tSelectRangeMomentumDialog-layout {
    display: flex;
    justify-content: space-between;
}

.tSelectRangeMomentumDialog-valueMax {
    width: 4em;
}

.tSelectRangeMomentumDialog-valueMin {
    width: 4em;
}

.tSelectRangeMomentumDialog-reset {
    margin: 1em 0 0 0;
    text-align: right;
}