@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 03 Feb 2016
 * Project: Trendrating Application
 *
 * Tile viewer styles
 */
.viewer-tile {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  clear: both;
  overflow: auto;
}

.viewer-tile__list {
  text-align: center;
}

.viewer-tile__tile {
  border: 1px solid #d3d3d3;
  display: inline-block;
  margin: 0.25em;
  padding: 0.25em;
  position: relative;
  text-align: left;
  vertical-align: top;
}

.viewer-tile__checkbox {
  border-bottom: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
  left: 0;
  padding: 0.25em 0.05em 0.25em 0.25em;
  position: absolute;
  top: 0;
}

.viewer-tile__tile-title-container {
  display: flex;
  margin-left: 2em;
}

.viewer-tile__tile-title-container--no-margin {
  margin-left: 0;
}

.viewer-tile__tile-rate {
  font-size: 360%;
  line-height: 0.9em;
  margin-right: 0.1em;
}

.viewer-tile__tile-title {
}

.viewer-tile__chart-container {
  position: relative;
}

/* .viewer-tile__chart {
   cursor: pointer;
   display: inline-block;
   width: 100%;
} */

.viewer-tile__chart {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 0;
  padding: 0;
  padding-bottom: calc(100% * 3 / 5);
}
