.dropdown__menu {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #2a7092;
    max-width: 150px;
    position: fixed;
    z-index: 9999999;
    max-height: 150px;
    overflow: auto;

    li {
        cursor: pointer;
        padding: 10px;

        &:hover {
            background-color: #2a7092;
            color: white;
        }
    }
}
