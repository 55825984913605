.mainList {
  display: flex;
  gap: 8px;
  min-height: 0;
  overflow: auto;
  height: 100%;
  width: 100%;
  min-width: 0;
  padding: 8px;
  background-color: #f2f2f2;
  border-radius: 4px;
  flex-wrap: wrap;

  .listItem {
    display: flex;
    width: calc(25% - 8px);
  }
}
