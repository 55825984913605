@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 08 Mar 2015
 * Project: Trendrating Application
 *
 * Dialog Save widget
 */
.dialog-save__name {
   display: inline-block;
   overflow: hidden;
   text-overflow: ellipsis;
   vertical-align: top;
   white-space: nowrap;
   width: 8em;
}