@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 21 Aug 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Tooltip for security
 */
.claro .dijitTooltipContainer,
.claro .dijitTooltipContainer .dijitTooltipFocusNode {
   padding: 0;
}

.securty-tooltip {
   min-width: 30em;
   position: relative;
}

.securty-tooltip__wrapper {
   margin-bottom: 1em;
   padding: .5em;
}

.securty-tooltip__name,
.securty-tooltip__info,
.securty-tooltip__ticker {
   margin: 0;
   padding: 0;
}

.securty-tooltip__ticker {
   font-size: 125%;
   font-weight: 600;
}

.securty-tooltip__name {
   border-bottom: 1px solid #d3d3d3;
   overflow: hidden;
   margin-bottom: .25em;
   padding-bottom: .25em;
   text-overflow: ellipsis;
   white-space: nowrap;
   width: 18em;
}

.securty-tooltip__info {
   color: #666;
   margin-bottom: .5em;
}

.securty-tooltip__close {
   background-color: transparent;
   border: 0;
   cursor: pointer;
   position: absolute;
   top: 5px;
   background: url(images/dialogCloseIcon.png);
   background-repeat: no-repeat;
   right: 5px;
   height: 15px;
   width: 21px;
   outline: none;
}

.securty-tooltip__close:hover {
   background-position: -21px;
   outline: none;
}

.securty-tooltip__close:hover:focus {
   background-position: -42px;
   outline: none;
}

.securty-tooltip__new-HL,
.securty-tooltip__price,
.securty-tooltip__price-date,
.securty-tooltip__rate,
.securty-tooltip__rate-date {
   text-align: center;
}

.securty-tooltip__price {
   font-size: 250%;
}

.securty-tooltip__price-date {}

.securty-tooltip__new-HL {}

.securty-tooltip__rate {
   font-size: 450%;
   line-height: 1em;
   font-weight: 600;
}