@charset "utf-8";
/*
* Author : Trendrating <info@trendrating.net>
* Created: 08 Jun 2017
* Project: Trendrating Web Application
*
* Alternative Zoom widget
*/
.alternativeZoom {
   background-color: rgba(255, 255, 255, 1);
   color: rgba(0, 0, 0, 1);
   left: 0;
   opacity: 0;
   outline: 1px solid rgba(42, 112, 146, 1);
   position: absolute;
   top: 0;
   transition: .5s ease-in-out opacity;
   z-index: -1; /* managed by JavaScript in order to avoid flickering */
}

.alternativeZoom-isHide {
   left: 0;
   opacity: 0;
   top: 0;
}

.alternativeZoom-isShow {
   opacity: 1;
}

.alternativeZoom-content {
   display: flex;
   justify-content: space-between;
   padding: .25em;
}

.alternativeZoom-info {
   padding-right: .5em;
   width: 19.5em;
}

.alternativeZoom-ticker {
   font-size: 150%;
}

.alternativeZoom-name {
   margin-bottom: .5em;
}

.alternativeZoom-market {
   color: rgba(170, 170, 170, 1);
}

.alternativeZoom-marketcapSector {
   color: rgba(170, 170, 170, 1);
   margin-bottom: .5em;
}

.alternativeZoom-rate {
   margin-bottom: .5em;
}

.alternativeZoom-price {}

.alternativeZoom-chart {
   background-repeat: no-repeat;
   background-size: contain;
   clear: both;
   height: 12em;
   margin: 0 .25em;
   width: 19.5em;
}

.alternativeZoom-workflow {
   background-color: rgba(0, 0, 0, 1);
   color: rgba(255, 255, 255, 1);
}

.alternativeZoom-workflowActions {
   text-align: center;
}

.alternativeZoom-workflowAction {
   cursor: pointer;
   display: inline-block;
   padding: .5em 1em;
}

.alternativeZoom-workflowAction:hover {
   background-color: rgba(42, 112, 146, 1);
}