.main {
  display: flex;
  width: 100%;
  height: 100%;
  min-width: 0;

  &__card {
    width: 100%;
    height: 100%;
    min-height: 100%;
    min-width: 0;

    &__content {
      height: 100%;
      width: 100%;

      min-width: 0;

      &__resumeTable {
        width: fit-content;
        border-spacing: 8px;
      }

      &__tables {
        height: 85%;
        padding-left: 8px;
        padding-right: 8px;
        overflow: auto;
      }
    }
  }
}

.tableBox {
  height: 20%;
  min-height: 440px;
  min-width: 0;

  &__card {
    width: 100%;
    min-width: 0;

    &__content {
      min-width: 0;
      width: 100%;

      &__tableTitle {
        color: #2a7090 !important;
        text-transform: uppercase;
      }
    }
  }
}

.country__btn {
  padding: 5px;
  display: flex;
  border: 1px solid transparent;
  border-radius: 6px;
  width: 10vw;
  border: 2px solid #ddd;
  background-color: white;
  color: grey;
  cursor: pointer;
  justify-content: space-between;
  transition: all 0.5s;
  gap: 8px;

  &:hover {
    color: #2a7090;
    text-decoration: underline;
  }

  &__content {
    cursor: pointer;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  &__active {
    border-color: #2a7090;
    color: #2a7090;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.helpIcon {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  border: 2px solid black;
  background-color: rgba(255, 192, 1, 0.5);
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.5s;

  &__active {
    background-color: #2a7090;
    color: white;
  }
}

.formControlLabel {
  margin-right: 0 !important;
  white-space: nowrap;
}
