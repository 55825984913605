@charset "utf-8";
/*
* Author : Trendrating <info@trendrating.net>
* Created: 06 Jun 2017
* Project: Trendrating Web Application
*
* Alternatives Tile widget
*/
.tileAlternatives {
   margin-top: .6em;
   width: 16em;
}

.tileAlternatives-title {
   font-size: 130%;
   margin-bottom: .5em;
}

.tileAlternatives-content {
   background-color: rgba(255, 255, 255, 1);
   outline: 1px solid rgba(211, 211, 211, 1);
   padding: .05em .25em;
}

.tileAlternatives-filters {
   margin: .5em 0;
}

.tileAlternatives-filter { }

.tileAlternatives-button {
   background-color: transparent;
   border: 0;
   color: rgba(42, 112, 146, 1);
   cursor: pointer;
   font-family: "Open Sans", sans-serif;
   font-size: 100%;
   padding: .15em .25em;
   text-align: center;
   width: 100%;
}

.tileAlternatives-button--isHidden {
   opacity: 0;
}

.tileAlternatives-button--down {
   border-top: 2px solid rgba(211, 211, 211, 1);
}

.tileAlternatives-button--up {
   border-bottom: 2px solid rgba(211, 211, 211, 1);
}

.tileAlternatives-button:focus {
   outline: 0;
}

.tileAlternatives-alternatives {
   max-height: 52.75em;
   overflow: hidden;
}