@charset "utf-8";
/*
 * Author : Trendrating <info@trendrating.net>
 * Created: 15 May 2017
 * Project: Trendrating Web Application
 *
 * Security Tile widget
 */
.tileSecurity {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   width: 100%;
}

.tileSecurity-tile {
   background-color: rgba(255, 255, 255, 1);
   margin-top: 1%;
   outline: 1px solid rgba(211, 211, 211, 1);
   overflow: hidden;
   padding: .5%;
   position: relative;
   width: 31.75%;
}

.tileSecurity-tile--disabled {
   background-color: transparent;
   outline-color: transparent;
}

.tileSecurity-tile--2_0x {
   padding: 0;
   width: 66.25%;
}

.tileSecurity-tile--0_5x {
   padding: .5%;
   width: 48.5%;
}

.tileSecurity-properties {
   border-collapse: collapse;
   width: 100%;
}

.tileSecurity-propertyName {
   border-bottom: 1px solid rgba(239, 239, 239, 1);
   font-weight: normal;
   padding: .5em 0;
   text-align: left;
}

.tileSecurity-propertyName--extra {
   display: inline-block;
}

.tileSecurity-propertyName--extraInfo {
   font-size: 70%;
}

.tileSecurity-propertyValue--extraRelevant {
   display: inline-block;
   font-size: 150%;
}

.tileSecurity-propertyValue--extraInfo {
   display: inline-block;
   font-weight: normal;
}

.tileSecurity-propertyValue--rate {
   font-size: 200%;
}

.tileSecurity-propertyValue {
   border-bottom: 1px solid rgba(239, 239, 239, 1);
   font-weight: 600;
   padding: .5em 0;
   text-align: right;
}

.tileSecurity-properties tr:last-child td,
.tileSecurity-properties tr:last-child th {
   border-bottom: 0;
}