@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 17 May 2017
 *
 * Style guide : MaintainableCSS (http://maintainablecss.com)
 *
 * DatePicker widget
 */
.datePicker {
   color: rgba(42, 112, 146, 1);
   cursor: pointer;
   display: inline-block;
}

.datePicker--fixedLabel {
   color: inherit;
}