.no-border-right {
  border-right: none !important;
}

.invisible {
  span {
    display: none;
  }
}

.dataImporter {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;

  .dataImporter_table {
    flex: 1;
    overflow: auto;
  }
}

.unavailable {
  color: #aaa;
}

.expired {
  color: red;
}

.edited {
  color: blue;
}

.rebalanced {
  color: green;
}
