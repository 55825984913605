.button {
  background: #2a7090;
  color: white;
  cursor: pointer;
  border: 0;
  min-width: 100px;
  padding: 5px 30px;
  margin-right: 5px;
}
.buttonClose {
  background: white;
  color: #2a7090;
  cursor: pointer;
  border: 1px solid #2a7090;
  min-width: 100px;
  padding: 5px 30px;
}
.dialogPrimaryBtn {
  min-width: 150px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  background: #2a7090;
  color: white;
  border: none;
  cursor: pointer;
}
.dialogSecondaryBtn {
  min-width: 150px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  background: #fafafa;
  color: #2a7090;
  border: 1px solid #2a7090;
  cursor: pointer;
}
