.sortableList {
  //   background-color: rgb(62, 62, 122);
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  // padding: 3px;
  &__listItem {
    font-weight: bold;
    width: 100%;
    padding: 4px;
    border: solid 2px transparent;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      border: solid 2px #2a7092;
    }
    &:not(:last-child) {
      margin-bottom: 4px;
    }
    &:nth-child(odd) {
      background-color: #f2f2f2;
    }
    &:nth-child(even) {
      background-color: #f2f2f2;
    }
    .listItem {
      box-sizing: border-box;
      &__description {
        width: 70%;
        display: inline-flex;
        align-items: center;
        height: 100%;
        position: relative;
      }
      &__controls {
        width: 30%;
        position: relative;
        height: 100%;
        min-width: 50px;
        display: inline-flex;
        justify-content: space-evenly;
        align-items: center;
        z-index: 100;
        & span {
          padding: 5px 13px;
          transition: all 0.2s;
          &:hover {
            background-color: rgb(42, 112, 146);
            border-radius: 4px;
            color: white;
          }
        }
        & div {
          padding: 5px 13px;
          // transition: all 0.2s;
        }
      }
    }
  }
}

.selectedItem {
  background-color: #fff !important;
  border: solid 2px #2a6f929d;
  &:hover {
    border: solid 2px #2a6f929d;
  }
}
