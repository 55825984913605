@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 13 Oct 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Filter widget
 */
.filter {
    background-color: #fff;
    list-style: none;
    margin: 0;
    padding: 0.25em 0;
}

.filter--horizontal {
    display: inline-block;
    border: 2px solid #d3d3d3;
    outline: none;
}

.filter--selected {
    outline-color: #2a7092;
}

.filter__item {
    display: inline-block;
    padding: 0 0.5em;
}

.filter__label {
    display: inline-block;
    vertical-align: middle;
}
/* -------------------------- Filter Bar (Peer, Screening, Porfolio Analysis) */
.filter-bar {
    align-items: center;
    border-bottom: 1px solid #d3d3d3;
    display: flex;
    margin-bottom: 0.5em;
}

.filter-bar__box {
}

.filter-bar__box--border-left {
    border-left: 1px solid #d3d3d3;
}

.filter-bar__any {
    color: #2a7092;
    cursor: pointer;
    font-weight: normal;
    padding: 0.25em 0.25em;
    position: relative;
    top: -0.25em;
}

.filter-bar__any:hover {
    background-color: #2a7092;
    color: #fff;
}

.filter-bar__row {
    padding-bottom: 0.5em;
    /* padding-left: 0.5em; */
}

.filter-bar__row--border {
    border-bottom: 1px solid #d3d3d3;
    margin-bottom: 0.5em;
}

.filter-bar__list-field {
}

.filter-bar__field {
    display: inline-block;
    margin-right: 0.75em;
}

.filter-bar__field-label {
    display: inline-block;
    margin-right: 0.2em;
    vertical-align: middle;
}

.filter-bar__field-widget {
    display: inline-block;
    margin-left: 0.2em;
    vertical-align: middle;
}

.filter-bar__row--type {
    border-bottom: 1px solid #2a7092;
    font-size: 150%;
    margin-bottom: 0.55em;
    padding-bottom: 0;
}
