@charset "utf-8";
/*
 * Author      : Trendrating <info@trendrating.net>
 * Created     : 16 Nov 2015
 *
 * Style guide : BEM (https://en.bem.info)
 *
 * Actions for grid row
 */
.row-action {
   position: absolute;
   top: 25%;
   right: .25em;
   /*outline: 1px dotted red*/
}

.row-action__action {
   background-color: transparent;
   border: 0;
   display: none;
   margin: 0 0 0 .5em;
   padding: 0;
}

.row-action__action:hover {
   background-color: #2a7092;
   color: #ffffff;
   border-radius: 50%;
   cursor: pointer;
}

.row-action__action--rate-distribution-preview {
   background-color: #fff;
   position: absolute;
   right: .25em;
   top: .1em;
}